class ConfirmModalController {
  constructor() {
    'ngInject';
  }
  no() {
    this.modalInstance.dismiss();
  }
  yes() {
    this.modalInstance.close();
  }
}

export default ConfirmModalController;

import template from './deleteAccount.template.html';
import controller from './deleteAccount.controller';

let component = {
  controller,
  template,
  bindings: {
    onAccountDelete: '&'
  }
};

export default component;

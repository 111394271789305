class SimpleDocumentModalController {
  constructor(flash, $scope) {
    'ngInject';
    this.flash = flash;
    this.$scope = $scope;
  }

  $onInit() {
    if(angular.isUndefined(this.resolve.formObj.data) || this.resolve.formObj.data == null) this.resolve.formObj.data = {};

    this.resolve.formObj.formDefinition.fields.forEach((field, idx) => {
      this.resolve.formObj.data[idx] = null;
    });

    this.$scope.$on('$locationChangeStart', (event) => {
      if (this.modalForm.$dirty) {
        let answer = confirm("You have unsaved changes. \n\n Are you sure that you want to leave the document?");
        if (!answer) {
          this.$scope.$emit('modal.closing', 'prevent-back-button');
          event.preventDefault(); // stops the navigation
        } else {
          this.$scope.$emit('modal.closing', 'backbtn');
        }
        // the $emit events get sent to the parent to control closing the modal
      }
    });
  }

  setFieldsDisabled(option) {
    angular.forEach(this.resolve.formObj.fields, field => {
      field.templateOptions.disabled = option;
      field.expressionProperties =  {
        'templateOptions.disabled': `${option}`
      };
    });
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  checkData(formFieldData) {
    let values = [];
    for (const prop in formFieldData) {
      if (formFieldData.hasOwnProperty(prop)) {
        values.push(formFieldData[prop])
      }
    }
    return values.some(i => i !== null);
  }

  save() {
    let result = {doc: this.resolve.formObj, save: true, $dirty: this.modalForm.$dirty || this.resolve.formObj.status !== 'COMPLETE'};
    if (this.checkFieldValidity())
    {
      angular.forEach(this.resolve.formObj.formDefinition.fields, field => {
        if (angular.isUndefined(field.validation)) field.validation = {};
        field.validation.show = true;
      });
      this.flash.showToast('Please fill out the highlighted fields.', 'danger');
      if (angular.isDefined(document.querySelectorAll('input.ng-invalid, form-group has-error'))) {
        document.querySelectorAll('input.ng-invalid, form-group has-error')[0].focus();
      }
    }
    else {
      if (this.modalForm.$dirty || this.checkData(this.resolve.formObj.data) === false) {
        this.flash.showToast('Form Saved Successfully!', 'info');
        this.resolve.formObj.status = 'COMPLETE';
      }
      this.modalInstance.close(result);
    }
  }

  checkFieldValidity() {
    return this.resolve.formObj.formDefinition.fields.some(i => {
      if (angular.isDefined(i.formControl)) {
        if (i.formControl.length > 1) {
          return i.formControl.some(j => j.$valid === false)
        } else {
          return i.formControl.$valid === false;
        }
      }
    });
  }
}

export default SimpleDocumentModalController;

import angular from 'angular';
import component from './disconnect.component';


const disconnect = angular
  .module('connect.Account.Disconnect', [])
  .component('disconnect', component)
  .name;

export default disconnect;

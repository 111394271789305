class QuestionnaireSevice {
  constructor($q, Organization, config, Form) {
    this.$q = $q;
    this.Organization = Organization;
    this.config = config;
    this.Form = Form;
  }

  static get $inject() {
    return ['$q', 'Organization', 'commonLibrary.config', 'Form'];
  }

  getFormConfigForOrg(){
    let defer = this.$q.defer();
    this.Organization.getOrgIds().then((orgIds) => {
      if (!orgIds.orgId) { //Fallback to config for null org id
        orgIds.orgId = this.config.dev_org;
      }
      this.Organization.getOrgUnitAttributes(orgIds.orgId).then(atts => {
        let config = angular.fromJson(atts.data.attributesAsMap['SELF_SCHEDULE_QUESTIONNAIRE_CONFIG']);
        defer.resolve(config);
      });
    });
    return defer.promise;
  }

  getGetInLineFormConfig(deptId){
    let defer = this.$q.defer();
    let config = {enabled: false};
    this.Organization.getOrgUnitAttributes(deptId).then(atts => {
      config = angular.fromJson(atts.data.attributesAsMap['GET_IN_LINE_QUESTIONNAIRE_CONFIG']);
      defer.resolve(config);
    }, (error) => {
      defer.resolve(config)
    });

    return defer.promise;
  }

  getFormInformation(formId){
    let defer = this.$q.defer();
    this.Form.getQuestionnaire(formId).then((response) => {
      defer.resolve(response.data);
    });
    return defer.promise;
  }

  getFieldsFromPaths(node, fieldRequests){
    let fieldIds = [];
    node.paths.forEach(path => {
      path.conditions.forEach(condition => {
        if(!fieldIds.includes(condition.formFieldId)){
          fieldIds.push(condition.formFieldId);
        }
      });
    });
    let fields = [];
    fieldIds.forEach(id => {
      fieldRequests.forEach(req => {
        if(req.field.id == id){
          delete req.field.createdOn;
          delete req.field.nodeId;
          fields.push(req.field);
        }
      });
    });
    return fields;
  }

  findNode(nodeId, nodeRequests){
    let returnNode = {};
    nodeRequests.forEach(request => {
      if(request.node.id == nodeId){
        returnNode = request.node;
      }
    });
    return returnNode;
  }



}

export default QuestionnaireSevice;

import moment from 'moment';

export default class SlotsNavigation {
  constructor(){
    'ngInject';

  }

  $onInit(){

  }

  $onChanges(){
    this.updateCalendar();
  }

  findMostDays(){
    this.mostDays = 0;
    if(angular.isDefined(this.calendar)){
      this.calendar.slots.forEach(week => {
        week.forEach(day => {
          if(day.length > this.mostDays){
            this.mostDays = day.length;
          }
        });
      });
    }
  }

  updateCalendar(){
    let tempDate = moment(this.pivotDate);
    let startDate = tempDate.clone().startOf('month').startOf('week');
    let endDate = tempDate.clone().endOf('month').endOf('week');
    this.endOfMonth = tempDate.clone().endOf('month').valueOf();
    this.startOfMonth = tempDate.clone().startOf('month').valueOf();
    let tempWeek = [];
    this.month = [];
    let weekCounter = 0;
    while(endDate.diff(startDate) > -1){
      tempWeek.push(startDate.valueOf());
      startDate.add(1, 'days');
      if(++weekCounter % 7 == 0){
        this.month.push(tempWeek);
        tempWeek = [];
      }
    }
    this.findMostDays();
  }

  selectWeek(week){
    this.onWeekSelection({$event:week});
  }

  nextMonth(){
    this.onNextMonth({$event:''});
  }

  prevMonth(){
    this.onPrevMonth({$event:''});
  }

  nextWeek(){
    this.onNextWeek({$event:''});
  }

  prevWeek(){
    this.onPrevWeek({$event:''});
  }

  getDayClass(date){
    let day = moment(date);
    let classString = 'day';
    if(date < this.startOfMonth || date > this.endOfMonth){
      classString += ' other-month';
    }
    if(angular.isDefined(this.calendar) && angular.isDefined(this.calendar.slots[day.week()])){
      if(this.calendar.slots[day.week()][day.day()].length >0){
        classString += ' has-timeslots';
      }
    }
    return classString;
  }

  getWeekClass(week){
    let classString = 'week';
    if(this.weekNumber == moment(week[0]).week()){
      classString += ' highlighted';
    }
    return classString;
  }

  getDayOfWeekOf(day, week){
    if(angular.isDefined(day) && angular.isDefined(week)){
      let year = moment(this.pivotDate).endOf('week').startOf('day').year();
      return moment().year(year).week(week).day(day).toDate();
    }
  }

}

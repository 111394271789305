import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './myWaitingRoom.component';
import service from './myWaitingRoom.service';

const myWaitingRoom = angular
  .module('connect.waitingRoom.MyWaitingRoom', [uiRouter, 'commonLibrary'])
  .component('myWaitingRoom', component)
  .service('myWaitingRoomService', service)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('myWaitingRoom', {
        url: '/waitingroom/:facilityId/:patientVisitNumber',
        showInHeader: false,
        allowUnlogged: true,
        title: 'My Waiting Room',
        template: '<my-waiting-room></my-waiting-room>'
      });
  }])
  .name;

export default myWaitingRoom;

import angular from 'angular';
import phoneNumber from './phoneNumber.filter';
import ssn from './ssn.filter';

const filter = angular
    .module('connect.Filters', [])
    .filter('phoneNumber', () => new phoneNumber)
    .filter('ssn', () => new ssn)
    .name;

export default filter;

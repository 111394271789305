import controller from './jfhPatientVideo.controller';
import template from './jfhPatientVideo.template.html';
import styles from './_jfhPatientVideo.scss';

const component = {
  controller,
  template,
  styles,
  bindings: {
    appointmentId: '<',
    token: '<',
    tracks: '<',
    hasForms: '<',
    provider: '<',
    departmentId: '<',
    autoDischarge: '<',
    hasSaved: '<',
    hasSurvey: '<'
  }
};

export default component;

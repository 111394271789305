import template from './personalInformation.template.html';
import controller from './personalInformation.controller';

let component = {
  controller,
  template,
  bindings: {
    user: '<',
    onUserSave: '&'
  }
};

export default component;

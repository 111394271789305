import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './videoDone.component';
import surveys from '../surveys/survey.component';

const dependencies = [uiRouter];

const videoDone = angular
  .module('connect.videoDone', dependencies)
  .component('videoDone', component)
  .component('surveys', surveys)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('videoDone', {
        url: '/video-done/:appointmentId',
        disableHeader: true,
        allowUnlogged: true,
        title: 'Video Visit Done',
        template: '<video-done></video-done>'
      });
  }])
  .name;

export default videoDone;

import controller from './getInLineForm.controller';
import template from './getInLineForm.template.html';

const component = {
  controller,
  template,
  bindings: {
    businessHours: '<',
    config: '<',
    org: '<',
    department: '<',
    user: '<',
    completed: '<',
    processing: '<',
    isRegistered: '&',
    getInLineAction: '&',
    registerAndGetInLineAction: '&',
    documents: '<',
    onDocumentsValid: '&',
    onSaveDocuments: '&',
  }
};

export default component;

import template from './signUp.template.html';
import controller from './signUp.controller';

let component = {
  controller: controller,
  template: template,
  bindings: {
    modalInstance: '<',
    resolve: '<'
  }
};

export default component;

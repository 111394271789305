class GetInLineFormController {
  constructor() {
    'ngInject';
  }

  $onChanges(changes) {
    if (angular.isDefined(changes) && angular.isDefined(changes.config) && angular.isDefined(changes.config.currentValue)) {
      let useFacilityAddress = this.config.WaitingRoom.mobileWaitingRoomConfig.useFacilityAddress;

      if (useFacilityAddress) {
        this.displayAddress = this.department.address;
      } else {
        this.displayAddress = this.org.address;
      }
    }
  }

  registered(value) {
    this.isRegistered({value});
  }

  getInline(value) {
    this.getInLineAction({value});
  }

  registerAndGetInline(value) {
    this.registerAndGetInLineAction({value});
  }

  documentsValid(event){
    this.onDocumentsValid({$event: event});
  }

}

export default GetInLineFormController;

import angular from 'angular';
import component from './timeoutModal.component';
import service from '~/selfScheduling/selfScheduling.service';

import timer from '../timer/timer';

const timeoutModal = angular
  .module('connect.holdingTime.timeoutModal', [timer, 'commonLibrary'])
  .component('timeoutModal', component)
  .service('selfSchedulingService', service)
  .name;

export default timeoutModal;

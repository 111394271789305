import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './campaignRegistration.component';
import loggedWidget from '~/shared/loggedWidget/loggedWidget';
import service from './campaignRegistration.service';

import selfSchedulingService from '../selfScheduling.service';
import appointmentDetailsCard from './appointmentDetailsCard/appointmentDetailsCard';
import registerForm from '~/shared/registerForm/registerForm';
import progressModal from '../progressModal/progressModal';

const dependencies = [
  uiRouter,
  loggedWidget,
  appointmentDetailsCard,
  registerForm,
  progressModal
];

const campaignRegistration = angular
  .module('connect.campaignRegistration', dependencies)
  .component('campaignRegistration', component)
  .service('campaignRegistrationService', service)
  .service('selfSchedulingService', selfSchedulingService)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('campaignRegistration', {
        url: '/self-scheduling/register/:campaignId/:slotId/:appointmentTypeId',
        title: 'Schedule Your Appointment',
        template: '<campaign-registration></campaign-registration>',
        allowUnlogged: true
      });
  })
  .name;

export default campaignRegistration;

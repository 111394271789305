import template from './controls.template.html';
import controller from './controls.controller';

const videoControls = {
  controller,
  template,
  bindings: {
    room: '<',
    mobile: '<',
    audio: '=',
    video: '=',
    facing: '<',
    hasForms: '<',
    departmentId: '<',
    autoDischarge: '<',
    appointmentId: '<',
    onToggleVideo: '&',
    onToggleAudio: '&',
    onCameraSwitch: '&',
    hasSaved: '<',
    hasSurvey: '<'
  }
};

export default videoControls;

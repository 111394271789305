class ResetPasswordController {
  constructor($state, $stateParams, flash, User) {
    'ngInject';

    this.$state = $state;
    this.$stateParams = $stateParams;
    this.flash = flash;
    this.User = User;
  }
  doReset() {
    this.User.sendPReset(this.resetPasswordData.password, this.$stateParams.token)
    .then(() => {
      this.$state.go('login');
      this.message = {
        msg: 'Your password has been reset. You can use your new password to login.',
        type: 'info'
      };
    }, (err) => {
      this.message = {
        msg: err.data.consumerMessage || err.data,
        type: 'danger'
      };
    });
  }
}

export default ResetPasswordController;

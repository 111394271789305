class claimPatientForm {

  $onInit(){
    this.form = {};
    this.participant = {};
    this.signinForm = {};
    this.dob = {};
    this.days = [];
    this.years = [];
    this.months = [
      {name:'January', value:0},
      {name:'February', value:1},
      {name:'March', value:2},
      {name:'April', value:3},
      {name:'May', value:4},
      {name:'June', value:5},
      {name:'July', value:6},
      {name:'August', value:7},
      {name:'September', value:8},
      {name:'October', value:9},
      {name:'November', value:10},
      {name:'December', value:11}
    ];

    for(var x=1; x<= 31; ++x){
      this.days.push(x);
    }
    for( let x=new Date().getFullYear(); x >= 1900; --x){
      this.years.push(x);
    }
  }

  changeDob(){
    if(this.dob.year!=null&& this.dob.month !=null&&this.dob.day!=null){
      this.form.dob = new Date();
      this.form.dob.setFullYear(this.dob.year, this.dob.month.value, this.dob.day);
    }
  }

  signin() {
    this.signinForm.emailAddress = this.form.email;
    this.signinUser({$event: this.signinForm});
  }

  sendForm() {
    this.submitForm({$event: this.form});
  }

  createAccount() {
    this.register({$event: this.participant});
  }

  forgotPassword(email) {
    this.passwordReset({$event: email});
  }
}

export default claimPatientForm;

class FormsPatientIntakeController {
  constructor(formsPatientIntakeService, $stateParams, $log, SessionManager, $state, Access, $window) {
    'ngInject';
    this.formsPatientIntakeService = formsPatientIntakeService;
    this.$stateParams = $stateParams;
    this.$log = $log;
    this.SessionManager = SessionManager;
    this.$state = $state;
    this.Access = Access;
    this.$window = $window;
  }

  $onDestroy() {
    this.SessionManager.clear('token');
    this.SessionManager.clear('current_user');
  }

  $onInit() {
    this.sessionToken = this.$stateParams.token;
    this.patientData = null;
    this.currentProgress = 0;
    this.processing = true;
    this.formsPatientIntakeService.getForms(this.sessionToken).then(response => {
      this.setupPatientData(response.data)
      this.setupSessionManagerVars(response.data);
      this.processing = false;
    }).catch(reason => { // TODO what if the fetching fails?
      this.processing = false;
      this.$log.info('Execution rejected: ',reason);
    });
  }

  nextStep(){
    this.Access.getNextTaskLocation(this.sessionToken).then(resp => {
      if(this.patientData.documents.length > 0){
        let completeCount = this.patientData.documents.filter(d=>{return d.status === "COMPLETE"}).length;
        if(completeCount < this.patientData.documents.length && resp.data.urls[1]) { //skip
          this.$window.location.href = resp.data.urls[1];
        } else {
          this.$window.location.href = resp.data.urls[0];
        }
      } else {
        this.$window.location.href = resp.data.urls[0];
      }
    })
  }

  setupSessionManagerVars(data){
    let currentUser = {"roles": []};
    this.SessionManager.set('current_user', currentUser);
    let session = {
      access_token: data.token.substring('Bearer '.length),
      refresh_token: null
    }
    this.SessionManager.createSession(session, function(){});
  }

  setupPatientData(data){
    data.documents = data.documents.filter(i => !i.hideFromPatient);
    this.patientData = data;
    this.patientData.docsComplete = this.setCompleteCount(data.documents);
    this.totalSteps = data.agenda.length;
    this.currentStep = 0;
    data.agenda.some(task => {
      this.currentStep++;
      return task.type === 'FORMS';
    })
    this.currentProgress = Math.floor(this.currentStep/this.totalSteps * 100);
  }

  setCompleteCount(documents) {
    let completeCount = documents.filter(d=>{return d.status === "COMPLETE"}).length;
    return completeCount + '/' + documents.length;
  }

  saveDocuments($event) {
    this.processing = true;
    this.formsPatientIntakeService.saveForm(this.sessionToken, $event.doc).then(res => {
      this.formsPatientIntakeService.getForms(this.sessionToken).then(response => {
        this.setupPatientData(response.data)
        this.processing = false;
      }).catch(reason => { // TODO what if the fetching fails?
        this.processing = false;
        this.$log.info('Execution rejected: ',reason);
      });
    });
  }

  disableNext() {
    let disable = false;
    if(this.patientData && this.patientData.documents){
      this.patientData.documents.forEach(d=>{
        if(d.required && d.status !="COMPLETE"){
          disable = true
        }
      })
    }
    return disable;
  }

  getNextText() {
    let text = 'SKIP THIS STEP';
    if(this.patientData && this.patientData.documents){
      let completeCount = this.patientData.documents.filter(d=>{return d.status === "COMPLETE"}).length;
      if(completeCount >= this.patientData.documents.length)
        text = 'NEXT STEP'
    }
    return text;
  }
}
export default FormsPatientIntakeController;

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './myConversations.component';
import threadList from "~/shared/threadList/threadList";

const dependencies = [
  uiRouter,
  threadList,
  'commonLibrary',
  'commonFeatures'
];

const myConversations = angular
  .module('connect.myConversations', dependencies)
  .component('myConversations', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('myConversations', {
        url: '/conversations',
        showInHeader: true,
        title: 'My Conversations',
        template: '<my-conversations></my-conversations>'
      });
  }])
  .name;

export default myConversations;

import template from './documentsList.template.html';
import controller from './documentsList.controller';

let component = {
  controller: controller,
  template: template,
  bindings: {
    onDocumentsValid: '&',
    documents: '<',
    // this patient obj may only passed for the patient.id
    patient: '<',
    onSaveDocuments: '&',
    readOnly: '<',
    allowPrint: '<',
    onDocumentOpen: '&',
    showTitle: '<',
    appointmentId: '<',
    hideReviewStatus: '<'
  }
};

export default component;

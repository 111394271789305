import angular from 'angular';
import component from './threadList.component';
import threadModal from './threadModal/threadModal';


const dependencies = [
  threadModal,
];

const threadList = angular
  .module('connect.threadList', dependencies)
  .component('threadList', component)
  .name;

export default threadList;

import controller from './loggedWidget.controller';
import template from './loggedWidget.template.html';

const component = {
  controller,
  template,
  bindings: {
    user: '<',
    config: '<',
    isRegistered: '&',
    asRegistered: '<',
    onRegisterSelect: '&'
  }
};

export default component;

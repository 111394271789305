class ThreadListController {
  constructor($uibModal, $scope, AppointmentsDomain, Access) {
    'ngInject';
    this.$uibModal = $uibModal;
  }


  openThreadModal(index) {
    let modalInstance = this.$uibModal.open({
      component: 'threadModal',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        appointment: () => this.appointment,
        conversation: () => this.conversations[index],
        user: () => this.user,
        conversations: () => this.conversations,
        unreadCount: () => this.conversations[index].unreadCount
      }
    });
    modalInstance.result.then(res => {

    }, dismissRes => {
      this.conversations = dismissRes;
      this.onModalDismiss({$event: this.conversations})
    })
  }

  truncate(input, limit) {
    if (input.length > limit)
      return input.substring(0,limit) + '...';
    else
      return input;
  }
}

export default ThreadListController;
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './checkin.component';

const dependencies = [uiRouter];

const checkin = angular
  .module('connect.checkin', dependencies)
  .component('checkin', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('checkin', {
        url: '/checkin/{orgUnitId}/{apptId}',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Remote Check-In',
        template: '<checkin></checkin>'
      });
  }])
  .name;

export default checkin;

export default class CampaignListController {
  constructor($uibModal){
    'ngInject';
    this.$uibModal = $uibModal;
  }

  viewSchedule($event){
    this.onCampaignSelection({$event: $event});
  }

  selectSlot(campaign, schedule, slot){
    let location = this.getScheduleLocation(campaign, schedule);

    this.onSlotSelection({$event: {
      campaign: campaign.campaign,
      schedule: schedule,
      location: location,
      slot: slot
    }});
  }

  getScheduleLocation(campaign, schedule) {
    let locationId = schedule.resources.filter(resource => resource.type === 'LOCATION')[0].id;
    return campaign.locations.filter(location => location.id === locationId)[0];
  }

  getDepartmentHours(campaign, schedule) {
    return campaign.departmentHours.filter(hours => hours.organizationalUnitId === schedule.organizationalUnitId)[0];
  }

  openLocationsModal(campaign) {
    let modalInstance = this.$uibModal.open({
      component: 'campaignLocationsModal',
      size: 'md',
      resolve: {
        'name' : () => campaign.campaign.name,
        'locations': () => campaign.locations,
        'imageUrl' : () => campaign.imageUrl,
        'hours' : () => campaign.departmentHours
      }
    });

    modalInstance.result.then((result) => {
      this.onCampaignSelection({$event: {campaignId: campaign.campaign.id, locationId: result}});
    }, () => {
      //modal cancelled
    });
  }
}

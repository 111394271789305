import moment from 'moment';

export default class timerController {
  constructor($timeout, $location, $uibModal, $state, $stateParams, $interval, $element){
    'ngInject';
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.$location = $location;
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$element = $element;
  }

  $onChanges(changes){
    if (angular.isDefined(changes.slotData) &&
      angular.isDefined(changes.slotData.currentValue) &&
      changes.slotData.currentValue != null){
      this.setTimes(changes.slotData.currentValue);
      this.fireTimer();
    }
  }

  $onDestroy() {
    if (this.timer) {
      this.$interval.cancel(this.timer);
    }
  }

  setTimes(slotData) {
    //this is here so that the previous timer gets cancelled when extending a slot hold
    if (this.timer) {
      this.$interval.cancel(this.timer);
    }

    this.expireTime = moment(slotData.expire);
    this.warningTime = moment(slotData.expire).subtract(30, 'seconds');
    this.modalOpened = false;
  }

  fireTimer() {
    this.timer = this.$interval(() => {
      let now = moment();
      let isExpired = now.isAfter(this.expireTime);
      let isExpiring = now.isAfter(this.warningTime);

      if (!isExpired){
        this.$element[0].innerHTML = moment().startOf('day').add(this.expireTime.diff(now), 'milliseconds').format('m:ss');
      } else {
        this.$timeout(() => {
          this.onTimeout({$event: {timedOut: true}});
          this.$interval.cancel(this.timer);
        }, 0);
      }

      if (isExpiring && !this.modalOpened) {
        this.$timeout(() => {
          this.modalOpened = true;
          this.onExpiring({$event: {expireTime: this.expireTime}});
        }, 0);
      }
    }, 1000, 0, false);
  }
}

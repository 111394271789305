const Stomp = require("stompjs/lib/stomp.js").Stomp;



class ChatSessionController {
  constructor($state, $scope, $timeout,$stateParams, $location, $anchorScroll, Access, WebSocket) {
    'ngInject';
    this.$state = $state;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;
    this.Access = Access;
    this.WebSocket = WebSocket;
  }

  $onInit() {
    this.remoteTypingIndicator = '!.!'
    this.connectedIndicator = '!*!'
    this.typing = false;
    this.remoteTyping = false;
    this.messages = [];
    this.from = this.$stateParams.appointmentId;
    this.setupMessageListeners();
    this.setupChat();
    this.setupWebsocket();
  }

  checkTyping(){
    if(!this.typing) {
      this.typing = true
      this.Access.sendChatMessage(this.$stateParams.appointmentId, this.$stateParams.userId, this.remoteTypingIndicator)
    }
  }

  sendMessage() {
    if(this.message.length > 0 ) {
      this.typing = false;
      this.sending = true;
      this.Access.sendChatMessage(this.$stateParams.appointmentId, this.$stateParams.userId, this.message).then(response => {
        this.sending = false;
      })
    }
  }

  setupWebsocket(){
    this.WebSocket.startChatWebSocket(this.$stateParams.appointmentId);
  }

  setupChat(){
    this.Access.getChat(this.$stateParams.appointmentId, this.$stateParams.userId).then(response => {
      // this.processConversations(angular.fromJson(response.data.conversations));//FIXME: Should we show previous conversations with no login? Probably not -Sam
      this.chattingWith = response.data.chattingWith;
      this.$timeout(() => {
        this.$location.hash('bottom');
        this.$anchorScroll();
      },0);
    })
  }

  processConversations(conversations){
    //FIXME: Should we show previous conversations with no login? Probably not -Sam
    // conversations.forEach(conversation => {
    //   let message = {message: {from: conversation.from.id, to: this.from, text: conversation.body.text}, timestamp: conversation.timestamp, mine: (this.from === conversation.from.id)};
    //   this.messages.push(message);
    //   this.processConversations(conversation.replies)
    // })
  }

  isEmoji(s) {
    let charRegex = /[^\u0000-\u00ff]/;
    return charRegex.test(s) && s.match(/./gu).length===1;
  }

  pushNewMessage(container) {
    let splitText = container.message.text.split('\n');
    splitText.forEach(m => {
      let tempMsg = angular.copy(container);
      tempMsg.message.text = m;
      this.messages.push(tempMsg);
    });
  }

  setupMessageListeners() {
    this.$scope.$on('CHAT_' + this.$stateParams.appointmentId, (event, response) => {
      this.$timeout(() => {
        let mine = this.from === response.message.from;
        if(!mine && response.message.text === this.remoteTypingIndicator) {
          this.remoteTyping = true;
        } else if(response.message.text != this.remoteTypingIndicator && response.message.text !== this.connectedIndicator) {
          let newMessage = {...response, mine: mine};
          if(mine) {
            this.sending = false;
            this.message = '';
          } else {
            this.remoteTyping = false;
          }
          this.pushNewMessage(newMessage);
          this.$location.hash('bottom');
          this.$anchorScroll();
        }
      },0);
    });

    this.$scope.$on('CHAT_' + this.$stateParams.appointmentId + '_READY', (event, response) => {
      this.$timeout(() => {
        this.Access.sendChatMessage(this.$stateParams.appointmentId, this.$stateParams.userId, this.connectedIndicator);
        this.loading = false;
        this.sending = false;
      },0);
    });

  }

  disconnect() {
    this.WebSocket.endChatWebSocket();
    this.loading = false;
    this.Access.endSecureChat(this.$stateParams.appointmentId, this.$stateParams.userId, this.message).then(()=> {
      this.$state.go('myEvents', {reload: true});
    }, (error) => {
      this.$state.go('myEvents', {reload: true});
    });
  }
}

export default ChatSessionController;

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './arrived.component';

const dependencies = [uiRouter];

const arrived = angular
  .module('connect.arrived', dependencies)
  .component('arrived', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('arrived', {
        url: '/arrived/{orgUnitId}/{apptId}',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Remote Check-In',
        template: '<arrived></arrived>'
      });
  }])
  .name;

export default arrived;

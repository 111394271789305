class SelfSchedulingService {
  constructor($log, $q, $window, Public, PopulationDomain, Organization, Bloom, Access, AccessRegistration, ConfigManager, Form, FormsDomain, PatientsDomain, SessionManager){
    'ngInject';
    this.$log = $log;
    this.$q = $q;
    this.Public = Public;
    this.Organization = Organization;
    this.Bloom = Bloom;
    this.Access = Access;
    this.configs = 'commonLibrary.config';
    this.AccessRegistration = AccessRegistration;
    this.ConfigManager = ConfigManager;
    this.Form = Form;
    this.PatientsDomain = PatientsDomain;
    this.FormsDomain = FormsDomain;
    this.SessionManager = SessionManager;
    this.$window = $window;
  }

  getOrgInfo(){
    let deferred = this.$q.defer();
    let orgInfo = {};
    this.Organization.getOrgIds().then((orgIds) => {
      //Fallback to config for null org id
      if (!orgIds.orgId) {
        orgIds.orgId = this.configs.dev_orgId;
      }
      orgInfo.orgIds = orgIds;
      deferred.resolve(orgInfo);
    }, (err) => {
      deferred.reject(err);
    });

    return deferred.promise;
  }

  filterProviders(formId, answers, orgId){
    let deferred = this.$q.defer();
    let userData = [];

    for (let key in answers) {

      let elementDTO = {};
      elementDTO.formId = formId;
      elementDTO.organizationId = orgId;
      elementDTO.fieldId = key;
      if(angular.isArray(answers[key])){
        elementDTO.textListData = answers[key];
      } else if(angular.isNumber(answers[key])){
        elementDTO.numberData = answers[key];
      } else if(angular.isString(answers[key])){
        elementDTO.textData = answers[key];
      }

      userData.push(elementDTO);
    }

    this.Bloom.filterProviders(userData, formId).then(resp =>{
      deferred.resolve(resp.data);
    });

    return deferred.promise;
  }

  getPopulationId(stateParams) {
    let returnValue = undefined;

    if (angular.isDefined(stateParams.pid)) {
      //store pid in session
      this.$window.sessionStorage.setItem('pid', angular.toJson(stateParams.pid));
      returnValue = stateParams.pid;
    } else {
      //check for pid in session
      returnValue = angular.fromJson(this.$window.sessionStorage.getItem('pid'));
    }

    return returnValue;
  }

  queryProviderScheduleGroups(answers, org, formId, populationId) {
    let deferred = this.$q.defer();
    let userData = [];

    for (let key in answers) {

      let elementDTO = {};
      elementDTO.formId = formId;
      elementDTO.organizationId = org.orgIds.orgId;
      elementDTO.fieldId = key;
      if (angular.isArray(answers[key])) {
        elementDTO.textListData = answers[key];
      } else if (angular.isNumber(answers[key])) {
        elementDTO.numberData = answers[key];
      } else if (angular.isString(answers[key])) {
        elementDTO.textData = answers[key];
      }

      userData.push(elementDTO);
    }
    this.Bloom.queryProviderScheduleGroups(userData, org.orgIds.orgId, populationId).then((response) => {
      //Find the provider image in the attributes and attach it to the outer campaign object.
      angular.forEach(response.data, campaign => {
        let imageAttribute = campaign.userAttributes.attributes.find(attribute => attribute['key'] === 'LOGO_URL');
        if (angular.isDefined(imageAttribute)) {
          campaign.imageUrl = imageAttribute['value'];
        }
      });
      deferred.resolve(response);
    }, error => {
      deferred.reject();
      this.$log.error(error);
    });
    return deferred.promise;
  }

  getOrgAttributes(orgId){
    let deferred = this.$q.defer();
    this.Organization.getOrgUnitAttributes(orgId).then(attrResponse => {
      deferred.resolve(attrResponse.data);
    });
    return deferred.promise;
  }

  getCampaignDetails(campaignId, locationId, appointmentTypeId) {
    return this.Bloom.getScheduleGroupDetails(campaignId, locationId, appointmentTypeId);
  }

  createSlotHold(scheduleId, location, time, day, duration) {
    return this.Public.createSlotHold(scheduleId, location, time, day, duration);
  }

  getSlotHold(slotHoldId) {
    return this.Public.getSlotHold(slotHoldId);
  }

  releaseSlotHold(slotHoldId) {
    return this.Public.releaseSlotHold(slotHoldId);
  }

  getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, findAttr) {
    return this.$q((resolve) => {
      let units = unitAttributes;
      let orgAttribute = undefined;
      let orgUnitAttribute = undefined;
      let promiseArray = new Array();

      promiseArray.push(this.getOrgUnitAttribute(units, orgId, findAttr));
      promiseArray.push(this.getOrgUnitAttribute(units, orgUnitId, findAttr));

      // SCENARIOS
      // Org IS SET and Org unit IS SET = use org unit attribute
      // Org IS NOT set and Org unit IS SET = use org unit attribute
      // Org IS SET and Org unit IS NOT set = use org attribute
      // Org IS SET and Org unit IS SET to null = use neither

      this.$q.all(promiseArray).then(result => {
        angular.forEach(result, (unit) => {
          if (unit.id === orgUnitId) {
            orgUnitAttribute = unit.attribute;
          }
          if (unit.id === orgId) {
            orgAttribute = unit.attribute;
          }
        });

        if (angular.isDefined(orgUnitAttribute)) {
          resolve(orgUnitAttribute);
        } else if (angular.isDefined(orgAttribute)) {
          resolve(orgAttribute);
        } else {
          resolve();
        }
      });
    });
  }

  getOrgUnitAttribute(units, findUnitId, findAttr) {
    return this.$q((resolve) => {
      angular.forEach(units, (unit) => {
        if (findUnitId == unit.id) {
          angular.forEach(unit.attributes, (attribute) => {
            if (attribute.key == findAttr) {
              resolve({
                id: unit.id,
                attribute: attribute.value
              });
            }
          });
          //if theres no match just resolve with an undefined value
          resolve({
            id: unit.id,
            attribute: undefined
          });
        }
      });
    });
  }

  getAttributeByKey(searchKey, attributes) {
    return this.$q((resolve) => {
      angular.forEach(attributes, (attribute) => {
        if (attribute.key == searchKey) {
          resolve(attribute.value);
        }
      });
      resolve(undefined);
    });
  }

  createMirthAppointment(mirthUrl, apptData) {
    //Process Form Data to give mirth dates in the proper format
    if (angular.isDefined(apptData.documents)) {
      apptData.documents.forEach((document) => {
        if(angular.isDefined(document.data) && document.data != null )
        {
          Object.keys(document.data).forEach((index) => {
            if (document.data[index] instanceof Date) document.data[index] = moment(document.data[index]).format('MM/DD/YYYY');
          })
        }
      });
    }
    return this.Access.forwardUrl(mirthUrl, apptData);
  }

  getCaptchaKey() {
    return this.ConfigManager.getByKey('captcha').public_key;
  }

  getRegistrationFormConfig(currentOrg, currentOrgUnit) {
    return this.$q((resolve, reject) => {
      this.AccessRegistration.getRegistrationConfig(currentOrg, currentOrgUnit)
        .then(response => {
          resolve(response.data);
        }, reject);
    });
  }

  getExtraSchedulingConfigs(orgId, orgUnitId, userAttributes) {
    return this.$q((resolve, reject) => {
      this.Organization.getBulkOrganizationUnitAttributes([orgId, orgUnitId]).then(attrResponse => {
        let unitAttributes = attrResponse.data;
        let promiseArray = new Array();

        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'SELF_SCHEDULING_ERROR_TEXT'));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'SLOT_HOLD_ERROR_MESSAGE'));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'MIRTH_URL'));
        promiseArray.push(this.Organization.getOrganizationUnitPublic(orgUnitId));
        promiseArray.push(this.getAttributeByKey('PROVIDER_ID', userAttributes));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'PROVIDER_ID_REQUIRED'));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'SET_SELF_SCHEDULED_TO_PENDING'));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'SELF_SCHEDULE_ON_FAILED_SEARCH'));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'ONLY_SELF_SCHEDULE_EXISTING_PATIENTS'));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'SELF_SCHEDULING_REGISTRATION_TYPES'));
        promiseArray.push(this.getOrgOrUnitAttribute(orgId, orgUnitId, unitAttributes, 'SELF_SCHEDULING_ENABLE_FORMS'));

        this.$q.all(promiseArray).then(result => {
          resolve({
            errorResponseText: result[0] ? result[0] : 'There was an error processing your appointment.',
            slotHoldError: result[1] ? result[1] : 'The slot you have selected is not available.',
            mirthUrl: result[2] ? result[2] : undefined,
            orgUnit: result[3].data[0],
            provider_id: result[4] ? result[4] : undefined,
            provider_id_required: result[5] === 'true' ? true : false,
            set_self_scheduled_to_pending: result[6] === 'true' ? true : false,
            self_schedule_on_failed_search: result[7] === 'true' ? true : false,
            only_self_schedule_existing_patients: result[8] === 'true' ? true : false,
            registration_types: angular.isDefined(result[9]) ? angular.fromJson(result[9]) : {guest: false, registrationLogin: true},
            enable_forms: result[10] === 'true' ? true : false,
          });
        }, (error) => {
          reject(error);
        });
      }, error => reject(error));
    });
  }

  saveDocument(document) {
    let form = {
      data : {
        id: document.id,
        version: document.version,
      },
      formModel: angular.copy(document.data)
    };
    return this.FormsDomain.submitForm(form);
  }

  getPatientDocumentsIdMap(patientId, appointmentTypeId, appointmentDate) {
    // passing in appointmentDate breaks this call to the backend
    return this.PatientsDomain.getPatientDocuments(patientId, appointmentTypeId, 'Detail', undefined).then((response) => {
      let allDocs = response.data.data.documents;
      let docMap = {};
      allDocs.map(f => {
        docMap[f.formDefinition.id] = f
      });
      return docMap;
    });
  }


}

export default SelfSchedulingService;

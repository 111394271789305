import angular from 'angular';
import component from './simpleDocumentModal.component';
import Upload from 'ng-file-upload';

const dependencies = [Upload];

const simpleDocumentModal = angular
  .module('connect.simpleDocumentList.simpleDocumentModal', dependencies)
  .component('simpleDocumentModal', component)
  .name;

export default simpleDocumentModal;

import template from './communication.template.html';
import controller from './communication.controller';

let component = {
  controller,
  template,
  bindings: {
    comm: '<',
    onCommunicationSave: '&'
  }
};

export default component;

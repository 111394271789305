import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './selfSchedulingDetails.component';
import service from './selfSchedulingDetails.service';

import SlotsWeekCalendar from './slotsWeekCalendar/slotsWeekCalendar';
import SlotsNavigation from './slotsNavigation/slotsNavigation';
import progressModal from '../selfScheduling/progressModal/progressModal';

const dependencies = [
  uiRouter,
  SlotsWeekCalendar,
  SlotsNavigation,
  progressModal
];

const selfSchedulingDetails = angular
  .module('connect.SelfSchedulingDetails', dependencies)
  .component('selfSchedulingDetails', component)
  .service('selfSchedulingDetailsService', service)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('selfSchedulingDetails', {
        url: '/self-scheduling/details/:campaignId/:locationId/:appointmentTypeId',
        template: '<self-scheduling-details></self-scheduling-details>',
        allowUnlogged: true,
        params: { answers: null, }
      });
  })
  .name;

export default selfSchedulingDetails;

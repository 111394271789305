class AccountController {
  constructor(User, flash, Auth) {
    'ngInject';
    this.User = User;
    this.flash = flash;
    this.Auth = Auth;
  }

  $onInit() {
    this.password = {};
    this.getCurrentUser();
  }

  getCurrentUser() {
    this.User.getCurrentUser()
      .then(resp => {
        this.user = resp.data;
        this.getDirectory();
      });
  }

  saveUser($event) {
    this.User.update($event.user)
      .then(() => {
        this.flash.instantMessage('Personal Information Saved Correctly', 'success');
        this.getCurrentUser();
      }, () => this.flash.instantMessage('Cannot Save Personal Information', 'danger'));
  }

  changePassword($event) {
    this.Auth.oauthService(this.user.emailAddress, $event.oldPassword, true).then(() => {
      this.User.resetPasswordSimple(this.user.id, $event.newPassword)
        .then(() => {
          this.flash.instantMessage('Password Changed Correctly', 'success');
        }, () =>  this.flash.instantMessage('Password Change failed', 'danger'));
    }, () => {
      this.flash.instantMessage('Incorrect Password', 'danger');
    });
  }

  deleteAccount() {
    this.User.deleteUser(this.user.emailAddress) 
      .then(() => {
        this.Auth.logout();
      });
  }

  getDirectory() {
    this.User.getPasswordPolicy('0000000001')
      .then(res => this.passwordPolicy = res.data.passwordPolicy);
  }

}

export default AccountController;
import template from './changePassword.template.html';
import controller from './changePassword.controller';

let component = {
  controller,
  template,
  bindings: {
    password: '<',
    onPasswordChange: '&',
    passwordPolicy: '<'
  }
};

export default component;

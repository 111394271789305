import template from './documentModal.template.html';
import controller from './documentModal.controller';

let component = {
  controller: controller,
  template: template,
  bindings: {
    modalInstance: '<',
    resolve: '<',
    close: '&'
  }
};

export default component;

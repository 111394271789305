import controller from './getInLineFormBody.controller';
import template from './getInLineFormBody.template.html';

const component = {
  controller,
  template,
  bindings: {
    config: '<',
    org: '<',
    department: '<',
    user: '<',
    completed: '<',
    processing: '<',
    isRegistered: '&',
    getInLineAction: '&',
    registerAndGetInLineAction: '&',
    getInLineHours: '<',
    documents: '<',
    onDocumentsValid: '&',
    onSaveDocuments: '&',
  }
};

export default component;

class MyWaitingRoomService {
  constructor($q, $sce, $window, Organization, Image) {
    'ngInject';
    this.$q = $q;
    this.$sce = $sce;
    this.$window = $window;
    this.Organization = Organization;
    this.Image = Image;
  }

  retrieveMap(mapId) {
    return this.$q(resolve => {
      var img = {};
      this.Image.getImage(mapId).then(resLogo => {
        img.map = this.$sce.trustAsResourceUrl(this.$window.URL.createObjectURL(new Blob([resLogo.data], {type: 'image/png'})));
        resolve({
          imageMap: img.map
        });
      });
    });
  }

  getDotClass(index, currentIndex){
    var dotClass = 'my-waiting-room__progress__step';
    if(index==0) {
      dotClass += ' my-waiting-room__progress__step--first';
    }
    if(index < currentIndex){
      dotClass += ' completed';
    }
    if(index == currentIndex){
      dotClass += ' active';
    }
    return dotClass;
  }

}

export default MyWaitingRoomService;
class AuthModalController{
  constructor(Access, config) {
    'ngInject';
    this.Access = Access;
    this.config = config
  }

  static get $inject() {
    return ['Access', 'commonLibrary.config'];
  }

  $onInit(){
    this.processing = false;
    this.authModalTitle = 'Authorizing...';
    this.processingText = "Attempting authorization of payment. Do not hit your browser's back button or close this page";
    this.authorize(this.resolve.appointmentId, this.resolve.card);
  }

  authorize(apptId, card) {
    this.processing = true;
    if(this.resolve.vendor === 'instamed') {
      this.instamedAuthorization(apptId, card);
    } else if (this.resolve.vendor === 'elavon') {
      this.elavonAuthorization(apptId, card);
    } else if (this.resolve.vendor === 'authorize.net') {
      this.authNetAuthorization(apptId, card);
    }
  }

  authNetAuthorization(apptId, cardData){

    let authData = {};
    authData.clientKey = angular.copy(this.config.auth_net.client_key);
    authData.apiLoginID = angular.copy(this.config.auth_net.api_login_id);

    let secureData = {};
    secureData.authData = authData;
    secureData.cardData = cardData;

    let responseHandler = (response) => {
      if (response.messages.resultCode === "Error") {
        let i = 0;
        let errorMessage = "";
        while (i < response.messages.message.length) {
          errorMessage += response.messages.message[i].text + " ";
          ++i;
        }
        this.processing = false;
        this.modalInstance.close({status: errorMessage});
      } else {
        cardData.opaqueData = response.opaqueData;
        this.Access.authNetTokenAuthorization(apptId, cardData).then((authResponse) => {
          this.modalInstance.close(authResponse.data);
        },(error) => {
          console.log('PAYMENT_AUTH_ERROR:',error);
          this.processing = false;
          this.modalInstance.close({status: 'ERROR TRYING TO AUTHORIZE'});
        })
      }
    }

    Accept.dispatchData(secureData, responseHandler);
  }

  instamedAuthorization(apptId, card){
    this.Access.instamedSecureTokenAuthorization(apptId, card).then((response) => {
      this.modalInstance.close(response.data);
    },(error) => {
      console.log('PAYMENT_AUTH_ERROR:',error);
      this.processing = false;
      this.modalInstance.close({status: 'ERROR TRYING TO AUTHORIZE'});
    })
  }

  elavonAuthorization(apptId, card){
    this.Access.elavonSecureToken(apptId).then(res => {
      let paymentData = {
        ssl_txn_auth_token: res.data.token,
        ssl_card_number: card.ssl_card_number,
        ssl_exp_date: card.ssl_exp_date,
        ssl_first_name: card.ssl_first_name,
        ssl_last_name: card.ssl_last_name,
        ssl_avs_address: card.ssl_avs_address,
        ssl_avs_zip: card.ssl_avs_zip,
        ssl_cvv2cvc2: card.ssl_cvv2cvc2,
        ssl_merchant_txn_id: apptId,
        ssl_invoice_number: res.data.externalAccountNumber ? res.data.externalAccountNumber : ""
      };
      let callback = {
        onError: (error) => {
          this.processing = false;
          this.modalInstance.close(error);
        },
        onDeclined: (paymentResponse) => {
          if(paymentResponse.errorCode){
            this.modalInstance.close(paymentResponse);
          } else {
            this.Access.elavonRecordPayment(apptId, paymentResponse).then((recordResponse) => {
              this.modalInstance.close(recordResponse.data);
            })
          }
        },
        onApproval: (paymentResponse) => {
          this.Access.elavonRecordPayment(apptId, paymentResponse).then((recordResponse) => {
            this.modalInstance.close(recordResponse.data);
          })
        }
      };
      ConvergeEmbeddedPayment.pay(paymentData, callback);
    })
  }


}
export default AuthModalController;

class DocumentModalService {
  constructor(FormsDomain) {
    'ngInject';
    this.FormsDomain = FormsDomain;
  }

  getFormData(documentId, version) {
    return this.FormsDomain.getFormById(documentId, version);
  }

  printForm(documentId, version) {
    return this.FormsDomain.printForm(documentId, version);
  }
}

export default DocumentModalService;

import angular from 'angular';
import component from './deleteAccount.component';


const deleteAccount = angular
  .module('connect.Account.DeleteAccount', [])
  .component('deleteAccount', component)
  .name;

export default deleteAccount;

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './chatSession.component';

const dependencies = [uiRouter];

const chatSession = angular
  .module('connect.chatSession', dependencies)
  .component('chatSession', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('chatSession', {
        url: '/chat/:appointmentId/:userId',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Chat',
        template: '<chat-session></chat-session>'
      });
  }])
  .name;

export default chatSession;

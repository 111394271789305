class PersonalInformationController {
  constructor() {
    'ngInject';
  }

  save() {
    this.user.name = this.user.emailAddress;
    this.onUserSave({$event:{user: this.user} });
  }
}

export default PersonalInformationController;
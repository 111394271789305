class FormsByMessageController {
  constructor($stateParams, $uibModal, $sce, Access, selfSchedulingService) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.$sce = $sce;
    this.Access = Access;
    this.selfSchedulingService = selfSchedulingService;
  }

  $onInit() {
    this.loading = true;
    this.selfSchedulingService.getOrgInfo().then((orgInfo) => {
      this.orgInfo = orgInfo
      this.getOrgAttributes(orgInfo.orgIds.orgId);
      this.Access.getBlankForms(this.$stateParams.appointmentId, this.$stateParams.token).then(res => {
        this.originalForms = res.data;
        this.totalFormCount = this.originalForms.length;
        this.blankForms = this.originalForms.filter(i => i.status !== 'COMPLETE');
        this.completedFormCount = this.originalForms.filter(i => i.status === 'COMPLETE').length;
        this.loading = false;
      });
    });
  }

  getOrgAttributes(orgId) {
    this.selfSchedulingService.getOrgAttributes(orgId).then(atts => {
      this.orgAtts = atts;
      this.setAttributeValues();
    });
  }

  setAttributeValues() {
    let formByMessageHeaderText = this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_HEADER_TEXT'] ? this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_HEADER_TEXT'] : 'Forms';
    this.formByMessageHeader = this.$sce.trustAsHtml(formByMessageHeaderText);
    let formByMessageBodyText = this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_BODY_TEXT'] ? this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_BODY_TEXT'] : 'Please fill out the forms below';
    this.formByMessageBody = this.$sce.trustAsHtml(formByMessageBodyText);
    let securityText = this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_SECURITY_TEXT'] ?
      this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_SECURITY_TEXT'] : 'Due to inactivity your data has been invalidated. Refresh the page to continue filling out the forms.';
    this.securityMessage = this.$sce.trustAsHtml(securityText);
    let completionText = this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_COMPLETION_TEXT'] ? this.orgAtts.attributesAsMap['FORMS_BY_MESSAGE_COMPLETION_TEXT'] : '';
    this.completionMessage = this.$sce.trustAsHtml(completionText);
  }

  invalidUser($event) {
    if ($event.isInactive) {
      this.showSecurityMessage = true;
    }
  }

  saveDocuments($event) {
    this.Access.saveFormsPublic(this.$stateParams.appointmentId, this.$stateParams.token, $event.doc);
    this.blankForms = this.originalForms.filter(i => i.status !== 'COMPLETE');
    this.completedFormCount = this.originalForms.filter(i => i.status === 'COMPLETE').length;
  }
}

export default FormsByMessageController;

class FormsPatientIntakeService {
  constructor($q, $filter, User, Organization, PatientsDomain, FormsDomain, Access) {
    'ngInject';
    this.$q = $q;
    this.$filter = $filter;
    this.User = User;
    this.Organization = Organization;
    this.PatientsDomain = PatientsDomain;
    this.FormsDomain = FormsDomain;
    this.Access = Access;
  }

  getOrganizations(orgList) {
    return this.$q((resolve, reject) => {
      let r = [];
      let promises = orgList.map(
        (org) => this.Organization.getOrganization(org)
          .then(res => {
            r[res.data.id] = res.data.name;
          }));

      this.$q.all(promises).then(() =>  {
          resolve(r);
        }
        , reject);
    });
  }

  parseForms(patientData) {
    return this.$q((resolve, reject) => {
      let listOfIds = [];
      if (angular.isDefined(patientData.patients)) {
        patientData.patients.forEach(patient => {
          listOfIds.push(patient.organizationId);
        });
        this.getOrganizations(listOfIds)
          .then((orgs) => {
            patientData.patients.forEach(pat => {
              pat.orgName = orgs[pat.organizationId];
            });
            resolve(patientData);
          }, reject);
      } else {
        reject('No Participant Data Found');
      }
    });
  }

  getForms(sessionToken) {
    return this.Access.getIntakeForms(sessionToken);
  }

  saveForm(sessionToken, form) {
    return this.Access.saveIntakeForm(sessionToken, form);
  }
}

export default FormsPatientIntakeService;

class CardCollectionModal {
  constructor($sce, $timeout, config) {
    'ngInject';
    this.$sce = $sce;
    this.$timeout =  $timeout
    this.config = config;
  }

  static get $inject() {
    return ['$sce', '$timeout', 'commonLibrary.config'];
  }

  $onInit() {
    this.processing = true;
    if(this.resolve.vendor === 'instamed'){
      this.setupInstamed();
    } else if(this.resolve.vendor === 'elavon'){
      this.setupElavon();
    }
  }

  cancel(){
    this.modalInstance.dismiss();
  }

  setupElavon(){
    this.processing = false;
    this.cardData = {};
  }

  submitElavon() {
    this.modalInstance.close(this.cardData);
  }

  submitAuthNet() {
    this.modalInstance.close(this.cardData);
  }

  setupInstamed(){
    this.handleResponse = (function(data) {
      if (data.Status == "Cancelled") {
        this.modalInstance.dismiss();
      }
      else {
        this.modalInstance.close(data);
      }
    }).bind(this);
    this.addCardCollector();
  }

  addCardCollector() {
    let manual = true;
    let instamedUrl = this.config.instamed.secure_token.url;
    let accountID = this.config.instamed.secure_token.account;
    let untrustedUrl = InstaMed2.addCard(this.handleResponse, null, instamedUrl, manual, accountID);
    this.trustedUrl = this.$sce.trustAsResourceUrl(untrustedUrl)
    let iFrame = angular.element(document.getElementById('instamed'));
    iFrame.attr('src', this.trustedUrl);
    iFrame.on('load', event => {
      this.$timeout(()=>{this.processing=false}, 2000)
    })
  }





}

export default CardCollectionModal;

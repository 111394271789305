import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './formsByMessage.component';
import simpleDocumentList from "~/shared/simpleDocumentList/simpleDocumentList";

const dependencies = [ uiRouter, simpleDocumentList ];

const formsByMessage = angular
  .module('connect.formsByMessage', dependencies)
  .component('formsByMessage', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('formsByMessage', {
        url: '/documents/appointment/{appointmentId}/{token}',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Forms',
        template: '<forms-by-message></forms-by-message>'
      });
  }])
  .name;

export default formsByMessage;

import angular from 'angular';
import component from './documentModal.component';
import service from './documentModal.service';
import Upload from 'ng-file-upload';

const dependencies = [Upload];

const documentModal = angular
  .module('connect.documentsList.documentModal', dependencies)
  .component('documentModal', component)
  .service('documentModalService', service)
  .name;

export default documentModal;

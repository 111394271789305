class ValidateController {
  constructor( $state, $stateParams, $log, User, flash) {
    'ngInject';
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$log = $log;
    this.User = User;
    this.flash =flash;
  }

  $onInit(){
    this.User.validateEmail(this.$stateParams.token).then(() =>{
      this.verified = true;
    },(er) => {
      this.verified = false;
      this.errorMessage = er.data.consumerMessage;
      this.$log.log('error:', er);
    });
  }

}

export default ValidateController;

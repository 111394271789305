import controller from './rating.controller';
import template from './rating.template.html';

export default {
  controller,
  template,
  bindings:{
    item: '<',
    name: '<',
    disabled: '<'
  }
};

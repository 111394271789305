class CampaignController {
  constructor($uibModal, $state, $stateParams){
    'ngInject';
    this.$uibModal = $uibModal;
    this.$state = $state;
    this.$stateParams = $stateParams;
  }

  $onInit(){
    if (angular.isUndefined(this.details)) {
      this.details = false;
    }

  }

  selectCampaign(){
    this.onCampaignSelection({$event:this.campaign});
  }


  changeLocation() {
    let modalInstance = this.$uibModal.open({
      component: 'campaignLocationsModal',
      size: 'md',
      resolve: {
        'name' : () => this.campaign.name,
        'locations': () => this.locations,
        'imageUrl' : () => this.imageUrl,
        'hours' : () => this.hours
      }
    })
      .result.then((result) => {
        this.$state.go('selfSchedulingDetails', {locationId: result, appointmentTypeId: this.$stateParams.appointmentTypeId});
      });
  }
}

export default CampaignController;
class ControlsController {
  constructor($state, $uibModal, Access, SessionManager) {
    'ngInject';
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.Access = Access;
    this.SessionManager = SessionManager;
  }


  $onInit() {}

  switchVideo() {
    this.onToggleVideo({});
  }

  switchAudio() {
    this.onToggleAudio({});
  }

  endVisit() {
    if (this.hasSaved || !this.hasForms) {
      this.text = 'You are about to end the telehealth session. Are you sure?';
    } else {
      this.text = `You are about to end the telehealth session. <br />Are you sure you want to leave <b>without saving</b> your forms?`;
    }
    this.$uibModal.open({
      component: 'confirm',
      resolve: {
        text: () =>  this.text,
        title: () => 'Telehealth Video',
        Text: () => this.text,
        headerText: () => 'Telehealth Video'
      }
    }).result.then(() => {
      this.$state.go('videoDone', {'appointmentId': this.appointmentId});
      this.room.disconnect();
    });
  }

  flipCamera() {
    if (this.facing === 'user') {
      this.onCameraSwitch({
        $event: 'environment'
      });
    }
    else {
      this.onCameraSwitch({
        $event: 'user'
      });
    }
  }
}

export default ControlsController;

class SimpleDocumentListController {
  constructor($uibModal, $scope, $timeout, $state, SessionManager, $window) {
    'ngInject';
    this.$uibModal = $uibModal;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$state = $state;
    this.SessionManager = SessionManager;
    this.$window = $window;
  }

  $onInit() {
    this.securityMessage = "";
    this.prodActivityDuration = 30 * 60 * 1000; // 30 minutes
    this.devActivityDuration = 0.5 * 60 * 1000; // 30 seconds
    this.checkIfValid();

    this.startTimer();

    this.$scope.$on('modal.closing', (event, sentEvent) => {
      // the 'Route Changed' event was being fired from the back button
      // but we only care about the sentEvent from confirm
      if (sentEvent !== 'Route Changed') {
        switch (sentEvent) {
          case 'backbtn':
            break;
          case 'prevent-back-button':
            event.preventDefault();
            event.stopPropagation(); // this is as far as I want the event to propagate
            break;
          default:
            break;
        }
      }
    });
  }

  startTimer() {
    this.$timeout.cancel(this.inactiveTimer);

    this.inactiveTimer = this.$timeout(() => {
      this.clearData();
    }, this.prodActivityDuration)
  }

  clearData() {
    this.formModel.forEach(form => {
      form.data = null;
    });
    if (angular.isDefined(this.modalInstance)) {
      this.modalInstance.dismiss("invalid");
    }
    this.showSecurityMessage = true;
    this.onInvalidUser({$event: {isInactive: true}})
  }

  $onDestroy() {
    this.$timeout.cancel(this.inactiveTimer);
  }

  $onChanges() {
    this.updateCompletedCount();
  }

  checkIfValid() {
    let isValid = true;

    angular.forEach(this.formModel, doc => {
      if (doc.required && doc.status === 'INCOMPLETE') {
        isValid = false;
      }
    });
    this.updateCompletedCount();
    this.onDocumentsValid({$event: {validDocuments: isValid, documents: this.formModel}});
  }

  updateCompletedCount(){
    this.completedDocuments = 0;
    if(angular.isDefined(this.formModel)){
      this.formModel.forEach(doc => {
        if(doc.status == 'COMPLETE'){
          ++this.completedDocuments
        }
      });
    }
  }

  //TODO need to do this until we can get the forms in the context of an appointment
  checkStatus(status) {
    if (status === 'NEEDS_REVIEW' && this.hideReviewStatus)
      return 'COMPLETE';
    else
      return status;
  }

  openDocumentModal(index) {
    // clicking a form in the list will reset the inactivity timer by firing this function
    this.startTimer();
    this.modalInstance = this.$uibModal.open({
      component: 'simpleDocumentModal',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        formObj: () => this.formModel[index],
      }
    });
    this.modalInstance.result.then(result => {
      if (result.save)
        // could pass obj here like {id: patientId from appt}
        this.onSaveDocuments({$event: {doc: result.doc}});
      this.formModel[index].status = result.doc.status;
      this.formModel[index].data = result.doc.data;
      this.checkIfValid();
    }, form => {
      if (angular.isDefined(form)) {
        // marked review check
        if (form === "inactive") {
          this.clearData();
        }
        this.formModel[index].status = form.status;
      }
    });
  }

}

export default SimpleDocumentListController;

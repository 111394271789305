import angular from 'angular';
import component from './searchBar.component';
import searchBarModal from './searchBarModal/searchBarModal.component';

const searchBar = angular
  .module('connect.SelfScheduling.SearchBar', [])
  .component('searchBar', component)
  .component('searchBarModal', searchBarModal)
  .name;

export default searchBar;

import angular from 'angular';
import component from './hourRange.component';

let dependencies = [];

const hourRange = angular
.module('connect.hourRange', dependencies)
  .component('hourRange', component)
  .name;

export default hourRange;

class TimeoutModalController {
  constructor($timeout, $stateParams, $state, selfSchedulingService) {
    'ngInject';
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.selfSchedulingService = selfSchedulingService;
  }

  $onInit() {
    //show time and ability to extend
    this.modalTitle = 'Do you need more time?';
  }

  timedOut() {
    //change the title and button if the time runs out
    this.modalTitle = 'Your appointment time selection time has expired.';
    this.expired = true;
  }

  cancel() {
    if (!this.expired) {
      //releaseSlotHold then dismiss
      this.selfSchedulingService.releaseSlotHold(this.$stateParams.slotId).then(() => {
        this.modalInstance.dismiss('Registration Cancelled');
        this.$state.go('selfSchedulingDetails',{campaignId: this.$stateParams.campaignId, locationId: this.resolve.slotData.slot.slotTimeDuration.location.id, appointmentTypeId: this.$stateParams.appointmentTypeId });
      });
    } else {
      this.modalInstance.dismiss('Registration Cancelled');
      this.$state.go('selfSchedulingDetails',{campaignId: this.$stateParams.campaignId, locationId: this.resolve.slotData.slot.slotTimeDuration.location.id, appointmentTypeId: this.$stateParams.appointmentTypeId });
    }
  }

  extendTime() {
    this.selfSchedulingService.releaseSlotHold(this.$stateParams.slotId).then(() => {
      this.selfSchedulingService.createSlotHold(
        this.resolve.slotData.slot.scheduleId,
        this.resolve.slotData.slot.slotTimeDuration.location,
        this.resolve.slotData.slot.slotTimeDuration.time,
        this.resolve.slotData.slot.slotTimeDuration.day,
        this.resolve.slotData.slot.slotTimeDuration.duration
      ).then((response) => {
        this.modalInstance.close(response.data);
      });
    });
  }
}

export default TimeoutModalController;

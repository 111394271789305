export default class surveyController{
    constructor($state, SurveyPageService, flash, $window){
        'ngInject';
        this.$state = $state;
        this.SurveyPageService = SurveyPageService;
        this.flash = flash;
        this.$window = $window;
    }

    $onInit(){
        this.loading = true;
        this.message = {};
        this.message.finished = false;
        this.showRedirect = false;
        this.SurveyPageService.getData(this.$state.params.surveyId, this.$state.params.organizationId, this.$state.params.appointmentId).then((data) => {
            this.data = data;
            this.surveyTitle = angular.isDefined(this.data.template.name) ? this.data.template.name : "Patient Survey";
            if (this.data.survey.completed || this.data.survey.isCompleted) {
                this.message = {
                    text: 'Survey Already Completed',
                    type: 'warning',
                    finished: true
                };
                this.redirectCheck();
            }
            this.loading = false;
        });
    }

    redirectCheck(){
        let ratingCount = 0;
        let ratingTotal = 0;
        this.data.survey.answers.forEach(itm => {
            if(itm.question.type === 'RATING'){
                ++ratingCount;
                ratingTotal += (itm.question.choices.indexOf(itm.answer) + 1);
            }
        });
        if(ratingCount > 0){
            let ratingAverage = ratingTotal/ratingCount;
            if(ratingAverage >= this.data.template.redirectThreshold){
                this.showRedirect = true;
            }
        }
    }

    redirect(url){
        this.$window.open(url);
    }

    submitSurvey() {
        this.data.survey.isCompleted = true;
        this.data.survey.completed = true;
        this.SurveyPageService.saveData(this.data.survey).then(() => {
            this.message = {
                text: 'Survey Submitted',
                type: 'info',
                finished: true
            };
            this.redirectCheck();
        });
    }
}
import template from './slotsNavigation.template.html';
import controller from './slotsNavigation.controller';

const component = {
  controller,
  template,
  bindings: {
    pivotDate: '<',
    calendar: '<',
    weekNumber: '<',
    onWeekSelection: '&',
    onPrevMonth: '&',
    onNextMonth: '&',
    onPrevWeek: '&',
    onNextWeek: '&'
  }
};

export default component;

class MyDocumentsController {
  constructor(myDocumentsService, $log, SessionManager, $location) {
    'ngInject';
    this.myDocumentsService = myDocumentsService;
    this.$log = $log;
    this.SessionManager = SessionManager;
    this.$location = $location;
  }

  $onInit() {
    this.patientData = null;
    this.processing = true;
    this.myDocumentsService.getHideMyDocuments().then((hideMyDocuments) => {
      if (hideMyDocuments) {
        this.$location.path("");
      }
    });

    this.myDocumentsService.getForms(this.SessionManager.getCurrentUser().id)
      .then(response => {
        this.processing = false;
        response.patients.forEach(patient => {
          patient.forms = patient.forms.filter(i => !i.hideFromPatient);
        });
        this.patientData = response.patients.filter(patient => patient.forms.length > 0);
      })
      .catch(reason => {
        this.processing = false;
        this.$log.info('Execution rejected: ',reason);
      });
  }

  saveDocuments($event) {
    this.myDocumentsService.saveForm($event.doc).then(res => {
      angular.forEach(this.patientData, (patient) => {
        if (patient.id === $event.patientId) {
          angular.forEach(patient.forms, (doc, index, docs) => {
            if (doc.id === res.data.data.id) {
              docs[index].version = res.data.data.version;
              docs[index].data = res.data.formModel;
            }
          });
        }
      });
    });
  }

}

export default MyDocumentsController;

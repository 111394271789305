import template from './campaign.template.html';
import controller from './campaign.controller';

const component = {
  controller,
  template,
  bindings: {
    campaign: '<',
    details: '<',
    categories: '<',
    imageUrl: '<',
    onCampaignSelection: '&',
    locations: '<'
  }
};

export default component;

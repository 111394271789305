import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uibAlert from 'angular-ui-bootstrap/src/alert';
import RootController from './index.controller';
import 'angular-spinner';
import config from './config';
import header from './header/header';
import footer from '~/shared/footer/footer.component';
import login from './login/login';
import signUp from './signUp/signUp';
import signUpSuccess from './signUp/signUpSuccess/signUpSuccess';
import myEvents from './myEvents/myEvents';
import formsByMessage from "./formsByMessage/formsByMessage";
import myDocuments from './myDocuments/myDocuments';
import myConversations from './myConversations/myConversations';
import waitingRoom from './waitingRoom/waitingRoom';
import resetPassword from './resetPassword/resetPassword';
import forgotPassword from './forgotPassword/forgotPassword';
import getInLine from './getInLine/getInLine';
import arrived from './arrived/arrived';
import chatSession from "./chatSession/chatSession";
import account from './account/account';
import claimPatient from './claimPatient/claimPatient';
import validate from './validate/validate';
import payment from './payment/payment';
import paymentPatientIntake from '~/patientIntake/patientIntakePayment/payment';
import formsPatientIntake from './patientIntake/patientIntakeForms/forms';
import questionnaire from './questionnaire/questionnaire';
import selfScheduling from './selfScheduling/selfScheduling';
import selfSchedulingDetails from './selfSchedulingDetails/selfSchedulingDetails';
import pinElementOnScroll from './shared/pinElementOnScroll/pinElementOnScroll.directive';
import validateDOB from './shared/validateDOB/validateDOB.directive';
import sharedFilters from './shared/filters/filter';
import checkIn from './checkin/checkin';
import videoVisit from './videoVisit/videoVisit';
import survey from './surveys/survey';
import videoDone from "./videoDone/videoDone";

import 'angular-telephone-filter';
//jf-ui-features imports
import '@jellyfish-health/features/dist/app.min-debug';
import '@jellyfish-health/features/dist/tpl';

//jf-ui-library imports
import '@jellyfish-health/library/common-library';
import '@jellyfish-health/library/businessLayer/AppointmentsDomain';
import '@jellyfish-health/library/businessLayer/FormsDomain';
import '@jellyfish-health/library/businessLayer/PatientsDomain';
import '@jellyfish-health/library/interceptors/authInterceptor';
import '@jellyfish-health/library/interceptors/querystringInterceptor';
import '@jellyfish-health/library/interceptors/analyticsInterceptor';
import '@jellyfish-health/library/services/analyticsService';
import '@jellyfish-health/library/services/accessRegistrationService';
import '@jellyfish-health/library/services/accessService';
import '@jellyfish-health/library/services/appointmentService';
import '@jellyfish-health/library/services/auditService';
import '@jellyfish-health/library/services/authService';
import '@jellyfish-health/library/services/bloomService';
import '@jellyfish-health/library/services/departmentService';
import '@jellyfish-health/library/services/defaultFields';
import '@jellyfish-health/library/services/flashService';
import '@jellyfish-health/library/services/flowService';
import '@jellyfish-health/library/services/formService';
import '@jellyfish-health/library/services/imageService';
import '@jellyfish-health/library/services/locationService';
import '@jellyfish-health/library/services/notificationService';
import '@jellyfish-health/library/services/organizationService';
import '@jellyfish-health/library/services/patientsService';
import '@jellyfish-health/library/services/participantRegService';
import '@jellyfish-health/library/services/populationDomainService';
import '@jellyfish-health/library/services/populationService';
import '@jellyfish-health/library/services/queueHistoryService';
import '@jellyfish-health/library/services/queueService';
import '@jellyfish-health/library/services/smsService';
import '@jellyfish-health/library/services/sessionManagerService';
import '@jellyfish-health/library/services/ticketService';
import '@jellyfish-health/library/services/userService';
import '@jellyfish-health/library/services/jfUtilities';
import '@jellyfish-health/library/services/userUtilitiesService';
import '@jellyfish-health/library/services/surveyService';
import '@jellyfish-health/library/services/webSocketService';
import '@jellyfish-health/library/services/timerService';
import '@jellyfish-health/library/services/waitingRoomService';
import '@jellyfish-health/library/services/getInLineService';
import '@jellyfish-health/library/services/messageBuilderService';
import '@jellyfish-health/library/services/configManagerService';
import '@jellyfish-health/library/services/scheduleService';
import '@jellyfish-health/library/services/userConfigService';
import '@jellyfish-health/library/services/publicService';
import '@jellyfish-health/library/services/splitService';
import '@jellyfish-health/library/services/ssoService';
import '@jellyfish-health/library/businessLayer/formsDomain';
import '@jellyfish-health/library/businessLayer/appointmentsDomain';
import '@jellyfish-health/library/businessLayer/usersDomain';

let dependencies = [
  uiRouter,
  uibAlert,
  'angularSpinner',
  'bc.TelephoneFilter',
  header,
  login,
  signUp,
  signUpSuccess,
  waitingRoom,
  resetPassword,
  forgotPassword,
  config,
  myEvents,
  formsByMessage,
  myDocuments,
  myConversations,
  getInLine,
  arrived,
  chatSession,
  account,
  'commonFeatures',
  claimPatient,
  validate,
  selfScheduling,
  selfSchedulingDetails,
  payment,
  formsPatientIntake,
  paymentPatientIntake,
  questionnaire,
  'commonLibrary',
  sharedFilters,
  checkIn,
  videoVisit,
  survey,
  videoDone
];

angular
  .module('connect', dependencies)
  .directive('pinElement', ['$window', pinElementOnScroll])
  .directive('validateBirthday', validateDOB)
  .component('jfhFooter', footer)
  .config(($locationProvider, $urlRouterProvider, $stateProvider, $httpProvider, usSpinnerConfigProvider) => {
    'ngInject';
    $httpProvider.interceptors.push('QuerystringInterceptor');
    $httpProvider.interceptors.push('AnalyticsInterceptor');
    $locationProvider.html5Mode({
      enabled: true,
      requireBase: true
    }).hashPrefix('!');
    $urlRouterProvider.otherwise('/');

    usSpinnerConfigProvider.setDefaults({color: '#01576B', radius: 20, lines: 12, length: 0, corners: 15, speed: 1.3});
    usSpinnerConfigProvider.setTheme('smallWhite', {color: 'white', radius: 6, lines: 8});
    usSpinnerConfigProvider.setTheme('smallBlue', {radius: 6, lines: 8});
    usSpinnerConfigProvider.setTheme('largeWhite', {color: 'white'});
    usSpinnerConfigProvider.setTheme('largeBlue', {}); //default
  })
  .controller('RootController', RootController)
  .run(($rootScope, $state, $window, $location, Public, $uibModalStack) => {
    'ngInject';


    let host = $location.host();

    if ( host.indexOf('localhost') < 0 ) {
      host = host.replace('http://', '');
      host = host.replace('https://', '');
      host = host.replace('ftp://', '');
      if(host.indexOf('@') > -1){
        host = host.split('@')[1];
      }
      let stripColons = host.split(':');
      let i = stripColons.length< 2 ? 1 : 2;
      host = stripColons[stripColons.length-i];
      host = host.split('/')[0];
      host = host.split('.');
      $rootScope.hostURL = host[host.length-2] + '.' + host[host.length-1];
      host = host[host.length-2];
    }

    //eslint-disable-next-line no-undef
    pendo.initialize({ visitor: {id:''}, account: {id:''} });

    // Dynamically load Accept.js Script
    let fullURL = $location.absUrl();
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.charset='utf-8';
    if ( fullURL.indexOf('0.0.0.0') > -1 || fullURL.indexOf('localhost') > -1 || fullURL.indexOf('staging') > -1 || fullURL.indexOf('stage') > -1) {
      script.src = 'https://jstest.authorize.net/v1/Accept.js';
    } else {
      script.src = 'https://js.authorize.net/v1/Accept.js';
    }
    document.getElementsByTagName('head')[0].appendChild(script);


    if( host.indexOf('jellyfish') > -1 || host.indexOf('localhost') > -1 || host.indexOf('undefined') > -1)  {
      $rootScope.appTitle = 'Connect';
      $rootScope.brandStyleDirectory = './';
      $rootScope.appFavicon = $rootScope.brandStyleDirectory + 'favicon.ico';
      $rootScope.whiteLabel = false;
    } else {
      $rootScope.appTitle = (angular.isString(host) && host.length > 0) ? host.charAt(0).toUpperCase() + host.substr(1).toLowerCase() : host;
      $rootScope.brandStyleDirectory = 'https://s3.amazonaws.com/jfhclients/whitelabel/' + host + '/';
      $rootScope.brandStyle = $rootScope.brandStyleDirectory + 'style/brand.css?a=' + new Date().getTime();
      $rootScope.appFavicon = $rootScope.brandStyleDirectory + 'img/favicon.ico';
      $rootScope.whiteLabel = true;
    }

    if(!$rootScope.whiteLabel) {
      Public.getStyleResource().then((response) => {
        //Set the base and brand css files as well as the brand style directory for images
        $rootScope.brandStyleDirectory = response;
        $rootScope.brandStyle = response + 'style/brand.css?a=' + new Date().getTime();
      }, () => {
        //If this gets an error then it's on localhost
        $rootScope.brandStyle = '/brand.css';
      });
    }

    if($window.decodeURIComponent($location.url()).contains('#/reset_password')) {
      // console.log('url is: ', $window.decodeURIComponent($location.url()));

      let newUrl = $window.decodeURIComponent($location.url());
      newUrl = newUrl.replace('#/', '');
      // console.log('new url is', newUrl);
      $window.location.href = newUrl;
      $state.preventDefault();
    }

    /* disable google-analytics
    let stateChangeSuccessListener = $rootScope.$on('$stateChangeSuccess', function () {
      if ($window.location.href.indexOf('localhost') == -1 && $window.location.href.indexOf('staging') == -1 && $window.location.href.indexOf('qc') == -1 && $window.location.href.indexOf('dev1') == -1) {
        $window.ga('send', 'pageview', {page: $location.url()});
      }
    });
     */

    let $stateChangeStartListener = $rootScope.$on('$stateChangeStart', function () {
      //close any open modals when routing to a new page
      $uibModalStack.dismissAll('Route Changed');
    });

    $rootScope.$on('$destroy', function () {
      //stateChangeSuccessListener(); disable google-analytics
      $stateChangeStartListener();
    });

    angular.extend($rootScope, {
      GetState: () => $state.current
    });
  });

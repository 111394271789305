class ChangePasswordController {
  constructor() {
    'ngInject';
  }

  save() {
    this.onPasswordChange({$event:{newPassword: this.password.newPassword, oldPassword: this.password.oldPassword}});
  }

}

export default ChangePasswordController;
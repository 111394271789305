import template from './hoursSchedule.template.html';
import controller from './hoursSchedule.controller';

const component = {
  controller: controller,
  template: template,
  bindings: {
    schedule: '<',
    onOpen: '&',
    inline: '<'
  }
};

export default component;

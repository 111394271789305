import template from './searchBar.template.html';
import controller from './searchBar.controller';

const component = {
  controller,
  template,
  bindings: {
    onSearch: '&'
  }
};

export default component;

import template from './threadReply.template.html';
import controller from './threadReply.controller';

let component = {
  controller: controller,
  template: template,
  bindings: {
    thread: '<',
    user: '<',
    conversations: '<',
    appointment: '<',
    onRead: '&',
  }
};

export default component;

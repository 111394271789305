import {createLocalTracks, isSupported} from 'twilio-video';
const AudioContext = window.AudioContext || window.webkitAudioContext;
const audioContext = AudioContext ? new AudioContext() : null;

class VideoPreviewController {
  constructor($timeout) {
    'ngInject';
    this.$timeout = $timeout;
  }

  $onInit() {
    this.setupPreview();
  }

  setupPreview() {
    this.loaded = false;
    this.allowed = false;
    this.supported = isSupported;
    this.audio = true;
    this.soundHeard = false;
    this.video = true;
    this.facing = 'user';
    createLocalTracks( {
        audio: true,
        video: {
          height: 480,
          width: 640,
          facingMode: 'user'
        }
      }).then( localTracks => {
      this.localTracks = localTracks;
      audioContext.resume().then(()=> {
        this.$timeout(()=>{
          this.loaded = true;
          this.allowed = true;},0);

        this.cleanContainer('local-media');
        this.attachTrack('local-media', this.localTracks[1]);
        this.localTracks.forEach(track => {
          if(track.kind == 'video' && track.mediaStreamTrack.readyState == 'live'){
            this.videoSeen = true;
          }
          if(track.kind == 'audio' && track.mediaStreamTrack.readyState == 'live'){
            this.soundHeard = true;
          }
        })
      })
    }, error => {
      this.$timeout(()=>{ this.loaded = true; },0);
      if (String(error).indexOf('denied') > -1) {
        this.$timeout(()=>{ this.allowed = false; },0);
      }
    });
  }

  cleanContainer(id) {
    // eslint-disable-next-line
    let container = document.getElementById(id);
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
  }

  attachTrack(id, track) {
    // eslint-disable-next-line
    let container = document.getElementById(id);
    container.appendChild(track.attach());
  }

  ready() {
    this.modalInstance.close({tracks: this.localTracks});
  }

}

export default VideoPreviewController;

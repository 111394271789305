import moment from 'moment';

export default class appointmentDetailsCard {
  constructor($log, $state, $stateParams, $uibModal, selfSchedulingService, $window) {
    'ngInject';
    this.$log = $log;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.$uibModal = $uibModal;
    this.selfSchedulingService = selfSchedulingService;
    this.$window = $window;
  }

  $onInit() {
    this.stickFix = !this.doesSupportSticky() && this.$window.innerWidth > 780 ? {position: 'fixed'} : {};
    this.stickFixMobile = !this.doesSupportSticky() && this.$window.innerWidth <= 780 ? {'position': 'fixed',
    'top': 0,
    'left': 0,
    'width': '100%',
    'z-index': 100,
    'box-shadow': '0 0 10px rgba(0, 0, 0, .4)',
    'border-radius': 0} : {};
    this.stickFixMobileError = !this.doesSupportSticky() && this.$window.innerWidth <= 780 ? {'width': '90vw',
    'margin-right': '5vw',
    'position': 'fixed',
    'z-index': 9999,
    'margin-top': '100px',
    'margin-left': '5vw',
    'top': 0} : {};
  }

  doesSupportSticky() {
    let el = document.createElement('a'),
        mStyle = el.style;
    mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;";
    return mStyle.position.indexOf('sticky')!==-1;
  }

  $onChanges(changes) {
    if (angular.isDefined(changes.slotData) &&
      angular.isDefined(changes.slotData.currentValue) &&
      changes.slotData.currentValue != null) {

      this.slotData = changes.slotData.currentValue;
      let dateTime = moment.tz(this.slotData.slot.slotTimeDuration.day + 'T' + this.slotData.slot.slotTimeDuration.time, this.slotData.slot.slotTimeDuration.location.timezone);
      this.date = dateTime.format('ddd, MMM Do YYYY');
      this.time = dateTime.format('h:mm a');
    } else if (changes.slotData){
      this.slotData = null;
    }
  }

  closeAlerts() {
    this.onCloseAlerts({
      $event: {}
    });
  }

  editSlot() {
    this.selfSchedulingService.releaseSlotHold(this.$stateParams.slotId).then(() => {
      this.$state.go('selfSchedulingDetails', {
        campaignId: this.$stateParams.campaignId,
        locationId: this.slotData.slot.slotTimeDuration.location.id,
        appointmentTypeId: this.$stateParams.appointmentTypeId
      });
    }, error => {
      this.$state.go('selfSchedulingDetails', {
        campaignId: this.$stateParams.campaignId,
        locationId: this.slotData.slot.slotTimeDuration.location.id,
        appointmentTypeId: this.$stateParams.appointmentTypeId
      });
      this.$log.error(error);
    });
  }

  onTimeout($event) {
    this.timedOut({$event: $event});
  }

  showTimeoutModal() {
    let modalInstance = this.$uibModal.open({
      component: 'timeoutModal',
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        slotData: () => this.slotData,
        now: () => moment(),
        expireTime: () => this.expireTime
      }
    });

    modalInstance.result.then((newSlotData) => {
      //The slot hold was extended, so reset the time and slot id state param
      this.$state.go('campaignRegistration', {
        campaignId: this.$stateParams.campaignId,
        slotId: newSlotData.id
      }, {notify: false});
      this.slotData = newSlotData;
    }, () => {
      //the user selected to cancel the registration
      //redirection happens within the modal
    });
  }

}

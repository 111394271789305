import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './resetPassword.component';


const resetPassword = angular
  .module('connect.resetPassword', [uiRouter, 'commonLibrary'])
  .component('resetPassword', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider.state('reset-password', {
      url: '/reset_password/{token}',
      allowUnlogged: true,
      onlyUnlogged: true,
      template: '<reset-password></reset-password>'
    });
  }])
  .name;

export default resetPassword;

import moment from 'moment';

class SelfSchedulingDetailsService {
  constructor($q, $http, Bloom, Organization){
    'ngInject';
    this.$q = $q;
    this.$http = $http;
    this.Bloom = Bloom;
    this.Organization = Organization;
  }

  getCampaignDetails(campaignId, locationId, appointmentTypeId) {
    return this.Bloom.getScheduleGroupDetails(campaignId, locationId, appointmentTypeId);
  }

  getCustomText(orgId){
    let deferred = this.$q.defer();
    this.Organization.getOrgUnitAttributes(orgId).then(attrResponse => {
      let errorMessage = attrResponse.data.attributesAsMap['SELF_SCHEDULE_ERROR'];
      let slotErrorMessage = attrResponse.data.attributesAsMap['SLOT_HOLD_ERROR_MESSAGE'];
      let headerText = attrResponse.data.attributesAsMap['SELF_SCHEDULING_DETAIL_HEADER'];
      let obj = {errorMessage: errorMessage, headerText: headerText, slotErrorMessage: slotErrorMessage};
      deferred.resolve(obj);
    });
    return deferred.promise;
  }

  //Find the provider image in the attributes and attach it to the outer campaign object.
  setImageUrl(campaignData) {
    let imageAttribute = campaignData.userAttributes.attributes.find(attribute => attribute['key'] === 'LOGO_URL');
    if(angular.isDefined(imageAttribute)) {
      return imageAttribute['value'];
    }
  }

  // DEBT: could definitely be more performant by storing responses and fetching future months in the background
  getSlotsFromCampaign(campaignData, pivotDate, appointmentTypeId, locationId) {
    let deferred = this.$q.defer();
    let start;
    if( moment(pivotDate).isSameOrBefore(moment())) {
      start = moment().valueOf();
    } else {
      start = moment(pivotDate).startOf('month').startOf('week').valueOf();
    }
    let end = moment(pivotDate).endOf('month').endOf('week').valueOf();
    this.Bloom.getScheduleGroupDetails(campaignData.campaign.id, locationId, appointmentTypeId, moment(start).format("YYYY-MM-DD"), moment(end).format("YYYY-MM-DD")).then(resp => {
      let campaignData = resp.data;
      let slots = [];
      campaignData.schedules.forEach(schedule => {
        if(angular.isArray(schedule.appointmentTypeIds) && schedule.appointmentTypeIds.length>0) {
          schedule.appointmentTypeIds.forEach(id => {
            if (id == appointmentTypeId) {
              slots = slots.concat(schedule.nextAvailableSlots);
            }
          })
        }
        else
        {
          slots = slots.concat(schedule.nextAvailableSlots);
        }
      });

      deferred.resolve(slots);
    });
    return deferred.promise;
  }
}

export default SelfSchedulingDetailsService;

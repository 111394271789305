import angular from 'angular';
import component from './getInLineFormBody.component';
import loggedWidget from '~/shared/loggedWidget/loggedWidget';
import registerForm from '~/shared/registerForm/registerForm';

const dependencies = [
  loggedWidget,
  registerForm
];

const getInLineFormBody = angular
  .module('connect.getInLine.getInLineForm.getInLineFormBody', dependencies)
  .component('getInLineFormBody', component)
  .name;

export default getInLineFormBody;

class FindWaitingRoomController {
  constructor($state) {
    'ngInject';
    this.$state = $state;
  }
  goToWaitingRoom() {
    this.$state.go('myWaitingRoom', this.loginData);
  }
}

export default FindWaitingRoomController;

import template from './simpleDocumentList.template.html';
import controller from './simpleDocumentList.controller';

let component = {
  controller: controller,
  template: template,
  bindings: {
    formModel: '<',
    onSaveDocuments: '&',
    onDocumentsValid: '&',
    onInvalidUser: '&',
    hideReviewStatus: '<'
  }
};

export default component;

import moment from 'moment-timezone';

class CampaignSchedulePreviewController {
  constructor() {
    'ngInject';
  }

  getDisplayTime(time) {
    return moment(time).tz(this.timezone).format('h:mm a');
  }

  selectSlot(slot) {
    this.onSlotSelection({$event: slot});
  }

  viewSchedule() {
    if (this.appointmentTypeIds != null && angular.isDefined(this.appointmentTypeIds) && this.appointmentTypeIds.length == 1) {
      this.onViewSchedule({
        $event: {
          campaignId: this.campaignId,
          locationId: this.location.id,
          appointmentTypeId: this.appointmentTypeIds[0]
        }
      });
    } else {
      this.onViewSchedule({
        $event: {
          campaignId: this.campaignId,
          locationId: this.location.id
        }
      });
    }
  }

  getTimeTitle(time, index) {
    let today = moment().endOf('day');
    let title;
    let slot = moment(time);
    if (slot.diff(today) < 0) {
      title = 'Today';
    } else if (slot.clone().diff(today.add(1, 'day')) < 0) {
      title = 'Tomorrow';
    } else {
      title = slot.format('MMM Do');
    }
    if (index > 0 && this.getTimeTitle(this.slots[index - 1], -1) == title) {
      title = '';
    }
    return title;
  }


}

export default CampaignSchedulePreviewController;

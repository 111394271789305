import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './myEventDetails.component';
import service from '../myEvents.service';
import pricingFilter from './pricingFilter/pricingFilter';
import uibModal from 'angular-ui-bootstrap/src/modal';
import confirmModal from '../../shared/confirmModal/confirmModal.component';
import copayWidget from '@jellyfish-health/features/src/payments/copayWidget';
import threadList from "~/shared/threadList/threadList";

const myEventDetails = angular
  .module('connect.MyEvents.Details', [uiRouter, uibModal, 'commonLibrary', copayWidget, threadList])
  .component('myEventDetails', component)
  .component('confirm', confirmModal)
  .service('myEventDetailsService', service)
  .filter('pricingFilter', pricingFilter)
  .config(['$stateProvider', '$httpProvider', ($stateProvider, $httpProvider) => {
    'ngInject';
    $stateProvider
      .state('myEventDetails', {
        url: '/my-events/event-details/:eventId/:appointmentId',
        template: '<my-event-details></my-event-details>',
        title: 'Event Details',
        params: {
          flashMessage: null,
          section: null
        }
      });

    $httpProvider.interceptors.push(($q) => {
      return {
        responseError: (response) => {
          if (response.status == 403) {
            // not logout (to redirect to claimPatient)
            response.config.overrideLogout = true;
          }
          return $q.reject(response);
        }
      };
    });
  }])
  .name;

export default myEventDetails;

export default class paymentConfirmController {
  constructor ($state, $stateParams, Access, Appointment, $uibModal, $filter, flash) {
    'ngInject';
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.Access = Access;
    this.Appointment = Appointment;
    this.$uibModal = $uibModal;
    this.$filter = $filter;
    this.flash = flash;
  }

  $onInit() {
    this.selectedCard = undefined;
    this.loading = true;
    this.error = false;
    this.completedTransaction = {};
    this.vendor = null;
    this.getCopayData();
  }

  getCopayData(){
    this.Access.getCopayDataByAppointment(this.$stateParams.id).then(res => {
      this.copayData = res.data;
      this.vendor = res.data.source;
      this.copayData.paymentAttempts.forEach(attempt => {
        if(attempt.completedOn > 0){
          this.completedTransaction = attempt;
        }
      });
      this.loading = false;
    }, err => {
      this.error = true;
      this.loading = false;
    });
  }

  openCardModal() {
    if(this.vendor) {
      let modalInstance = this.$uibModal.open({
        component: 'cardCollectionModal',
        backdrop: 'static',
        size: 'md',
        resolve: {
            'title' : () => 'Card Collection',
            'vendor' : () => this.vendor,
        }
      })
      modalInstance.result.then((result) => {
        let tempCard = angular.copy(result);
          if(this.vendor === 'instamed') {
            tempCard.maskedNumber = InstaMed2.maskCardNumber(result.Token)
          } else if (this.vendor === 'elavon') {
            tempCard.maskedNumber = 'XXXXXXXXXXXX' + result.ssl_card_number.substring(12) ;
            tempCard.ExpDate = result.ssl_exp_date.substring(0,2) + '/' + result.ssl_exp_date.substring(2);
          } else if (this.vendor === 'authorize.net') {
            tempCard.maskedNumber = 'XXXXXXXXXXXX' + result.cardNumber.substring(12) ;
            tempCard.ExpDate = result.month.substring(0,2) + '/' + result.year.substring(0,2);
          }
        this.selectedCard = tempCard;
        this.submitPayment();
      });
    }
  }

  submitPayment() {
    let modalInstance = this.$uibModal.open({
      component: 'authModal',
      backdrop: 'static',
      size: 'sm',
      resolve: {
        'card': () => this.selectedCard,
        'appointmentId': () => this.$stateParams.id,
        'vendor': () => this.vendor
      }
    })

    modalInstance.result.then((result) => {
      this.selectedCard = undefined;
      if(result.errorCode) {
        this.flash.instantMessage(result.errorCode+': '+result.errorName+ ': '+result.errorMessage, 'danger');
      } else {
        this.completedTransaction = result;
        if(this.completedTransaction.status == 'APPROVED'){
          this.copayData.status = 'PAID';
        } else { //show error, please try again
          this.flash.instantMessage('There was a problem with your payment, please try again or use a different card. Status: '+ this.completedTransaction.status, 'danger');
        }
      }
    });
  }

}


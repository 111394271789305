import moment from 'moment-timezone';

class SelfSchedulingDetailsController {
  constructor($state, $stateParams, $uibModal, selfSchedulingDetailsService, Organization, $log){
    'ngInject';
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.selfSchedulingDetailsService = selfSchedulingDetailsService;
    this.$uibModal = $uibModal;
    this.Organization = Organization;
    this.details = true;
    this.$log = $log;
  }

  $onInit(){
    this.appointmentTypeId = this.$stateParams.appointmentTypeId;
    this.questionnaireAnswers = this.$stateParams.answers;
    this.locationId = this.$stateParams.locationId;
    this.currentSchedule = {};
    this.currentLocation = {};
    this.currentDepartmentSchedule = {};
    this.pivotDate = moment().valueOf();
    this.tab = 'schedule';
    this.showError = false;
    this.errorMessage = '<div class="alert alert-danger">There was an error processing your request, please try again.</div>';
    this.getDetails();
  }

  getDetails(){
    this.loading = true;
    this.selfSchedulingDetailsService.getCampaignDetails(this.$stateParams.campaignId, null, this.appointmentTypeId).then((resp) => {
      this.campaignData = resp.data;
      this.setCurrentScheduleAndLocation(resp.data);
      this.getScheduleStart(this.currentSchedule);
      this.campaignData.imageUrl = this.selfSchedulingDetailsService.setImageUrl(this.campaignData);
      this.getSlots(true);
      this.getCustomText(this.campaignData.campaign.organizationId);
    }, () => {
      this.loading = false;
    });
  }

  setCurrentScheduleAndLocation(campaign) {
    if (this.locationId.length < 1 || angular.isUndefined(this.locationId)) {
      this.loading = false;
      this.locationId = campaign.locations[0].id;
      this.currentLocation = campaign.locations[0];
      this.$state.go('selfSchedulingDetails', {locationId: this.locationId, appointmentTypeId: this.$stateParams.appointmentTypeId});
    }
    angular.forEach(campaign.schedules, (schedule) => {
      angular.forEach(schedule.resources, (resource) => {
        if (resource['type'] === 'LOCATION' && resource['id'] === this.locationId) {
          this.currentSchedule = schedule;
        }
      });
    });

    angular.forEach(campaign.locations, (location) => {
      if (location.id === this.locationId) {
        this.currentLocation = location;
      }
    });

    angular.forEach(campaign.departmentHours, (departmentSchedule) => {
      if (this.currentLocation.organizationalUnitId === departmentSchedule.organizationalUnitId) {
        this.currentDepartmentSchedule = departmentSchedule;
      }
    });
  }

  getScheduleStart(schedule){
    let scheduleStart = moment(schedule.startDate, 'YYYY-MM-DD').valueOf();
    if(scheduleStart>this.pivotDate){
      this.pivotDate = scheduleStart;
    }
    this.dateYear = moment(this.pivotDate).startOf('year').valueOf();
  }

  getCustomText(orgId){
    this.selfSchedulingDetailsService.getCustomText(orgId).then((obj) => {
      if(angular.isDefined(obj.errorMessage))
        this.errorMessage = obj.errorMessage;
      this.providerDetailsText = obj.headerText ? obj.headerText : 'Provider Details';
      this.slotErrorMessage = obj.slotErrorMessage ? '<div class="alert alert-danger">' + obj.slotErrorMessage + '</div>'
          : '<div class="alert alert-danger">The slot you have selected is over capacity please try again</div>';
    });
  }

  getSlots(findFirst){
    this.loadingSlots = true;
    this.selfSchedulingDetailsService.getSlotsFromCampaign(this.campaignData, this.pivotDate, this.appointmentTypeId, this.locationId).then(resp => {
      this.allSlots = resp.sort();
      this.updateCalendarWithSlotTimes(findFirst);
      this.loading = false;
      this.loadingSlots = false;
    });
  }

  selectSlot($event) {
    let modalInstance = this.$uibModal.open({
      component: 'progressModal',
      size: 'sm',
      backdrop: 'static',
      keyboard : false,
      resolve: {
        progressModalTitle: () => 'Just a moment, we\'re securing <br />your appointment time.',
        holdSlot: () => true,
        campaignData: () => this.campaignData,
        slotStartTime: () => $event,
        schedule: () => this.currentSchedule,
        location: () => this.currentLocation,
      }
    });

    modalInstance.result.then((result) => {
      this.$state.go('campaignRegistration', {campaignId: this.$stateParams.campaignId, slotId: result.id, appointmentTypeId: this.$stateParams.appointmentTypeId});
    },(err) => {
      //modal cancelled
      this.$log.error(err);
      let slotToRemove = this.currentSchedule.nextAvailableSlots.indexOf($event)
      if (slotToRemove !== -1) {
        this.currentSchedule.nextAvailableSlots.splice(slotToRemove, 1);
        this.getSlots(true);
      }
      this.errorMessage = this.slotErrorMessage;
      this.showError = true;
    });
  }

  prevMonth() {
    this.pivotDate = moment(this.pivotDate).add(-1,'months').valueOf();
    this.selectWeek(moment(this.pivotDate).endOf('week').startOf('day').valueOf());
    this.getSlots(true);
  }

  nextMonth() {
    this.pivotDate = moment(this.pivotDate).add(1,'months').valueOf();
    this.selectWeek(moment(this.pivotDate).endOf('week').startOf('day').valueOf());
    this.getSlots(true);
  }

  prevWeek() {
    this.pivotDate = moment(this.pivotDate).add(-1,'weeks').valueOf();
    this.selectWeek(moment(this.pivotDate).endOf('week').startOf('day').valueOf());
    if (angular.isUndefined(this.calendar.slots[this.weekNumber])){
      this.getSlots(false);
    }
  }

  nextWeek() {
    this.pivotDate = moment(this.pivotDate).add(1,'weeks').valueOf();
    this.selectWeek(moment(this.pivotDate).endOf('week').startOf('day').valueOf());
    if (angular.isUndefined(this.calendar.slots[this.weekNumber])){
      this.getSlots(false);
    }
  }

  selectWeek($event) {
    this.weekNumber = moment($event).week();
    this.dateYear = moment($event).startOf('year').valueOf();
  }

  updateCalendarWithSlotTimes(findFirst){
    this.calendar = {slots:[]};
    this.calendar.lastUpdatedTime = moment().valueOf();
    if(findFirst){
      this.weekNumber = moment(this.findFirstSlot()).week();
    }
    this.allSlots.forEach((slotTime) => {
      if (angular.isUndefined(this.calendar.slots[moment(slotTime).week()])) {
        this.calendar.slots[moment(slotTime).week()] = [[], [], [], [], [], [], []];
      }
      this.calendar.slots[moment(slotTime).week()][moment(slotTime).weekday()].push(slotTime);
    });
  }

  findFirstSlot(){
    let firstOfMonth = moment(this.pivotDate).startOf('month').valueOf();
    if(this.allSlots.length === 0) return firstOfMonth;
    let earliest = moment(this.pivotDate).endOf('month').valueOf();
    let now = moment().valueOf();
    this.allSlots.forEach((slotTime) => {
      if(slotTime>now && slotTime<earliest){
        earliest=slotTime;
      }
    });
    return earliest;
  }

  changeLocation() {
    let modalInstance = this.$uibModal.open({
      component: 'campaignLocationsModal',
      size: 'md',
      resolve: {
        'name' : () => this.campaignData.campaign.name,
        'locations': () => this.campaignData.locations,
        'imageUrl' : () => this.campaignData.imageUrl,
        'hours' : () => this.campaignData.departmentHours
      }
    });

    modalInstance.result.then((result) => {
      if (angular.isDefined(result)) {
        this.$state.go('selfSchedulingDetails', {locationId: result, appointmentTypeId:this.appointmentTypeId});
      }
    });
  }

}

export default SelfSchedulingDetailsController;

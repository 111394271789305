import angular from 'angular';
import component from './changePassword.component';


const changePassword = angular
  .module('connect.Account.ChangePassword', [])
  .component('changePassword', component)
  .name;

export default changePassword;

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './waitingRoom.component';

import myWaitingRoom from './myWaitingRoom/myWaitingRoom';

const findWaitingroom = angular
  .module('connect.waitingRoom', [uiRouter, myWaitingRoom])
  .component('findWaitingRoom', component)
  .config(['$stateProvider', $stateProvider => {
    'ngInject';

    $stateProvider.state('findWaitingRoom', {
      url: '/waitingroom',
      allowUnlogged: true,
      onlyUnlogged: true,
      disableHeader: false,
      showInHeader: true,
      title: 'Find Your Waiting Room',
      template: '<find-waiting-room></find-waiting-room>',
    });
  }])
  .name;

export default findWaitingroom;

export default class documentsListController {
  constructor($log, $uibModal, documentsListService, $filter, $window, $scope, documentModalService, registerFormService) {
    'ngInject';
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.documentsListService = documentsListService;
    this.registerFormService = registerFormService;
    this.$filter = $filter;
    this.$window = $window;
    this.documentModalService = documentModalService;
    this.$scope = $scope;
  }

  $onInit() {
    this.checkIfValid();
    this.$scope.$on('modal.closing', (event, sentEvent) => {
      // the 'Route Changed' event was being fired from the back button 
      // but we only care about the sentEvent from confirm
      if (sentEvent !== 'Route Changed') {
        switch (sentEvent) {
          case 'backbtn':
            break;
          case 'prevent-back-button':
            event.preventDefault();
            event.stopPropagation(); // this is as far as I want the event to propagate
            break;
          default:
            break;
        }
      }
    });
  }

  $onChanges() {
    this.updateCompletedCount();
  }

  checkIfValid() {
    let isValid = true;

    angular.forEach(this.documents, doc => {
      if (doc.required && doc.status === 'INCOMPLETE') {
        isValid = false;
      }
    });
    this.updateCompletedCount();
    this.onDocumentsValid({$event: {validDocuments: isValid, documents: this.documents}});
  }

  updateCompletedCount(){
    this.completedDocuments = 0;
    this.documents.forEach(doc => {
      if(doc.status == 'COMPLETE'){
        ++this.completedDocuments
      }
    });

  }

  openDocumentModal(index) {
    this.onDocumentOpen({
      $event: {}
    });
    let modalInstance = this.$uibModal.open({
      component: 'documentModal',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        doc: () => this.documents[index],
        patient: () => this.patient,
        readOnly: () => this.readOnly,
        allowPrint: () => this.allowPrint,
        appointmentId: () => this.appointmentId
      }
    });
    modalInstance.result.then(result => {
      if (result.save)
        // could pass obj here like {id: patientId from appt}
        this.onSaveDocuments({$event: {doc: result.doc, patientId: angular.isDefined(this.patient) ? this.patient.id : null}});
      this.documents[index].status = result.doc.status;
      this.documents[index].data = result.doc.data;
      this.checkIfValid();
    }, form => {
      if (angular.isDefined(form)) {
        // marked review check
        this.documents[index].status = form.status;
      }
    });
  }

  //TODO need to do this until we can get the forms in the context of an appointment
  checkStatus(status) {
    if (status === 'NEEDS_REVIEW' && this.hideReviewStatus)
      return 'COMPLETE';
    else
      return status;
  }

  print(document) {
    this.documentModalService.printForm(document.id, document.version).then(blob => {
      if (this.$window.navigator.msSaveOrOpenBlob) {
        this.$window.navigator.msSaveOrOpenBlob(blob);
      } else if (this.$window.URL.createObjectURL) {
        this.$window.open(this.$window.URL.createObjectURL(blob));
      }
    }, (e) => {
      this.flash.instantMessage("Error trying to print form: " + e , 'danger');
    });
  }

}

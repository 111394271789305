class MyDocumentsService {
  constructor($q, $filter, User, Organization, SessionManager, FormsDomain) {
    'ngInject';
    this.$q = $q;
    this.$filter = $filter;
    this.User = User;
    this.Organization = Organization;
    this.SessionManager = SessionManager;
    this.FormsDomain = FormsDomain;
  }

  getOrganizations(orgList) {
    return this.$q((resolve, reject) => {
      let r = [];
      let promises = orgList.map(
        (org) => this.Organization.getOrganization(org)
        .then(res => {
          r[res.data.id] = res.data.name;
        }));

      this.$q.all(promises).then(() =>  {
        resolve(r);
      }
      , reject);
    });
  }

  getHideMyDocuments() {
    return this.$q((resolve, reject) => {
      this.Organization.getOrgIds().then((orgIds) => {
        this.Organization.getOrgUnitAttributes(orgIds.orgId).then((response) => {
          resolve(angular.isDefined(response.data.attributesAsMap['CONNECT_HIDE_MY_DOCUMENTS']) ? 
            angular.fromJson(response.data.attributesAsMap['CONNECT_HIDE_MY_DOCUMENTS']) :
            false);
        }, error => reject(error));
      }, error => reject(error));
    });
  }

  parseForms(patientData) {
    return this.$q((resolve, reject) => {
      let listOfIds = [];
      if (angular.isDefined(patientData.patients)) {
        patientData.patients.forEach(patient => {
          listOfIds.push(patient.organizationId);
        });
        this.getOrganizations(listOfIds)
        .then((orgs) => {
          patientData.patients.forEach(pat => {
            pat.orgName = orgs[pat.organizationId];
          });
          resolve(patientData);
        }, reject);
      } else {
        reject('No Participant Data Found');
      }
    });
  }

  getForms(userId) {
    return this.$q((resolve, reject) => {
      this.FormsDomain.getUserDocuments(userId).then((resp) =>  {
        this.parseForms(resp.data.data)
          .then(e => resolve(e), reject);
      }, reject);
    });
  }

  saveForm(form) {

    let saveForm = {data: {id: form.id, version: form.version}, formModel: form.data};

    return this.FormsDomain.submitForm(saveForm);
  }
}

export default MyDocumentsService;

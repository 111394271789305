import template from './registerForm.template.html';
import controller from './registerForm.controller';

const component = {
  controller: controller,
  template: template,
  bindings: {
    orgId: '<',
    deptId: '<',
    isRegistered: '<',
    asRegistered: '<',
    verifyFields: '<',
    user: '<',
    patient: '<',
    formFields: '<',
    onFormValid: '&',
    onDocumentsValid: '&',
    enableForms: '<',
    documents: '<',
    isOther: '<',
    onCloseAlerts: '&',
    onGetConfig: '&',
    onSaveDocuments: '&',
    cohorts: '<',
    cohortName: '<',
    onCohortSelect: '&',
    selfScheduling: '<'
  }
};

export default component;

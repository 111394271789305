class GetInLineservice {
  constructor($window, $q, ConfigManager, AccessRegistration, GetInLine, Organization, SessionManager, ParticipantReg, WaitingRoom, FormsDomain, PatientsDomain) {
    'ngInject';
    this.$window = $window;
    this.$q = $q;
    this.AccessRegistration = AccessRegistration;
    this.GetInLine = GetInLine;
    this.Organization = Organization;
    this.SessionManager = SessionManager;
    this.ParticipantReg = ParticipantReg;
    this.WaitingRoom = WaitingRoom;
    this.ConfigManager = ConfigManager;
    this.FormsDomain = FormsDomain;
    this.PatientsDomain = PatientsDomain;
  }

  getCurrentOrg() {
    return this.$q((resolve, reject) => {
      this.Organization.getOrgIds().then(orgIds => {
        this.Organization.getOrganizationPublic(orgIds.orgId)
          .then(res => {
            if (res.data) {
              resolve(res.data);
            }
            else reject();
          }, reject);
      }, reject);
    });
  }

  getCurrentOrgUnit(org) {
    return this.$q((resolve, reject) => {
      this.Organization.getOrgIds().then(orgIds => {
        let dept = this.getOrganizationalUnit(org.childOrganizationalUnits, orgIds.departmentId);
        return resolve(dept);
      }, reject);
    });
  }

  getOrganizationalUnit(units, id) {
    let allUnits = this.getAllUnits(units, []);
    for(let i = 0; i < allUnits.length; i++) {
      if (allUnits[i].id === id) {
        return allUnits[i];
      }
    }
  }

  getAllUnits(units, allUnits){
    for(let i = 0; i < units.length; i++) {
      allUnits.push(units[i]);
      allUnits.concat(this.getAllUnits(units[i].childOrganizationalUnits, allUnits));
    }
    return allUnits;
  }

  getCurrentUser() {
    return this.SessionManager.getCurrentUser();
  }

  getFirstPatientIdForUser() {
    return this.SessionManager.getLinkedPatientIds()[0];
  }

  getWaitTime(org, dept) {
    return this.$q((resolve, reject) => {
      this.GetInLine.getWaitTime(org.id, dept.id)
        .then(res => {
          resolve(res.data);
        }, reject);
    });
  }

  getConfig(currentOrg, currentOrgUnit) {
    return this.$q((resolve, reject) => {
      let data = {};

      this.GetInLine.getInLineConfig(currentOrg.id, currentOrgUnit.id)
        .then(inlineRes => {
          data.Inline = inlineRes.data;
          data.Inline.schedule = data.Inline.schedule;

          this.WaitingRoom.getWaitingRoomInformation(currentOrgUnit.facilityId, data.Inline.id).then(waitingRoomRes => {
            data.WaitingRoom = waitingRoomRes.data;
            resolve(data);
          }, reject);
        }, reject);
    });
  }

  register(participant) {
    return this.$q((resolve, reject) => {
      this.getCurrentOrg()
        .then(org => {
          this.getCurrentOrgUnit(org)
            .then(orgUnit => {
              participant.organizationId = org.id;
              let patient = angular.extend({}, participant, {
                password: undefined,
                gender: undefined
              });
              this.ParticipantReg.CreateParticipant(participant.password, orgUnit.id, patient, participant.wantsSMS, participant.wantsEmails, false, [], participant.wantsVoice)
                .then((part) => {resolve(part);}, reject);
            }, reject);
        }, reject);
    });
  }

  getInLine(patient, org) {
    return this.$q((resolve, reject) => {
      this.getCurrentOrgUnit(org).then(orgUnit => {
        this.GetInLine.getInLine(patient, orgUnit.id)
          .then(resolve, reject);
      }, reject);
    });
  }

  getCoordinatesByAddress(address) {
    return this.$q((resolve, reject) => {
      let geocoder = new this.$window.google.maps.Geocoder();
      if (geocoder) {
        geocoder.geocode({'address': `${address.streetLine1}, ${address.city}, ${address.state}`}, (results, status) => {
          if (status == this.$window.google.maps.GeocoderStatus.OK) {
            const responseData = {
              center: [results[0].geometry.location.lat(), results[0].geometry.location.lng()],
              marker: [results[0].geometry.location.lat(), results[0].geometry.location.lng()],
              ref: results[0].geometry.location.lat() + ',' + results[0].geometry.location.lng(),
              zoom: 18
            };
            resolve(responseData);
          }
          else {
            reject(status);
          }
        });
      }
    });
  }

  getDocuments(appointmentTypeId, patientId) {
    let forms = [];
    if (appointmentTypeId && patientId) {
      return this.$q((resolve, reject) => {
        this.PatientsDomain.getPatientDocuments(patientId, appointmentTypeId, "Detail").then(response => {
          if(angular.isDefined(response.data.data.documents))
          {
            response.data.data.documents.forEach(f => {
              forms.push(f);
            });
          }
          else
          {
            forms = response.data.data.forms;
          }
          resolve(forms);
        }, error => reject(error));
      });
    } else {
      return this.$q((resolve, reject) => {
        if(appointmentTypeId)
        {
          this.FormsDomain.getFormsByAppointmentType(appointmentTypeId).then(response => {
            forms = response.data.data;
            resolve(forms.map(form => {
              //TODO: there is something weird here which is requiring me to set fields here.
              //    But leave that rabbit hole for another time
              return {
                formDefinition: form,
                fields: form.fields,
                formDefinitionId: form.id,
                name: form.name,
                id: null,
                data: [],
                required: !!form.required,
                status: 'INCOMPLETE'
              }
            }));
          }, error => resolve([]));
        } else { resolve([]); }
      });
    }
  }

  getPatientDocumentsMap(patientId, appointmentTypeId) {
    return this.PatientsDomain.getPatientDocuments(patientId, appointmentTypeId, 'Detail').then((response) => {
      let allDocs = response.data.data.documents;
      let docMap = {};
      allDocs.map(f => {
        docMap[f.formDefinition.id] = f
      });
      return docMap;
    });
  }

  saveDocument(form, apptId) {
    let saveForm = {data: {id: form.id, version: form.version}, formModel: form.data};
    return this.FormsDomain.submitFormForAppointment(saveForm, apptId, false);
  }
}

export default GetInLineservice;

class PatientIntakeProgress {
  constructor () {
    'ngInject';

  }

  $onChanges() {
    this.diameter = this.radius*2;
    this.normalizedRadius = this.radius - this.stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
    this.strokeDashoffset = this.circumference - this.progress / 100 * this.circumference;
  }

}
export default PatientIntakeProgress;

class ThreadReplyController {
  constructor(Appointment, flash) {
    'ngInject'
    this.Appointment = Appointment;
    this.flash = flash;
  }

  showReplyBox(oldVal, reply) {
    reply.showReply = !oldVal;
  }

  replyToThread(reply, thread) {
    let newReply = {
      "from":  {
        "id": this.user.id,
        "name": this.user.name
      },
      "body": {"text": reply.replyText},
      "timestamp": new Date().getTime(),
      "read": false,
      "replies": []
    };

    reply.replies.push(newReply);

    this.Appointment.getAppointment(this.appointment.id).then(res => {
      let appt = res.data
      appt.conversations = this.conversations;
      this.Appointment.updateAppointment(appt).then(res => {
        this.flash.showToast('Your reply has been sent', 'success');
        reply.replyText = '';
        reply.showReply = false;

        this.conversations.forEach(thread => {
          thread.unreadCount = this.getUnreads(thread.replies);
          thread.replyCount = this.getReplyCount(thread.replies);
        });
      })
    })
  }


  markAsRead(reply) {
    reply.read = true;
    reply.readDetail = {
      "userId": this.user.id,
      "timestamp": new Date().getTime()
    };
    this.onReadEvent(reply);
  }

  onReadEvent($event) {
    this.onRead({$event: $event});
  }

  getUnreads(replies) {
    let count = 0;
    replies.forEach(r => {
      if (r.from.id !== this.user.id) {
        if(!r.read) ++count;
      }
      if(r.replies.length > 0){
        count += this.getUnreads(r.replies);
      }
    });
    return count;
  }

  getReplyCount(replies) {
    let count = 0;
    replies.forEach(r => {
      ++count;
      if(r.replies.length > 0){
        count += this.getReplyCount(r.replies);
      }
    });
    return count;
  }
}

export default ThreadReplyController;
import uiRouter from 'angular-ui-router';
import component from './payment.component';
import collectionModal from './cardCollectionModal/cardCollectionModal.component';
import authModal from './authModal/authModal.component';

const dependencies = [
  uiRouter
];

const payment = angular
  .module('connect.payment', dependencies)
  .component('payment', component)
  .component('cardCollectionModal', collectionModal)
  .component('authModal', authModal)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('payment', {
        url: '/payment?{id}',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Payment',
        template: '<payment></payment>',
      })
      .state('/', {
        url: '/',
        disableHeader: true,
        disableTimer: true
      });
  })
  .name;

export default payment;

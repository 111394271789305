import angular from 'angular';
import component from './survey.component';
import service from './surveyPage.service';
import multipleChoice from './multipleChoice/multipleChoice.component';
import stringAnswer from './stringAnswer/stringAnswer.component';
import rating from './rating/rating.component';

const dependencies = [
    'ui.router.compat',
    'commonLibrary'
];

const survey = angular
    .module('connect.survey', dependencies)
    .service('SurveyPageService', service)
    .component('survey', component)
    .component('multipleChoice', multipleChoice)
    .component('rating', rating)
    .component('stringAnswer', stringAnswer)
    .config(($stateProvider) => {
        'ngInject';
        $stateProvider.state('survey', {
            allowUnlogged: true,
            url: '/survey/:surveyId/:organizationId/:appointmentId',
            template: '<survey></survey>'
        });
    })
    .name;

export default survey;

class SearchBarService {
  constructor(){
    'ngInject';
    this.searchData = {};
  }

  $onInit(){
  }

  getSearchData(){
    return this.searchData;
  }

  setSearchData(searchData){
    this.searchData = searchData;
  }

}

export default SearchBarService;
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import signUpComponent from './signUp.component';
import registerForm from '../shared/registerForm/registerForm';

const signUp = angular
  .module('connect.SignUp', [uiRouter, registerForm, 'commonFeatures'])
  .component('signUp', signUpComponent)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('signUp', {
        url: '/signup',
        allowUnlogged: true,
        onlyUnlogged: true,
        template: '<sign-up></sign-up>',
        params: {savedState: null, savedParams: null}
      });
  }])
  .name;

export default signUp;

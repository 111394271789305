import uiRouter from 'angular-ui-router';
import component from './payment.component';
import collectionModal from './cardCollectionModal/cardCollectionModal.component';
import authModal from './authModal/authModal.component';
import progress from '../patientIntakeProgress/patientIntakeProgress.component';

const dependencies = [
  uiRouter
];

const paymentPatientIntake = angular
  .module('connect.payment.intake', dependencies)
  .component('paymentPatientIntake', component)
  .component('cardCollectionModalPatientIntake', collectionModal)
  .component('authModalPatientIntake', authModal)
  .component('patientIntakeProgress', progress)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('paymentPatientIntake', {
        url: '/payment-patient-intake?{token}',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Payment',
        template: '<payment-patient-intake></payment-patient-intake>',
      });
  })
  .name;

export default paymentPatientIntake;

import template from './searchBarModal.template.html';
import controller from './searchBarModal.controller';

const component = {
  controller,
  template,
  bindings: {
    modalInstance: '<',
    resolve: '<',
    onSearch: '&'
  }
};

export default component;

class ConfrimationController {
  constructor() {
    'ngInject';

  }

  $onChanges(changes) {
    if (angular.isDefined(changes.config.currentValue)) {
      let useFacilityAddress = this.config.WaitingRoom.mobileWaitingRoomConfig.useFacilityAddress;

      if (useFacilityAddress) {
        this.displayAddress = this.department.address;
      } else {
        this.displayAddress = this.org.address;
      }
    }
  }


}

export default ConfrimationController;

import moment from "moment";
export default class hourRange {
  constructor() {
    'ngInject';
  }

  $onInit() {

    if (angular.isDefined(this.daysSlots) && this.daysSlots.length > 0) {
      if ((angular.isDefined(this.daysSlots[0].dailyStartTime) && angular.isDefined(this.daysSlots[0].dailyEndTime)) &&
        (!moment.isMoment(this.daysSlots[0]) || !moment.isMoment(this.daysSlots[0]))) {
        this.daysSlots[0].dailyStartTime = moment(this.daysSlots[0].dailyStartTime, 'HH:mm A');
        this.daysSlots[0].dailyEndTime = moment(this.daysSlots[0].dailyEndTime, 'HH:mm A');
      }
    }
  }

  $onChanges(changes) {
    if (angular.isDefined(changes.daySlots.currentValue)) {
      this.daysSlots = changes.daySlots.currentValue;
    }
  }
}

export default class documentModalController {
  constructor($log, $filter, $window, $scope, documentModalService, SessionManager, flash, AppointmentsDomain) {
    'ngInject';
    this.$log = $log;
    this.$filter = $filter;
    this.$window = $window;
    this.documentModalService = documentModalService;
    this.SessionManager = SessionManager;
    this.flash = flash;
    this.$scope = $scope;
    this.appointmentsDomain = AppointmentsDomain;
  }

  $onInit() {
    this.form = this.resolve.doc;
    this.form.name = angular.copy(this.resolve.doc.name);
    this.form.fields = this.resolve.doc.fields;
    if(angular.isUndefined(this.form.data)) this.form.data = {};

    if (angular.isDefined(this.form.fields) && this.form.fields.length != Object.keys(this.form.data).length) {
      for (let i = 0; i < this.form.fields.length; ++i) {
        if(angular.isUndefined(this.form.data[i])) this.form.data[i] = null;
      }
    }

    if (angular.isDefined(this.form.id) && this.form.id != null) {
      this.loading = true;
      this.documentModalService.getFormData(this.form.id, this.form.version).then((formDataResponse) => {
        if( formDataResponse.status == 200 ){
          this.form.fields = formDataResponse.data.data.formDefinition.fields;
          this.form.fields.forEach(field => { delete field.key})
          this.form.data = formDataResponse.data.formModel;
          if (this.form.status === 'NEEDS_REVIEW') {
            this.needsReview = true;
            this.setFieldsDisabled(true);
          } else if (this.form.status === 'COMPLETE') {
            this.update = true;
            this.setFieldsDisabled(true);
          }
        }
        this.loading = false;
      })
    }

    // Had to hit back button twice to leave modal. This removes the second press need to make sure doesn't break anything
    // this
    // .$window.history.back();
    // DEBT: Commented out to remove untested functionality of disabling editing of forms
    // if(angular.isDefined(this.resolve.readOnly)) {
    //   this.readOnly = angular.copy(this.resolve.readOnly);
    // }
    // if(this.readOnly) this.setFieldsDisabled(true);
    // else if (angular.isDefined(this.readOnly) && this.doc.form.status === 'COMPLETE') {
    //   this.setFieldsDisabled(true);
    //   this.needsReview = true;
    // }

    this.$scope.$on('$locationChangeStart', (event) => {
      if (this.modalForm.$dirty) {
        let answer = confirm("You have unsaved changes. \n\n Are you sure that you want to leave the document?");
        if (!answer) {
          this.$scope.$emit('modal.closing', 'prevent-back-button');
          event.preventDefault(); // stops the navigation
        } else {
          this.$scope.$emit('modal.closing', 'backbtn');
        }
        // the $emit events get sent to the parent to control closing the modal
      }
    });

  }

  setFieldsDisabled(option) {
    angular.forEach(this.form.fields, field => {
      field.templateOptions.disabled = option;
      field.expressionProperties =  {
        'templateOptions.disabled': `${option}`
      };
    });
  }

  markAsReviewed() {
    this.appointmentsDomain.markAppointmentFormReviewed(this.form.id, this.resolve.appointmentId).then((resp) => {
      this.modalInstance.close({doc: resp.data.data,
      save: false});
    });
  }
  
  updateForm() {
    this.setFieldsDisabled(false);
    this.needsReview = false;
    this.update = false;
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  checkData(formFieldData) {
    let values = [];
    for (const prop in formFieldData) {
      if (formFieldData.hasOwnProperty(prop)) {
        values.push(formFieldData[prop])
      }
    }
    return values.some(i => i !== null);
  }

  save() {
      let result = {doc: this.form, save: true, $dirty: this.modalForm.$dirty || this.form.status !== 'COMPLETE'};
      if (this.form.fields.some(i => (angular.isDefined(i.formControl) && i.formControl.$valid === false))) {
        angular.forEach(this.form.fields, field => {
          if (angular.isUndefined(field.validation)) field.validation = {};
          field.validation.show = true;
        });
        this.flash.showToast('Please fill out the highlighted fields.', 'danger');
        if (angular.isDefined(document.querySelectorAll('input.ng-invalid, form-group has-error'))) {
          document.querySelectorAll('input.ng-invalid, form-group has-error')[0].focus();
        }
      }
      else {
        if(this.modalForm.$dirty || this.checkData(this.form.data) === false){
          this.flash.showToast('Form Saved Successfully!', 'info');
          this.form.status = 'COMPLETE';
        }
        this.modalInstance.close(result);
      }
  }

  print() {
    this.documentModalService.printForm(this.form.id, this.form.version).then(blob => {
      if (this.$window.navigator.msSaveOrOpenBlob) {
        this.$window.navigator.msSaveOrOpenBlob(blob);
      } else if (this.$window.URL.createObjectURL) {
        this.$window.open(this.$window.URL.createObjectURL(blob));
      }
    }, (e) => {
      this.flash.instantMessage("Error trying to print form: " + e , 'danger');
    });
  }
}

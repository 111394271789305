import template from './timeoutModal.template.html';
import controller from './timeoutModal.controller.js';

const component = {
  template: template,
  controller : controller,
  bindings : {
    modalInstance: '<',
    resolve: '<',
    close: '&'
  }
};

export default component;

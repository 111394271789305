import template from './claimPatientForm.template.html';
import controller from './claimPatientForm.controller';

const component = {
  controller,
  template,
  bindings: {
    formData: '<',
    submitForm: '&',
    signinUser: '&',
    passwordReset: '&',
    register: '&',
    navigateToLogin: '&',
    isLoggedIn: '<',
    response: '<',
    notFound: '<',
    processing: '<',
    participant: '<',
    message: '<'
  }
};

export default component;

class DeletAccountController { 
  constructor() {
    'ngInject';
  }

  deleteAccount() {
    this.onAccountDelete();
  }
}

export default DeletAccountController;
import moment from 'moment-timezone';

export default class progressModal {
  constructor($q, $timeout, $log, selfSchedulingService, Schedule, Organization, Appointment, SessionManager, FormsDomain) {
    'ngInject';
    this.$q = $q;
    this.$timeout = $timeout;
    this.selfSchedulingService = selfSchedulingService;
    this.Schedule = Schedule;
    this.Organization = Organization;
    this.Appointment = Appointment;
    this.$log = $log;
    this.SessionManager = SessionManager;
    this.FormsDomain = FormsDomain;
  }

  $onInit() {
    this.processing = true;


    this.timeOutText = 'This can take a few seconds...';
    this.$timeout(() => {
      this.timeOutText = 'This is taking longer than expected, hang in there.';
    }, 10000);

    //If holding a slot
    if (this.resolve.holdSlot) {
      let locationTimezone = this.resolve.location.timezone ? this.resolve.location.timezone : Intl.DateTimeFormat().resolvedOptions().timeZone;
      let slotTime = moment(this.resolve.slotStartTime).tz(locationTimezone).format('HH:mm').valueOf();
      let slotDate = moment(this.resolve.slotStartTime).tz(locationTimezone).format('YYYY-MM-DD').valueOf();
      this.selfSchedulingService.createSlotHold(
        this.resolve.schedule.id,
        this.resolve.location,
        slotTime,
        slotDate,
        this.resolve.schedule.slotDuration).then((response) => {
          this.slotHoldId = response.data.id;
          this.selfSchedulingService.getSlotHold(this.slotHoldId).then((response) => {
            this.processing = false;
            this.close({$value: response.data});
          });
      }, (error) => {
        this.processing = false;
        this.modalInstance.dismiss(error);
      });
      //Else if scheduling an appointment
    } else if (this.resolve.scheduleAppt) {
      this.orgId = this.resolve.campaignData.campaign.organizationId;
      this.orgUnitId = this.resolve.slotData.slot.slotTimeDuration.location.organizationalUnitId;

      if (angular.isDefined(this.resolve.unsavedDocuments) && this.resolve.unsavedDocuments.length > 0) {
        let promiseArray = [];
        this.selfSchedulingService.getPatientDocumentsIdMap( this.resolve.patientData.id ,this.resolve.appointmentTypeId, moment(this.resolve.slotStartTime).format().valueOf()).then(documentMap => {
          angular.forEach(this.resolve.unsavedDocuments, doc => {
            if (doc.status === 'COMPLETE') {
              if(angular.isDefined(documentMap[doc.formDefinitionId])){
                doc.id = documentMap[doc.formDefinitionId].id;
                doc.version = documentMap[doc.formDefinitionId].version;
              }
              promiseArray.push(this.selfSchedulingService.saveDocument(doc));
            }
          });
          this.$q.all(promiseArray).then(() => {
            this.schedule();
          }, error => {
            this.$log.error(error);
            this.modalInstance.dismiss(this.errorResponseText);
          });
        }, (err) => {
          this.$log.error(err);
          this.schedule();
        });
      } else {
        this.schedule();
      }
    }
  }

  schedule() {
    //format the appointment time using the slot data
    let timeString = this.resolve.slotData.slot.slotTimeDuration.day + ' ' + this.resolve.slotData.slot.slotTimeDuration.time;
    let apptTime;
    if(this.resolve.slotData.slot.slotTimeDuration.location.timezone)
    {
      apptTime = moment.tz(timeString,this.resolve.slotData.slot.slotTimeDuration.location.timezone).format('x');
    } else
    {
      apptTime = moment(timeString).format('x');
    }
    //get all the extra info that's needed to schedule an appointment
    this.errorResponseText = this.resolve.registrationConfigs.errorResponseText;
    this.slotHoldError = this.resolve.registrationConfigs.slotHoldError;

    this.Schedule.createScheduleAppointment(this.resolve.slotData.slot.scheduleId, {
      appointmentTypeId: this.resolve.appointmentTypeId,
      expectedDuractionInMinutes: this.resolve.slotData.slot.slotTimeDuration.duration,
      patientId: this.resolve.patientData.id,
      pending: this.resolve.registrationConfigs.set_self_scheduled_to_pending,
      isFailed: this.resolve.setAppToFailed,
      organizationId: this.orgId,
      organizationalUnitId: this.orgUnitId,
      dateTime: apptTime,
      selfScheduled: true,
      apptCreationMethod: "SELF_SCHEDULE",
      resources: [{
        id: this.resolve.slotData.slot.slotTimeDuration.location.id,
        status: 'REQUIRED',
        type: 'LOCATION'
      }, {
        id: this.resolve.campaignData.campaign.providerId,
        status: 'REQUIRED',
        type: 'USER'
      }]
    }).then((apptResponse) => {
      // create mirth appointment
      // if successful then close and route
      // else show error and cancel appointment
      if (angular.isDefined(this.resolve.registrationConfigs.mirthUrl)) {
        if ((angular.isDefined(this.resolve.registrationConfigs.provider_id) && this.resolve.registrationConfigs.provider_id_required) || !this.resolve.registrationConfigs.provider_id_required) {
          this.selfSchedulingService.createMirthAppointment(this.resolve.registrationConfigs.mirthUrl, {
            slotData: this.resolve.slotData,
            patientData: this.resolve.patientData,
            provider_id: this.resolve.registrationConfigs.provider_id,
            userData: this.resolve.userData,
            apptTime: this.apptTime,
            orgUnit: this.resolve.registrationConfigs.orgUnit,
            appointmentData: apptResponse.data,
            documents: this.resolve.allDocuments,
            version: 1,
            eventType: 'SELF_SCHEDULE'
          }).then((response) => {
            if (response.status === 204) {
              //this is likely a connection issue. Mirth didn't get the appointment data
              this.processing = false;
              this.cancelApptAndReturnError(apptResponse.data.id);
            } else {
              //mirth successfully recieved the appointemnt data
              this.processing = false;
              this.close({$value: apptResponse});
            }
          }, () => {
            //this is probably a 500 error and means there is an issue with Mirth sending the data to Iatrics
            this.processing = false;
            this.cancelApptAndReturnError(apptResponse.data.id);
          });
        } else if (angular.isUndefined(this.resolve.registrationConfigs.provider_id) && this.resolve.registrationConfigs.provider_id_required) {
          this.$log.warn('The Provider ID is required but is not defined.');
          this.cancelApptAndReturnError(apptResponse.data.id);
        }
      } else {
        //The Mirth url didn't return so don't try to schedule with mirth
        //close the modal and reroute
        this.processing = false;
        this.close({$value: apptResponse});
      }
    }, (error) => {
      this.$log.error(error);
      this.modalInstance.dismiss(this.slotHoldError);
    });
  }

  cancelApptAndReturnError(appointmentId) {
    this.Appointment.cancelAppointment(appointmentId).then(() => {
      this.modalInstance.dismiss(this.errorResponseText);
    }, (error) => {
      this.$log.error(error);
      this.modalInstance.dismiss(this.errorResponseText)
    });
  }

  cancel() {
    this.modalInstance.dismiss('Hold Cancelled');
  }
}

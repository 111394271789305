export default class SearchBarModal {
  constructor(){
    'ngInject';
  }

  $onInit(){
  }

  search() {
    this.modalInstance.close();
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}

import template from './slotsWeekCalendar.template.html';
import controller from './slotsWeekCalendar.controller';

const component = {
  controller,
  template,
  bindings: {
    calendar: '<',
    weekNumber: '<',
    dateYear: '<',
    loading: '<',
    timezone: '<',
    onSlotSelection: '&'
  }
};

export default component;
import controller from './timer.controller';

let component = {
  controller: controller,
  bindings : {
    slotData : '<',
    //Function to execute when time is out
    onExpiring : '&',
    onTimeout : '&'
  }
};

export default component;

class ThreadModalController {
  constructor($scope, Appointment, flash) {
    'ngInject'
    this.Appointment = Appointment;
    this.flash = flash;
  }


  replyToThread(reply, thread) {
    let newReply = {
      "from":  {
        "id": this.resolve.user.id,
        "name": this.resolve.user.name
      },
      "body": {"text": reply.replyText},
      "timestamp": new Date().getTime(),
      "read": false,
      "replies": []
    };

    thread.replies.push(newReply);

    this.markAsRead(reply);
  }

  saveData(reply) {
    this.Appointment.getAppointment(this.resolve.appointment.id).then(res => {
      let appt = res.data
      appt.conversations = this.resolve.conversations;
      this.Appointment.updateAppointment(appt).then(res => {
        this.flash.showToast('Your reply has been sent', 'success');
        reply.replyText = '';
        reply.showReply = false;
      });
    })
  }

  markAsRead($event) {
    this.saveData($event);

    this.resolve.conversation.unreadCount = this.getUnreads(this.resolve.conversation.replies);
    this.resolve.conversation.replyCount = this.getReplyCount(this.resolve.conversation.replies);
  }

  getUnreads(replies) {
    let count = 0;
    replies.forEach(r => {
      if (r.from.id !== this.resolve.user.id) {
        if(!r.read) ++count;
      }
      if(r.replies.length > 0){
        count += this.getUnreads(r.replies);
      }
    });
    return count;
  }

  getReplyCount(replies) {
    let count = 0;
    replies.forEach(r => {
      ++count;
      if(r.replies.length > 0){
        count += this.getReplyCount(r.replies);
      }
    });
    return count;
  }

  cancel() {
    this.Appointment.getAppointment(this.resolve.appointment.id).then(res => {
      let appt = res.data
      appt.conversations = this.resolve.conversations;
      this.Appointment.updateAppointment(appt).then(res => {
        this.modalInstance.dismiss(appt.conversations);
      }, err => {
        this.modalInstance.dismiss(appt.conversations);
      })
    })
  }

  showReplyBox(oldVal, reply) {
    reply.showReply = !oldVal;
  }

}

export default ThreadModalController;
class VideoVisitController {
  constructor($stateParams, $location, $log, $uibModal, AppointmentsDomain, Access) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.$location = $location;
    this.$log = $log;
    this.$uibModal = $uibModal;
    this.$log = $log;
    this.Access = Access;
  }

  $onInit() {
    this.appointmentId = this.$stateParams.appointmentId;
    this.token = this.$location.search().t;
    this.ready = false;
    this.Access.getAttributesByAppointmentId(this.appointmentId).then(res => {
      this.departmentAtts = res.data.departmentAttributesMap;
      // casting string to boolean

      if (angular.isDefined(this.departmentAtts['VIDEO_VISIT_SURVEY_TEMPLATE'])) {
        // casting string to boolean
        this.hasSurvey = !!this.departmentAtts['VIDEO_VISIT_SURVEY_TEMPLATE'];
      } else {
        this.hasSurvey = false;
      }

      this.openVideoPreviewModal();
    });
  }

  openVideoPreviewModal() {
    let modalInstance = this.$uibModal.open({
      component: 'video-preview',
      backdrop  : 'static',
      keyboard  : false,
      resolve: {}
    });

    modalInstance.result.then((result) => {
      this.ready = true;
      this.$log.info("TRACKS", result.tracks);
      this.tracks = result.tracks;
      // make call here
      this.Access.processVideoPatient(this.appointmentId).then(res => {
        // console.log(res.data);
      }, (err) => {
        this.$log.error("Error:", err);
      });
    }, () => {
      //modal cancelled
    });
  }
}

export default VideoVisitController;

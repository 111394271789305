import moment from 'moment-timezone';

class MyEventsController {
  constructor($log, myEventsService, SessionManager, flash, $state, UsersDomain) {
    'ngInject';
    this.$log = $log;
    this.myEventsService = myEventsService;
    this.$state = $state;
    this.flash = flash;
    this.SessionManager = SessionManager;
    this.UsersDomain = UsersDomain;
  }

  $onInit() {
    this.events = [];
    this.unitAttributes = [];
    this.currentUser = this.SessionManager.getCurrentUser();
    this.getUserEvents();
    this.filterBy = {type: ''};
    this.includePastEvents = false;

    this.myEventsService.allowSelfScheduling().then((response) => {
      this.enableSelfScheduling = response['ORGANIZATION_SELF_SCHEDULING_ENABLED'] === 'true' ? true : false;
      this.SelfScheduleButtonText = response['SELF_SCHEDULING_NEW_APPT_BUTTON_TEXT'] ? response['SELF_SCHEDULING_NEW_APPT_BUTTON_TEXT'] : 'SCHEDULE AN APPOINTMENT';
    }, error => this.$log.error(error));
  }

  getFormattedTime(time, location) {
    if (angular.isDefined(location) && angular.isDefined(location.timezone)) {
      return moment(time).tz(location.timezone).format('h:mm A z');
    }
    return moment.tz(time, moment.tz.guess()).format('h:mm A z');
  }

  getUserEvents() {
    this.loading = true;
    this.loadingClass = 'blink';
    this.UsersDomain.getUserAppointments(this.currentUser.id).then((response) => {
      this.patients = response.data.data.patients;

      // Count the number of completed documents
      this.completedDocuments = this.patients.reduce((accumulator, currentPatient) => {
        if (angular.isDefined(currentPatient.appointments) && angular.isArray(currentPatient.appointments)) {
          return {
            ...accumulator, ...currentPatient.appointments.reduce((accumulator, currentAppointment) => {
              if (angular.isDefined(currentAppointment.forms) && angular.isArray(currentAppointment.forms)) {
                accumulator[currentAppointment.id] = currentAppointment.forms.reduce((accumulator, currentForm) => {
                  return accumulator + (currentForm.status === "COMPLETE" ? 1 : 0);
                }, 0);
              }
              return accumulator;
            }, {})
          }
        }
        return accumulator;
        }, {});


      // Fetch all departmentIds from appointments
      let unitIds = this.patients.reduce((accumulator, currentValue)=>{
        return accumulator.concat(currentValue.appointments.reduce((accumulator, currentValue)=>{
          return accumulator.concat(currentValue.departmentId);
        },[]))
      }, []);
      this.myEventsService.getDepartmentAttributes(unitIds).then((res) => {
        this.unitAttributes = res.data;
      }, err => this.flash.instantMessage(err.data, 'danger'));
      this.loading = false;
      this.loadingClass = '';
    }, err => this.flash.instantMessage(err.data, 'danger'));
  }

  dateStringOrder(appointment){
    return moment.tz(appointment.start,moment.tz.guess()).valueOf();
  }

  goToDetails(event, sectionId) {
    if (!event.series) {
      this.$state.go('myEventDetails', {
        eventId: event.id,
        appointmentId: event.id,
        section: sectionId
      });
    } else {
      this.$state.go('myEventDetails', {
        eventId: event.id,
        section: sectionId
      });
    }
  }

  getTooltipMessage(event) {
    return this.myEventsService.getTooltipMessage(event);
  }

  checkIn(event) {
    let enabled = this.myEventsService.enableCheckInButton(event, this.unitAttributes);
    if (enabled) {
      this.myEventsService.checkIn(event).then((result) => {
        this.events.forEach((event) => {
          if (event.appointmentId === result.data.id) {
            event.appointments[0] = result.data;
          }
        });
        this.flash.instantMessage('You have successfully checked in for your appointment', 'success');
        this.getUserEvents();
      }, () => {
        this.flash.instantMessage('There was a problem checking you in for your appointment', 'danger');
      });
    }
  }

  allowRemoteCheckIn(appointment) {
    let allowed = this.myEventsService.allowRemoteCheckIn(appointment, this.unitAttributes);
    return allowed;
  }

  enableCheckInButton(appointment) {
    let enabled = this.myEventsService.enableCheckInButton(appointment, this.unitAttributes);
    if (enabled) {
      this.checkInButtonText = 'Check In For This Appointment';
    } else {
      this.checkInButtonText = '<i class="material-icons text-success">check</i> You\'re Checked In';
    }
    return enabled;
  }

  allowRemoveCancelButton(appointment){
      let allowed = this.myEventsService.allowRemoveCancelButton(appointment, this.unitAttributes);
      return allowed;
  }


}

export default MyEventsController;

import angular from 'angular';
import component from './campaign.component';
import campaignLocationsModal from '../../shared/campaignLocationsModal/campaignLocationsModal.component';

const campaign = angular
  .module('connect.SelfScheduling.Campaign', [])
  .component('campaign', component)
  .component('campaignLocationsModal', campaignLocationsModal)
  .name;

export default campaign;
import { isSupported, connect, createLocalTracks, createLocalVideoTrack } from 'twilio-video';

class JfhPatientVideoController {
  constructor($document, $timeout, Access, $log, $state, SessionManager) {
    'ngInject';
    this.$document = $document;
    this.$timeout = $timeout;
    this.Access = Access;
    this.$log = $log;
    this.$state = $state;
    this.SessionManager = SessionManager;
  }

  $onInit() {
    this.supported = true;
    this.notAllowed = false;
    this.maxParticipants = false;
    this.localTracks = [];

    this.mediaErrors = [
      'NotAllowedError',
      'NotFoundError',
      'NotReadableError',
      'OverconstrainedError',
      'TypeError'
    ];

    if (isSupported) {
      // Set up your video app.
      this.isMobile = this.checkIfMobile();
      this.startRoom();
    } else {
      this.$log.error('This browser is not supported by twilio-video.js.');
      this.supported = false;
    }

    // Listen to the "beforeunload" event on window to leave the Room
    // when the tab/browser is being closed.
    // eslint-disable-next-line
    window.addEventListener('beforeunload', () => this.room.disconnect());

    // iOS Safari does not emit the "beforeunload" event on window.
    // Use "pagehide" instead.
    // eslint-disable-next-line
    window.addEventListener('pagehide', () => this.room.disconnect());
  }

  checkIfMobile() {
    if( navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i) || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i)) {
      return true;
    }
    else {
      return false;
    }
  }

  handleMediaError(error) {
    this.$log.error('Failed to acquire media:', error.name, error.message);
    if (error.name === 'NotAllowedError') {
      this.notAllowed = true;
    }
  }

  startRoom() {
    this.loaded = false;
    this.audio = true;
    this.video = true;
    this.facing = 'user';
    /*
     In mobile browsers, the camera can be reserved by only one local video track at any given time.
     If you want to display a camera preview, ensure you use the same video track for the preview and for publishing into the Room.
     */
    if (this.tracks) {
      this.$log.info("FEATURES Tracks", this.tracks);
      this.localTracks = this.tracks;

      return connect(this.token, {
        name: this.appointmentId,
        tracks: this.localTracks,
        maxAudioBitrate: 16000,
        maxVideoBitrate: 500000, // In mobile platforms keep it between 500000 and 2000000 bps per video track
        region: 'gll'
      }).then(room => { // success promise from connect functions
        this.cleanContainer('local-media');
        this.attachTrack('local-media', this.localTracks[1]);
        this.setupRoom(room);
      }, error => { // error promise from connect functions
        if (this.mediaErrors.includes(error.name)) {
          this.handleMediaError(error);
        }
        if ('code' in error) {
          if (error.code === 53105) {
            this.maxParticipants = true;
          }
          this.$log.error('Failed to join Room:', error.code, error.message);
        }
      });
    } else {
      createLocalTracks({audio: true, video: {width: 275, height: 150, facingMode: this.facing}}).then( localTracks => {
        this.localTracks = localTracks;
        this.cleanContainer('local-media');
        this.attachTrack('local-media', localTracks[1]);
        this.Access.createVideoRoom(this.appointmentId).then(() => {
          return connect(this.token, {
            name: this.appointmentId,
            tracks: this.localTracks,
            maxAudioBitrate: 16000,
            maxVideoBitrate: 500000, // In mobile platforms keep it between 500000 and 2000000 bps per video track
            region: 'gll'
          });
        }, (createVideoRoomError) => {
          this.$log.error('Connection Error:', createVideoRoomError);
          return connect(this.token, {
            name: this.appointmentId,
            tracks: this.localTracks,
            maxAudioBitrate: 16000,
            maxVideoBitrate: 500000, // In mobile platforms keep it between 500000 and 2000000 bps per video track
            region: 'gll'
          });
        }).then(room => { // success promise from connect functions
          this.setupRoom(room);
        }, error => { // error promise from connect functions
          if (this.mediaErrors.includes(error.name)) {
            this.handleMediaError(error);
          }
          if ('code' in error) {
            if (error.code === 53105) {
              this.maxParticipants = true;
            }
            this.$log.error('Failed to join Room:', error.code, error.message);
          }
        });
      });
    }

  }

  cleanContainer(id) {
    // eslint-disable-next-line
    let container = document.getElementById(id);
    while (container.firstChild) {
      container.removeChild(container.firstChild);
    }
  }

  attachTrack(id, track) {
    // eslint-disable-next-line
    let container = document.getElementById(id);
    container.appendChild(track.attach());
  }

  setupRoom(room) {
    this.room = room;
    this.loaded = true;
    this.cleanContainer('remote-media');
    this.restartVideo();

    // check if there are already participants when you connect
    room.participants.forEach(participant => {
      participant.tracks.forEach(publication => {
        if (publication.track) {
          this.attachTrack('remote-media', publication.track);
        }
      });
      participant.on('trackSubscribed', track => {
        this.attachTrack('remote-media', track);
      });
    });
    // set up events
    room.on('participantConnected', participant => {
      this.cleanContainer('remote-media');
      participant.tracks.forEach(publication => {
        if (publication.isSubscribed) {
          const track = publication.track;
          this.attachTrack('remote-media', track);
        }
      });
      participant.on('trackSubscribed', track => {
        this.attachTrack('remote-media', track);
      });
    });
    room.on('disconnected', room => {
      // Detach the local media elements
      room.localParticipant.tracks.forEach(publication => {
        publication.track.stop();
        const attachedElements = publication.track.detach();
        attachedElements.forEach(element => element.remove());
      });
      this.cleanContainer('remote-media');
      this.cleanContainer('local-media');
    });
    room.once('participantDisconnected', () => {
        this.$state.go('videoDone', {'appointmentId': this.appointmentId});
    });

    this.$timeout(() => {}, 0);
  }

  restartVideo() {
    this.room.localParticipant.tracks.forEach((track) => {
      if (track.kind === 'video'){
        track.track.restart();
      }
    });
  }

  toggleVideo() {
    this.room.localParticipant.tracks.forEach((track) => {
      if (track.kind === 'video'){
        if (track.track.isEnabled) {
          track.track.disable();
          this.video = false;
        } else {
          track.track.enable();
          this.video = true;
        }
      }
    });
  }

  toggleAudio() {
    this.room.localParticipant.tracks.forEach((track) => {
      if (track.kind === 'audio'){
        if (track.track.isEnabled) {
          track.track.disable();
          this.audio = false;
        }
        else {
          track.track.enable();
          this.audio = true;
        }
      }
    });
  }

  flipCamera($event) {
    this.facing = $event;
    const cameraTrack = this.localTracks.find(track => track.kind === 'video');
    cameraTrack.restart({facingMode: this.facing}).then(result => {
      console.log('☠️💀☠️💀☠️ accepted:: ', result);
    },result => {
      console.log('☠️💀☠️💀☠️ rejected:: ', result);
    });

  }

}

export default JfhPatientVideoController;

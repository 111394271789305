import template from './campaignSchedulePreview.template.html';
import controller from './campaignSchedulePreview.controller';

const component = {
  controller,
  template,
  bindings: {
    location: '<',
    appointmentTypeIds: '<',
    hours: '<',
    campaignId: '<',
    slots: '<',
    timezone: '<',
    onSlotSelection: '&',
    onViewSchedule: '&'
  }
};

export default component;

import moment from 'moment';

export default class hoursScheduleService {
  constructor($q) {
    'ngInject';
    this.$q = $q;
  }

  checkConfig(schedule) {
    let returnValue = false;

    if (angular.isDefined(schedule.sundayWeekly) && schedule.sundayWeekly !== null && schedule.sundayWeekly.daySlots.length) returnValue = true;
    if (angular.isDefined(schedule.mondayWeekly) && schedule.mondayWeekly !== null && schedule.mondayWeekly.daySlots.length) returnValue = true;
    if (angular.isDefined(schedule.tuesdayWeekly) && schedule.tuesdayWeekly !== null && schedule.tuesdayWeekly.daySlots.length) returnValue = true;
    if (angular.isDefined(schedule.wednesdayWeekly) && schedule.wednesdayWeekly !== null && schedule.wednesdayWeekly.daySlots.length) returnValue = true;
    if (angular.isDefined(schedule.thursdayWeekly) && schedule.thursdayWeekly !== null && schedule.thursdayWeekly.daySlots.length) returnValue = true;
    if (angular.isDefined(schedule.fridayWeekly) && schedule.fridayWeekly !== null && schedule.fridayWeekly.daySlots.length) returnValue = true;
    if (angular.isDefined(schedule.saturdayWeekly) && schedule.saturdayWeekly !== null && schedule.saturdayWeekly.daySlots.length) returnValue = true;

    return returnValue;
  }


  findDaySchedule(schedule, day) {
    // gets the correct day schedule with Sunday as 0 and Saturday as 6
    let today;

    if (angular.isDefined(day)) {
      today = day;
    } else {
      today = moment().day();
    }

    switch(today) {
    case 0:
      return schedule.sundayWeekly;
    case 1:
      return schedule.mondayWeekly;
    case 2:
      return schedule.tuesdayWeekly;
    case 3:
      return schedule.wednesdayWeekly;
    case 4:
      return schedule.thursdayWeekly;
    case 5:
      return schedule.fridayWeekly;
    case 6:
      return schedule.saturdayWeekly;
    default:
      return false;
    }
  }

  parseSchedule(schedule) {
    schedule.sundayWeekly = this.fixDayOfWeek(0, schedule.sundayWeekly);
    schedule.mondayWeekly = this.fixDayOfWeek(1, schedule.mondayWeekly);
    schedule.tuesdayWeekly = this.fixDayOfWeek(2, schedule.tuesdayWeekly);
    schedule.wednesdayWeekly = this.fixDayOfWeek(3, schedule.wednesdayWeekly);
    schedule.thursdayWeekly = this.fixDayOfWeek(4, schedule.thursdayWeekly);
    schedule.fridayWeekly = this.fixDayOfWeek(5, schedule.fridayWeekly);
    schedule.saturdayWeekly = this.fixDayOfWeek(6, schedule.saturdayWeekly);

    return schedule;
  }

  fixDayOfWeek(day, daySchedule) {
    //this fixes the times so they have the right day
    if (daySchedule) {
      daySchedule.daySlots.forEach((daySlot) => {
        daySlot.dailyStartTime = moment(daySlot.dailyStartTime, 'hh:mm').day(day);
        daySlot.dailyEndTime = moment(daySlot.dailyEndTime, 'hh:mm').day(day);
        if(daySlot.dailyEndTime.isBefore(daySlot.dailyStartTime)) {
          daySlot.dailyEndTime.add(1, 'day');
        }
      });
    }
    return daySchedule;
  }

  findNextOpenDay(schedule, day) {
    return this.$q((resolve) => {
      let findDay;
      let nextOpenDay;

      if (angular.isDefined(day)) {
        findDay = day;
        nextOpenDay = this.findDaySchedule(schedule, day);
      } else {
        findDay = moment().add(1, 'days');
        nextOpenDay = this.findDaySchedule(schedule, findDay.day());
      }
      //if the nextOpenDay comes back null then make it an empty object
      //with an empty array so the logic doesn't have to change
      nextOpenDay = nextOpenDay != null ? nextOpenDay : {daySlots: []};
      if (nextOpenDay.daySlots.length > 0) {
        resolve(nextOpenDay);
      } else if (this.nextDayOpenRuns < this.nextOpenDayRunLimit) {
        ++this.nextDayOpenRuns;

        if (Number.isInteger(findDay)) {
          findDay = moment().day(findDay);
        }

        this.findNextOpenDay(schedule, findDay.clone().add(1,'days').day()).then((result) => {
          resolve(result);
        });
      }
    });
  }

  findNextOpenTime(daySchedule) {
    let now = moment();
    if (daySchedule.daySlots.length > 0) {

      let earliestTime = daySchedule.daySlots[0].dailyStartTime.clone().endOf('day');
      daySchedule.daySlots.forEach((slot) => {

        //if today then get the next open time
        if (now.isSame(slot.dailyStartTime, 'day')) {
          if (now.isBefore(slot.dailyStartTime) && slot.dailyStartTime.isBefore(earliestTime)) {
            earliestTime = slot;
          }
          //else get the earliest time of all the slots in the daySchedule
        } else {
          if (slot.dailyStartTime.isSameOrBefore(earliestTime)) {
            earliestTime = slot;
          }
        }
      });
      //check to make sure the earliest time changed
      if (earliestTime.dailyStartTime && earliestTime.dailyStartTime.isSame(
        daySchedule.daySlots[0].dailyStartTime.clone().endOf('day')
      )) {
        return false;
      } else {
        //check to make sure the earliest time is not before now
        //and if it is then add some time to it
        if (earliestTime.dailyStartTime && earliestTime.dailyStartTime.isBefore(now)) {
          earliestTime.dailyStartTime.add(1, 'week');
        }

        return earliestTime;
      }
    } else {
      return false;
    }
  }

  findCurrentSlot(schedule) {
    return this.$q((resolve, reject) => {
      let daySchedule = this.findDaySchedule(schedule);

      if (daySchedule) {
        if (daySchedule.daySlots.length > 0) {
          let notBetween = 0;
          daySchedule.daySlots.forEach((slot) => {
            if(moment().isBetween(slot.dailyStartTime, slot.dailyEndTime)){
              //if the current time is between the slot times then thats the current slot
              resolve(slot);
            } else {
              //if the current time is NOT between then it's not the current slot
              //increment the count of times it's not between the slot times
              ++notBetween;
            }

            if (notBetween == daySchedule.daySlots.length) {
              //if the current time is not between any of the slot times then reject the promise
              reject();
            }
          });
        } else {
          //if there are no slots for the current daySchedule then reject
          reject();
        }
      } else {
        //if today's schedule is null then reject
        reject();
      }
    });
  }

  findNextSlot(schedule) {
    return this.$q((resolve) => {
      this.nextDayOpenRuns = 0;
      this.nextOpenDayRunLimit = 7;
      let nextOpenSlot;
      let daySchedule = this.findDaySchedule(schedule);
      //if the daySchedule comes back null then make it an empty object
      //with an empty array so the logic doesn't have to change
      daySchedule = daySchedule != null ? daySchedule : {daySlots: []};

      if (daySchedule.daySlots.length == 0) {
        //if there are no slots on this dayshedule, find the next dayslot
        this.findNextOpenDay(schedule).then((result) => {
          nextOpenSlot = this.findNextOpenTime(result);
          resolve(nextOpenSlot);
        });
      } else {
        //if there are no open times left for today then
        //get the earliest time on the next open day
        nextOpenSlot = this.findNextOpenTime(daySchedule);

        if (nextOpenSlot) {
          resolve(nextOpenSlot);
        } else {
          this.findNextOpenDay(schedule).then((result) => {
            nextOpenSlot = this.findNextOpenTime(result);
            resolve(nextOpenSlot);
          });
        }
      }

    });
  }
}

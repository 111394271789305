class MyConversationsController {
  constructor(SessionManager, UsersDomain, User) {
    'ngInject';
    this.SessionManager = SessionManager;
    this.UsersDomain = UsersDomain;
    this.User = User;

  }

  $onInit() {
    this.loading = true;
    this.currentUser = this.SessionManager.getCurrentUser();
    this.patientsNames = []

    this.UsersDomain.getUserAppointments(this.currentUser.id).then((response) => {
      this.patients = response.data.data.patients;
        this.filteredAppts = [];
      this.patients.forEach(patient => {
        patient.appointments.forEach(i => {
          if (angular.isDefined(i.conversations) && i.conversations.length > 0) {
            this.filteredAppts.push(i);
          }
        });
      });
      this.getUserInfo(this.patients);

      this.filteredAppts.forEach(i => {
        i.conversations.forEach(thread => {
          thread.unreadCount = this.getUnreads(thread.replies);
          thread.replyCount = this.getReplyCount(thread.replies);
        });
      })
      this.loading = false;
    });
  }

  getUnreads(replies) {
    let count = 0;
    replies.forEach(r => {
      if(!this.patientsNames.some((obj) => obj.id === r.from.id)) {
        if(!r.read) ++count;
      }
      if(r.replies.length > 0){
        count += this.getUnreads(r.replies);
      }
    });
    return count;
  }

  getReplyCount(replies) {
    let count = 0;
    replies.forEach(r => {
      ++count;
      if(r.replies.length > 0){
        count += this.getReplyCount(r.replies);
      }
    });
    return count;
  }

  getUserInfo(patients) {
    patients.forEach(patient => {
      let splitName = patient.name.split(',');
      this.patientsNames.push({
        id: patient.id,
        name: `${splitName[1]} ${splitName[0]}`
      });
    })
  }

  findPatient(patientId) {
    return this.patientsNames.find((obj) => {
      return obj.id === patientId
    });
  }

  updateCounts($event) {
    this.$onInit();
  }
}

export default MyConversationsController
import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './selfScheduling.component';
import service from './selfScheduling.service';

import searchBar from './searchBar/searchBar';
import searchBarService from './searchBar/searchBarService';
import CampaignList from './campaignList/campaignList';
import Campaign from './campaign/campaign';
import CampaignSchedulePreview from './campaignSchedulePreview/campaignSchedulePreview';
import campaignRegistration from './campaignRegistration/campaignRegistration';
import guestConfirmation from './guestConfirmation/confirmation';


const dependencies = [
  uiRouter,
  searchBar,
  CampaignList,
  Campaign,
  CampaignSchedulePreview,
  campaignRegistration,
  guestConfirmation
];

const selfScheduling = angular
  .module('connect.SelfScheduling', dependencies)
  .component('selfScheduling', component)
  .service('selfSchedulingService', service)
  .service('searchBarService', searchBarService)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('selfScheduling', {
        url: '/self-scheduling?pid',
        title: 'Self Scheduling',
        template: '<self-scheduling></self-scheduling>',
        allowUnlogged: true,
        params: { answers: null }
      });
  })
  .name;

export default selfScheduling;

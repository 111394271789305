import angular from 'angular';
import component from './threadModal.component';
import threadReply from "./threadReply/threadReply.component";

const dependencies = [];

const threadModal = angular
  .module('connect.threadList.threadModal', dependencies)
  .component('threadModal', component)
  .component('threadReply', threadReply)
  .name;

export default threadModal;

class onScrollDirective {
  constructor($window) {
    'ngInject';
    this.restrict = 'A';
    this.$window = $window;
  }

  link($scope, $element, attrs) {
    angular.element(this.$window).on(('scroll'), () => {
      if (this.$window.scrollY > attrs.pinElement) {
        $element.addClass('pinned');
      } else {
        $element.removeClass('pinned');
      }
    });
  }
}
export default ($window) => new onScrollDirective($window);

class MyWaitingRoomController {
  constructor(WaitingRoom, myWaitingRoomService, $stateParams, $state, $interval){
    'ngInject';
    this.WaitingRoom = WaitingRoom;
    this.myWaitingRoomService = myWaitingRoomService;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$interval = $interval;
  }

  $onInit(){
    this.loading = true;
    this.hasMap = false;
    this.facilityId = this.$stateParams.facilityId;
    this.patientVisitNumber = this.$stateParams.patientVisitNumber;
    this.update();
    this.updateInterval = this.$interval(()=>{
      this.update();
    },60000);
  }

  $onDestroy(){
    this.$interval.cancel(this.updateInterval);
  }

  update(){
    this.WaitingRoom.getWaitingRoomInformation(this.facilityId, this.patientVisitNumber).then((waitingRoom) => {
      this.details = waitingRoom.data;
      if(this.details.checkedInTime){
        this.checkedInTime = new Date(this.details.checkedInTime);
      }
      if (this.details.accessMapId) {
        this.myWaitingRoomService.retrieveMap(this.details.accessMapId).then(model => {
          if(model!=null){
            this.imageMap = model.imageMap;
            this.hasMap = true;
          }
          this.loading = false;
        });
      }
      else {
        this.loading = false;
      }
    }, () => {
      this.$state.transitionTo('findWaitingRoom');
    });
  }

  dotClass(index, currentIndex){
    return this.myWaitingRoomService.getDotClass(index, currentIndex);
  }

}

export default MyWaitingRoomController;

import angular from 'angular';
import angularFilter from 'angular-filter';
import uiRouter from 'angular-ui-router';
import component from './getInLine.component';
import service from './getInLine.service';
import maps from 'ngmap';
import form from './getInLineForm/getInLineForm';
import confirmation from './getInLineConfirmation/confirmation';

const dependencies = [
  angularFilter,
  uiRouter,
  'commonLibrary',
  'commonFeatures',
  maps,
  form,
  confirmation
];

const getInLine = angular
  .module('connect.getInLine', dependencies)
  .component('getInLine', component)
  .service('getInLineService', service)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('getInLine', {
        url: '/inline?d',
        allowUnlogged: true,
        params: { answers: null },
        template: '<get-in-line></get-in-line>'
      });
  }])
  .name;

export default getInLine;

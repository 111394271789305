import controller from './videoPreview.controller';
import template from './videoPreview.template.html';

const component = {
  controller,
  template,
  bindings: {
    modalInstance: '<',
    resolve: '<'
  }
};

export default component;

import angular from 'angular';
import component from './appointmentDetailsCard.component';
import service from '../../selfScheduling.service';

import timeoutModal from './timeoutModal/timeoutModal';
import timer from './timer/timer';

let dependencies = [
  timeoutModal,
  timer
];

const campaignRegistration = angular
  .module('connect.campaignRegistration.appointmentDetailsCard', dependencies)
  .component('appointmentDetailsCard', component)
  .service('selfSchedulingService', service)
  .name;

export default campaignRegistration;

import moment from 'moment';
import _ from 'lodash';

class RegisterFormController {
  constructor($scope, $log, $attrs, registerFormService, Organization, $timeout) {
    'ngInject';
    this.$scope = $scope;
    this.$attrs = $attrs;
    this.registerFormService = registerFormService;
    this.Organization = Organization;
    this.$log = $log;
    this.$timeout = $timeout;

  }

  $onInit() {
    this.relationships = [
      {key: 'CHILD', value: 'The patient is my child'},
      {key: 'PARENT', value: 'The patient is my parent'},
      {key: 'SPOUSE', value: 'The patient is my spouse'},
      {key: 'WARD', value: 'I am the guardian of the patient'},
      {key: 'GRANDPARENT', value: 'The patient is my grandparent'},
      {key: 'GRANDCHILD', value: 'The patient is my grandchild'},
      {key: 'GREATGRANDCHILD', value: 'The patient is my great grandchild'},
      {key: 'GREATGRANDPARENT', value: 'The patient is my great grandparent'}
    ];

    this.formData = {};

    this.selectedRelationship =  this.relationships.find(i => i.key === 'CHILD');

    this.registerFormService.resetErrors();

    this.Organization.getOrgIds()
      .then(response => this.orgId = response.orgId)
      .then(() => this.registerFormService.getConfig(this.orgId, this.deptId))
      .then(data => {
        this.config = data;
        this.onGetConfig({
          $event: data
        })
      })
      .then(() => this.Organization.getBulkOrganizationUnitAttributes([this.orgId]))
      .then(response => {
        this.registerFormService.hideCaptcha = response.data[0].attributesAsMap['CONNECT_HIDE_CAPTCHA'] == 'true' ? true : false;

        this.tosUrl = response.data[0].attributesAsMap['TOS_URL'] ? response.data[0].attributesAsMap['TOS_URL'] : 'https://jellyfishhealth.com/terms-of-service/';

        this.oldData = {};
        this.form.$valid = false;
        this.form.$invalid = true;

        this.checkFormForContactFields();
        if (angular.isDefined(this.user) && this.user != null) {
          // this.formData.relationship = {key: 'SELF', value: 'I am the patient'};
          if (angular.isDefined(this.user.tosAgreements) && this.user.tosAgreements != null) {
            this.formData.tos = this.user.tosAgreements.length > 0 ? true : false;
            this.formData.tosAgreements = this.user.tosAgreements;
          }

          if (angular.isUndefined(this.patient) && angular.isDefined(this.user.dateOfBirth) && this.user.dateOfBirth !== null) {
            let dobMoment = moment(this.user.dateOfBirth).utc();
            this.unformattedDOB = dobMoment.utcOffset(dobMoment.utcOffset()).format('MM/DD/YYYY');
            this.formatDOB();
          }
        }
        // else {
        //   this.formData.relationship = this.selectedRelationship;
        // }
        this.formData.relationship = {key: 'SELF', value: 'I am the patient'};
      }).catch(error => this.$log.error(error));
  }


  $onChanges($changes) {

    this.checkFormForContactFields();
    if (angular.isDefined(this.patient)) {
      this.formData = this.patient;
    }

    if (angular.isDefined($changes.patient) &&
      angular.isDefined($changes.patient.currentValue)
    ) {
      this.unformattedDOB = $changes.patient.currentValue.dob;
      this.formData.dob = $changes.patient.currentValue.dob;
      this.formData.firstName = $changes.patient.currentValue.firstName;
      this.formData.lastName = $changes.patient.currentValue.lastName;
      this.formData.ssn = $changes.patient.currentValue.ssn;
      this.formData.emailAddress = $changes.patient.currentValue.email ? $changes.patient.currentValue.email : this.formData.emailAddress;
      this.formData.phoneNumber = $changes.patient.currentValue.phoneNumber;
      this.formData.gender = $changes.patient.currentValue.gender;
      this.formData.relationship = $changes.patient.currentValue.relationship;
    }
  }

  $doCheck() {
    if (angular.isDefined(this.config) &&
      angular.isDefined(this.form) &&
      angular.isDefined(this.formData) && (!_.isEqual(this.oldData, this.formData) || (this.oldVal !== this.form.$valid))) {

      if( this.cohortName != 'Myself')
      {
        this.formData.relationship = this.selectedRelationship;
      }

      this.validateRegisterCriteria();
      this.oldVal = angular.copy(this.form.$valid);
      this.oldData = angular.copy(this.formData);
      let mergedData = {...this.patient, ...this.formData};
      this.onFormValid({
        $event: {
          formData: mergedData,
          isValid: !!this.form.$valid,
          regValidity: this.validateRegisterCriteria()
        }
      });
    }
  }

  selectRelation(relationship) {
    this.formData.relationship = relationship;
    this.selectedRelationship = relationship;
  }

  // Get validity of user info section for better error reporting
  validateRegisterCriteria() {
    let guestValid = true;
    let newAccountValid = true;

    if (this.asRegistered) {
      angular.forEach(this.config.registration.newAccount, (field) => {
        if (field.required && angular.isDefined(newAccountValid && this.form[this.removeSpaces(field.title)])) {
          newAccountValid = newAccountValid && this.form[this.removeSpaces(field.title)].$valid;
        }
      });
    } else {
      angular.forEach(this.config.registration.guest, (field) => {
        if (field.required && angular.isDefined(newAccountValid && this.form[this.removeSpaces(field.title)])) {
          guestValid = guestValid && this.form[this.removeSpaces(field.title)].$valid;
        }
      });
    }

    return {
      guestValid: guestValid,
      newAccountValid: newAccountValid
    };
  }

  tosChange() {
    if (this.formData.tos) {
      let tosAgreement = {
        timestamp: new Date().getTime(),
        tosUrl: this.tosUrl
      };
      if (angular.isUndefined(this.formData.tosAgreements)) {
        this.formData.tosAgreements = [];
      }
      this.formData.tosAgreements.push(tosAgreement);
    } else {
      this.tosAgreement = null;
      this.formData.tos = null;
    }
  }

  documentsValid($event) {
    this.validDocuments = $event.validDocuments;
    this.documents = $event.documents;

    this.onDocumentsValid({
      $event: {
        documents: this.documents,
        isValid: this.validDocuments
      }
    });
  }

  saveDocuments(event) {
    this.onSaveDocuments({$event : {doc: event.doc, patientId: angular.isDefined(this.patient) ? this.patient.id : null}});
  }

  closeAlerts() {
    this.onCloseAlerts({
      $event: {}
    })
  }

  removeSpaces(fieldName) {
    fieldName = fieldName.replace(/\s+/g, '');
    return fieldName;
  }

  formatDOB() {
    // transform date format from mm/dd/yyyy to yyyy-MM-dd
    this.formData.dob = moment(this.unformattedDOB, 'MM/DD/YYYY').format('YYYY-MM-DD');
  }

  findField(fields, typeId) {
    let found = false;

    angular.forEach(fields, (field) => {
      if (typeId === field.typeId) {
        found = true;
      }
    });

    return found;
  }

  checkFormForContactFields() {
    if (angular.isDefined(this.config)) {
      if (this.formFields === 'newAccount') {
        this.showSendEmail = true;
        this.showSendSMS = this.findField(this.config.registration.newAccount, '4');
        this.showSendVoice = this.findField(this.config.registration.newAccount, '4');
      } else {
        this.showSendSMS = this.findField(this.config.registration.guest, '4');
        this.showSendVoice = this.findField(this.config.registration.guest, '4')
        this.showSendEmail = this.findField(this.config.registration.guest, '6');
      }
    }
  }

  isRequired(field)
  {
    let required;
    switch(parseInt(field.typeId))
    {
      case 0:
        required = field.required && (this.isOther || ((!this.selfScheduling || this.formFields !== 'newAccount') && (!this.asRegistered || !this.patient || !this.patient.firstName )));
        break;
      case 1:
        required = field.required && (this.isOther || ((!this.selfScheduling || this.formFields !== 'newAccount') && (!this.asRegistered || !this.patient || !this.patient.lastName)));
        break;
      case 4:
        let smsRequired = this.formData.wantsSMS === true ? this.formData.wantsSMS : field.required;
        let voiceRequired = this.formData.wantsVoice === true ? this.formData.wantsVoice : field.required;
        required = smsRequired || voiceRequired;
        break;
      case 6:
        required = this.formData.wantsEmails === true ? this.formData.wantsEmails : this.isOther ? field.required : false;
        break;
      default:
        required = field.required;
    }
    return required;
  }

  cohortSelected(type)
  {
    this.onCohortSelect({$event: type});
    this.unformattedDOB = undefined;
  }

}

export default RegisterFormController;

import template from './appointmentDetailsCard.template.html';
import controller from './appointmentDetailsCard.controller';

let component = {
  controller: controller,
  template: template,
  bindings: {
    name: '<',
    address: '<',
    categories: '<',
    imageUrl: '<',
    slotData: '<',
    timedOut: '&',
    showError: '<',
    displayedErrors: '<',
    onCloseAlerts: '&'

  }
};

export default component;

export default () => (events, showPastEvent) => {
  let startOfToday = new Date().setHours(0,0,0,0);
  var tempEvents = [];
  events.forEach(function(appointment){
    if(!showPastEvent){
      if (new Date(appointment.start).getTime() > startOfToday) {
        tempEvents.push(appointment);
      }
    } else {
      tempEvents.push(appointment);
    }
  });
  return tempEvents;
};

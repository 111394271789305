import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './claimPatient.component';
import claimPatientForm from './claimPatientForm/claimPatientForm';

const dependencies = [
  uiRouter,
  claimPatientForm,
  'commonLibrary'
];

const claimPatient = angular
  .module('connect.claimPatient', dependencies)
  .component('claimPatient', component)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('claimPatient', {
        url: '/claim-patient/:appointmentId/:orgId/:deptId',
        showInHeader: false,
        allowUnlogged: true,
        title: 'Claim Patient Record',
        template: '<claim-patient></claim-patient>'
      });
  })
  .name;

export default claimPatient;

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import uibAlert from 'angular-ui-bootstrap/src/alert';
import component from './forgotPassword.component';

const forgotPassword = angular
  .module('connect.ForgotPassword', [uiRouter, uibAlert, 'commonLibrary'])
  .component('forgotPassword', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider.state('forgotPassword', {
      url: '/forgot_password',
      allowUnlogged: true,
      onlyUnlogged: true,
      template: '<forgot-password></forgot-password>'
    });
  }])
  .name;

export default forgotPassword;

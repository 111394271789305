import angular from 'angular';
import uiRouter from 'angular-ui-router';
import validateComponent from './validate.component';

const validate = angular
  .module('connect.Validate', [uiRouter, 'commonFeatures'])
  .component('validate', validateComponent)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('validate', {
        url: '/validate/:token',
        allowUnlogged: true,
        template: '<validate></validate>'
      });
  })
  .name;

export default validate;

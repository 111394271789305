import moment from 'moment';
class GetInLineFormBodyController {
  constructor(hoursScheduleService) {
    'ngInject';
    this.hoursScheduleService = hoursScheduleService;
  }

  $onInit() {
    this.asRegistered = true;
    this.today = moment().day();
    this.getInLineHelpText = "";
    if(angular.isDefined(this.getInLineHours)) {
      this.schedule = this.hoursScheduleService.parseSchedule(this.getInLineHours);
      this.isConfigured = this.hoursScheduleService.checkConfig(this.getInLineHours);
    }
    if (this.isConfigured) {
      this.hoursScheduleService.findCurrentSlot(this.schedule).then((slot) => {
        //get in line is open
        this.currentSlot = slot;
        this.checkGetInLineOpen();
      }, () => {
        //get in line is closed, so find the next open time
        this.hoursScheduleService.findNextSlot(this.schedule).then((slot) => {
          this.scheduleConfigured = true;
          this.nextSlot = slot;
          this.checkGetInLineOpen();
        });
      });
    }
    this.allDocumentsValid = true;
  }

  $onChanges($changes) {
    if (this.config && this.config.Inline) {
      if (this.config.Inline.guest) {
        this.asRegistered = false;
        this.formFields = 'guest';
      }
      if (this.config.Inline.registrationLogin) {
        this.asRegistered = true;
        this.formFields = 'newAccount';
      }
    }
    this.checkGetInLineOpen();
  }

  checkGetInLineOpen(){
    let now = moment(moment(), "HH:mm");
    if (angular.isUndefined(this.currentSlot) && angular.isDefined(this.nextSlot)) {
      this.getInLineOpen = false;
      // get in line is closed
      if (now.isSame(this.nextSlot.dailyStartTime, 'day') && now.isBefore(this.nextSlot.dailyStartTime)) {
        try {
        this.getInLineHelpText = "Get in Line hours start today at " +  this.nextSlot.dailyStartTime.format('LT').toString();
        } catch (e) {
          console.log(e)
        }
      } else {
        try{
        this.getInLineHelpText = "Get in Line hours start " +  this.nextSlot.dailyStartTime.format('dddd').toString()
          + ' at ' + this.nextSlot.dailyStartTime.format('LT').toString();
        } catch (e) {
          console.log(e)
      }
      }
    } else {
      this.getInLineOpen = true;
    }
  }

  documentsValid(event){
    this.onDocumentsValid({$event: event});
    this.allDocumentsValid = event.isValid;
  }

  wantRegister($event) {
    this.asRegistered = $event.reg;
    if (!this.asRegistered) {
      this.formFields = 'guest';
    } else {
      this.formFields = 'newAccount';
    }
  }

  formValid(event) {
    if(angular.isUndefined(event.formData.firstName)) event.formData.firstName = event.formData.userFirstName;
    if(angular.isUndefined(event.formData.lastName)) event.formData.lastName = event.formData.userLastName;
    this.participant = event.formData;
    this.isValid = event.isValid;
  }

  registered(value) {
    this.isRegistered({value});
  }

  showGetInLineButton() {
    if (this.config) {
      if (this.config.isRegistered && this.user) return true;
      else if (!this.config.isRegistered || (this.config.Inline.guest && !this.asRegistered)) return true;
      else return false;
    } else return false;
  }

  showRegisterAndGetInLineButton() {
    if (this.config) {
      if (this.config.isRegistered && !this.user && this.asRegistered) return true;
      else return false;
    } else return false;
  }

  getInLine() {
    if(this.user) {
      if(!this.isValid){
        this.participant = {};
      }
      angular.extend(this.participant, this.user);
    }
    this.getInLineAction({value: this.participant});
  }

  registerAndGetInLine() {
    this.registerAndGetInLineAction({value: this.participant});
  }

}

export default GetInLineFormBodyController;

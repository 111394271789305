import angular from 'angular';
import component from './header.component';
import uiRouter from 'angular-ui-router';
import uibDropdown from 'angular-ui-bootstrap/src/dropdown';


const header = angular
  .module('connect.Header', [uibDropdown, uiRouter, 'commonLibrary'])
  .component('mainHeader', component)
  .name;

export default header;

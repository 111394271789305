import template from './campaignList.template.html';
import controller from './campaignList.controller';

const component = {
  controller,
  template,
  bindings: {
    campaigns: '<',
    scheduleLimit: '<',
    onCampaignSelection: '&',
    onSlotSelection: '&'
  }
};

export default component;

import moment from 'moment';

class claimPatient {
  constructor(AccessRegistration, $stateParams, Organization, Access, Auth, flash, $location, User, $state, Patients, Appointment, SessionManager, $log) {
    'ngInject';
    this.AccessRegistration = AccessRegistration;
    this.$stateParams = $stateParams;
    this.Organization = Organization;
    this.Access = Access;
    this.Auth = Auth;
    this.flash = flash;
    this.$location = $location;
    this.User = User;
    this.$state = $state;
    this.Patients = Patients;
    this.Appointment = Appointment;
    this.SessionManager = SessionManager;
    this.$log = $log;
  }

  $onInit() {
    this.processing = false;
    this.isLoggedIn = !!this.SessionManager.isLoggedIn();

    if (this.isLoggedIn) {
      this.Appointment.getAppointment(this.$stateParams.appointmentId).then(() => {
            this.$state.go('myEventDetails', {
              eventId: '',
              appointmentId: this.$stateParams.appointmentId
            });
          },
          () => {
            this.claimRecordFormSetup();
          });
    } else {
      this.claimRecordFormSetup();
    }
  }

  claimRecordFormSetup() {
    this.response = 'search';
    this.AccessRegistration.getRegistrationConfig(this.$stateParams.orgId, this.$stateParams.deptId)
        .then(res => {
          this.formData = res.data.claimRecord;
        });

    this.Organization.getOrganization(this.$stateParams.orgId).then(organization => {
      this.organizationDetails = organization.data;
    });
  }

  submitForm($event) {
    this.processing = true;

    // DEBT: ... Not supported by IE
    const formData = {
      ...$event,
      dob: moment($event.dob).format('MM/DD/YYYY')
    };
    // remove empty values
    Object.keys(formData).forEach((key) => {
      if(formData[key] === ''){
        delete formData[key];
      }
    });
    this.claimPatientData = angular.copy(formData);
    this.participant = angular.copy(formData);

    this.notFound = false;
    this.Access.checkClaimRecord(formData, this.$stateParams.appointmentId).then((success) => {
      this.processing = false;
      this.recordMap = success.data;
      // check the response for the id hashmap and see what was found
      // patientId is null so not found
      if (this.recordMap.patientId == null)
      {
        this.notFound = true;
        this.response = 'search';
      }
      else if (this.recordMap.userId == null) // no userId, so no user account
      {
        this.response = 'create';
      }
      else // we have a patientId and a userId, so we have a user account
      {
        this.response = 'signin';
      }
    }, (err) => {
      this.$log.error("Error:", err);
      this.processing = false;
      this.notFound = true;
    });
  }

  signinUser($event) {
    this.processing = true;
    this.Auth.login($event.emailAddress, $event.password).then(() => {
      this.Patients.claimPatient(this.claimPatientData, this.recordMap.apptId).then(() => {
        this.processing = false;
        this.$location.path('/');
      }, (err) => {
        this.processing = false;
        this.flash.instantMessage(err.data, 'danger');
      });
    }, (err) => {
      this.processing = false;
      this.flash.instantMessage(err.data, 'danger');
    });
  }

  createUserRequest(participant){
    let userRequest = {
      user: {
        emailAddress: participant.email,
        phoneNumber: participant.phoneNumber,
        firstName: participant.firstName,
        lastName: participant.lastName,
        dateOfBirth: new Date(participant.dob),
        tosAgreements: participant.tosAgreements
      },
      password: {
        password: participant.password
      },
    };
    return userRequest;
  }

  register(event) {
    this.processing = true;
    // DEBT: ... Not supported by IE
    const participant = {
      ...event,
      ...this.participant
    };
    this.User.signUp(this.createUserRequest(participant)).then(() => {
      const tempUser = {
        emailAddress: participant.email,
        password: participant.password
      };
      this.signinUser(tempUser);
    }, (err) => {
      this.processing = false;
      this.flash.instantMessage(err.data, 'danger');
    });
  }

  sendPasswordReset(event){
    this.processing = true;
    this.User.sendPRRequest(event).then(
      () => {
        this.processing = false;
        this.message = {
          text: `Password reset instructions have been sent to ${event}. After resetting your password, return to this page and enter your new password to finish claiming your appointment.`,
          type: 'success'
        };
      },
      () => {
        this.processing = false;
        this.message = {
          text: 'There was an error processing your request. Please try again.',
          type: 'danger'
        };
      }
    );
  }

  navigateToLogin() {
    this.$state.go('login', {requested: true})
  }

}

export default claimPatient;

import moment from 'moment';

class SlotsWeekCalendarController {
  constructor(){
    'ngInject';
  }

  $onInit(){
    this.weekDays = [0,1,2,3,4,5,6];
  }

  getDisplayTime(time){
    return moment(time).tz(this.timezone).format('h:mm a');
  }

  selectSlot(selectedSlot){
    this.onSlotSelection({$event:selectedSlot});
  }

  getWeekDay(day, dateYear, weekNumber){
    let date = moment(dateYear).week(weekNumber).day(day);
    return date.valueOf();
  }

  hasSlots(){
    if(angular.isDefined(this.calendar)){
      return angular.isDefined(this.calendar.slots[this.weekNumber]);
    } else {
      return false;
    }
  }

}

export default SlotsWeekCalendarController;

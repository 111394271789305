class SearchBarController {
  constructor($uibModal, searchBarService){
    'ngInject';
    this.$uibModal = $uibModal;
    this.searchBarService = searchBarService;
  }

  $onInit(){
    this.searchData = this.searchBarService.getSearchData();
  }

  search(){
    this.searchBarService.setSearchData(this.searchData);
    this.onSearch({$event:this.searchData});
  }

  openSearchModal() {
    this.modalInstance = this.$uibModal.open({
      component: 'searchBarModal',
      resolve: {
      }
    });
  }


}

export default SearchBarController;
export default class {
  constructor($state, $q, Auth, Organization, myDocumentsService) {
    'ngInject';
    this.$state = $state;
    this.$q = $q;
    this.Auth = Auth;
    this.Organization = Organization;
    this.myDocumentsService = myDocumentsService;
  }

  $onInit() {
    this.navCollapsed = true;
    this.hideMyDocuments = true;
    this.getWaitRoomLinkConfig();

    this.myDocumentsService.getHideMyDocuments().then((hideMyDocs) => {
      this.hideMyDocuments = hideMyDocs;
    });
  }

  isLogged() {
    return this.Auth.isLoggedIn();
  }

  getCurrentUser() {
    return this.Auth.getCurrentUser();
  }

  navigateTo(routeName, parameters) {
    this.navCollapsed = !this.navCollapsed;
    this.$state.go(routeName, parameters);
  }

  logout() {
    this.Auth.logout();
  }

  getWaitRoomLinkConfig() {
    this.Organization.getOrgIds()
      .then(orgIds => this.Organization.getOrgUnitAttributes(orgIds.orgId))
      .then(response => {
        this.showWaitRoomLink = response.data.attributesAsMap['ORGANIZATION_SHOW_WAIT_ROOM_LINK'] === 'true' ? true : false;
      }).catch(() => this.showWaitRoomLink = false);
  }
}

import moment from 'moment';

class GetInLineController {
  constructor($rootScope, $document, flash, getInLineService, Auth, WaitingRoom, $state, Schedule, Organization, $location) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$document = $document;
    this.flash = flash;
    this.getInLineService = getInLineService;
    this.Auth = Auth;
    this.WaitingRoom = WaitingRoom;
    this.$state = $state;
    this.Schedule = Schedule;
    this.Organization = Organization;
    this.$location = $location;
  }

  $onInit() {
    this.getPreviousLocation();
    this.completed = false;
    this.processing = true;
    this.now = moment();
    this.user = this.getInLineService.getCurrentUser();
    this.config = {};
    if(this.user!=null) this.existingPatientId = this.getInLineService.getFirstPatientIdForUser();

    this.getInLineService.getCurrentOrg().then(org => {
      this.org = org;

      this.getInLineService.getCurrentOrgUnit(org).then(department => {
        this.department = department;
        this.Organization.getOrgUnitAttributes(this.department.id).then(atts => {
          let config = angular.fromJson(atts.data.attributesAsMap['GET_IN_LINE_QUESTIONNAIRE_CONFIG']);
          if(config && config.enabled && !this.$state.params.answers) {
            this.$state.go('questionnaire', {gid: this.department.id, d: this.$location.search().d });
          }
          this.Schedule.getSchedule(this.department.scheduleId).then(res => {
            this.businessHours = res.data;

            this.getInLineService.getConfig(this.org,this.department).then(config => {
              this.processing = false;
              this.config = config;

              this.getInLineService.getDocuments(this.config.Inline.appointmentTypeId, this.existingPatientId).then( documents => {
                this.documents = documents;
                this.processing = false;

                this.generateMap();
                if (this.config.Inline.showWaitTime) {

                  this.getInLineService.getWaitTime(this.org,this.department).then(waitTime => {
                    this.config.waitTime = waitTime;
                    let estimatedWaitTimeInMinutes = moment.duration(this.config.waitTime.estimatedWaitTimeLong);
                    this.config.waitTime.estimatedWaitTime = estimatedWaitTimeInMinutes.asMinutes();
                  }, () => {
                    this.config.Inline.showWaitTime = false;
                  });
                }
              })
            }, () => {
              this.flash.instantMessage('Cannot get Configurations', 'danger');
              this.processing = false;
            });
          });
        })
      });
    }, () => {
      this.flash.instantMessage('Cannot get current organization', 'danger');
    } );
  }

  registered(value) {
    this.config.isRegistered = value;
  }

  getPreviousLocation() {
    if(angular.isDefined(this.$document[0].referrer) &&  this.$document[0].referrer.length>0) {
      this.hasPreviousLocation = true;
      this.previousLocation = this.$document[0].referrer;
    }
  }

  getType() {
    return (this.config.isRegistered) ? 'newAccount' : 'guest';
  }

  generateMap() {
    let useFacilityAddress = this.config.WaitingRoom.mobileWaitingRoomConfig.useFacilityAddress;
    let address = useFacilityAddress ? this.department.address : this.org.address;
    this.getInLineService.getCoordinatesByAddress(address)
      .then(responseMap => {
        this.map = responseMap;
      }, () => {
        this.flash.instantMessage('Cannot Get Map Data', 'danger');
      });
  }

  getInLine(participant) {
    this.participant = participant;
    if(participant.dob) {
      participant.dob = moment(participant.dob).format('MM/DD/YYYY');
    }
    this.processing = true;

    if (this.config.isRegistered && this.user) {
      this.participant.email = this.user.emailAddress;
    }
    if (this.config.Inline.appointmentTypeId) {
      this.participant.appointmentTypeId = this.config.Inline.appointmentTypeId;
    }
    this.getInLineService.getInLine(this.participant, this.org).then(getInLineResponse =>{
      this.saveAllDocuments(this.participant, getInLineResponse.data.id, this.existingPatientId!=null);
      this.WaitingRoom.getWaitingRoomInformation(this.department.facilityId, getInLineResponse.data.displayString ).then(waitingRoomRes => {
        this.config.WaitingRoom = waitingRoomRes.data;
        this.processing = false;
        this.completed = true;
      }, response => {
        this.flash.instantMessage(response.data,'danger');
      });
    }, response => {
      this.flash.instantMessage(response.data,'danger');
    });
  }

  registerAndGetInLine(participant) {
    this.participant = participant;
    if(participant.dob) {
      participant.dob = moment(participant.dob).format('MM/DD/YYYY');
    }
    this.processing = true;
    this.getInLineService.register(this.participant)
      .then((resp) => {
        let newPatient = resp.data.patient;
        newPatient.wantsEmails = this.participant.wantsEmails;
        newPatient.wantsSMS = this.participant.wantsSMS;
        newPatient.wantsVoice = this.participant.wantsVoice;
        this.Auth.login(this.participant.email, this.participant.password, this.org.id, this.department.id)
          .then(() => {
            this.flash.instantMessage('Welcome, '+ this.participant.email + '!', 'success');
            this.$rootScope.$emit('loggedUser');
            this.getInLine(newPatient, this.org);
          }, err => {
            this.flash.instantMessage(err.data, 'danger');
          });
      }, err => {
        if(err.status === 409) {
          this.flash.onRedirectMessage('This email address already exists. Please sign in.', 'info', 'getInLine', {}, true);
          this.$state.go('login', {}, {reload: false});
        } else {
          this.flash.instantMessage(err.data, 'danger');
        }
      });
  }

  saveAllDocuments(patient, appointmentId, existingPatientId) {
    if(existingPatientId) {
      angular.forEach(this.unsavedDocuments, doc => {
        this.getInLineService.saveDocument(doc, appointmentId);
      });
    } else {
      this.getInLineService.getPatientDocumentsMap( patient.id ,this.config.Inline.appointmentTypeId).then(documentMap => {
        angular.forEach(this.unsavedDocuments, doc => {
          if (doc.status === 'COMPLETE') {
            if (angular.isDefined(documentMap[doc.formDefinitionId])) {
              doc.id = documentMap[doc.formDefinitionId].id;
              doc.version = documentMap[doc.formDefinitionId].version;
            }
            this.getInLineService.saveDocument(doc, appointmentId);
          }
        });
      });
    }
  }

  documentsValid($event){
    this.unsavedDocuments = $event.documents;
  }
}

export default GetInLineController;

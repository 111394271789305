import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './myEvents.component';
import service from './myEvents.service';
import myEventsDetails from './myEventDetails/myEventDetails';
import pastEventsFilter from './pastEventsFilter/pastEventsFilter';

const myEvents = angular
  .module('connect.MyEvents', [uiRouter, myEventsDetails, 'commonLibrary'])
  .component('myEvents', component)
  .service('myEventsService', service)
  .filter('pastEventsFilter', pastEventsFilter)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('myEvents', {
        url: '/',
        showInHeader: true,
        title: 'My Events',
        template: '<my-events></my-events>'
      });
  }])
  .name;

export default myEvents;

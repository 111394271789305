class LoginController {
  constructor($timeout, $state, $location, $rootScope, flash, Auth, ConfigManager, selfSchedulingService) {
    'ngInject';
    this.$timeout = $timeout;
    this.$state = $state;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.flash = flash;
    this.Auth = Auth;
    this.ConfigManager = ConfigManager;
    this.selfSchedulingService = selfSchedulingService;
  }

  $onInit() {
    this.processing = false;
  }

  getVersion() {
    return this.ConfigManager.getByKey('version');
  }

  pendoInitialization(userEmail, org) {
    // in your authentication promise handler or callback
    //eslint-disable-next-line no-undef
    pendo.identify({
      visitor: {
        id:              userEmail  // Required if user is logged in
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },

      account: {
        id:           org.orgId, // Required if using Pendo Feedback
        name:         org.name// Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional

        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      }
    });
  }

  doLogin() {
    this.processing = true;
    this.Auth.login(this.loginData.email, this.loginData.password)
    .then(() => {
      this.selfSchedulingService.getOrgInfo().then((orgInfo) => {
        this.orgInfo = orgInfo;
        this.pendoInitialization(this.loginData.email, orgInfo.orgIds);
      });
      this.flash.instantMessage(`Welcome back, ${this.loginData.email}!`, 'info');
      this.processing = false;
      this.redirectToSuccess(this.$rootScope.savedState);
    }, (err) => {
      this.processing = false;
      this.flash.instantMessage(err.data, 'danger');
    });
  }

  redirectToSuccess(savedState) {
    this.$timeout(() => {
      if(savedState) {
        this.$state.go(savedState.state.name, savedState.params);
      }
      else this.$location.path('/');
    });
  }
}

export default LoginController;

class SignUpController {
  constructor($timeout, $location, $state, flash, Auth, ParticipantReg, Organization, $stateParams, User) {
    'ngInject';
    this.$timeout = $timeout;
    this.$location = $location;
    this.flash = flash;
    this.Auth = Auth;
    this.ParticipantReg = ParticipantReg;
    this.Organization = Organization;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.User = User;
  }

  formValid(event) {
    if(angular.isUndefined(event.formData.firstName)) event.formData.firstName = event.formData.userFirstName;
    if(angular.isUndefined(event.formData.lastName)) event.formData.lastName = event.formData.userLastName;
      this.formModel = {
        user: {
          emailAddress: event.formData.email,
          phoneNumber: event.formData.phoneNumber,
          firstName: event.formData.firstName,
          lastName: event.formData.lastName,
          dateOfBirth: event.formData.dob,
          tosAgreements: event.formData.tosAgreements
        },
        password: {
          password: event.formData.password
        }
      };
      this.isSignUpValid = event.isValid;
  }

  // Modal Methods

  cancel() {
    this.modalInstance.dismiss();
  }

  modalRegister() {
    this.processing = true;
    let user = this.formModel;
    this.User.signUp(user)
      .then(() => {
        this.modalInstance.close(this.formModel);
      }, error => {
        this.processing = false;
        if (error.status == 409) {
          this.error = 'The email address you entered is already in use';
        } else {
          this.error = error.data;
        }
      });
  }

  // End of Modal Methods

  register() {
    this.processing = true;
    let user = this.formModel;
    this.User.signUp(user)
      .then(() => {
        this.$state.go('signUpSuccess', {
          email: this.formModel.user.emailAddress,
          password: this.formModel.password.password
        });
      }, error => {
        this.processing = false;
        if (error.status == 409) {
          this.error = 'The email address you entered is already in use';
        } else {
          this.error = error.data;
        }
      });
  }
}

export default SignUpController;

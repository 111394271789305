class ForgotPasswordController {
  constructor(User) {
    'ngInject';
    this.User = User;
  }
  resetPassword() {
    this.processing = true;
    this.User.sendPRRequest(this.email)
    .then(
      () => {
        this.processing = false;
        this.message = {
          text: `Instructions have been sent to ${this.email} to reset the password.`,
          type: 'success'
        };
      },
      () => {
        this.processing = false;
        this.message = {
          text: 'There was an error processing your request. Please try again.',
          type: 'danger'
        };
      }
    );
  }
}

export default ForgotPasswordController;

class SelfSchedulingController {
  constructor(selfSchedulingService, $state, $uibModal, SessionManager, $stateParams, $sce, $log) {
    'ngInject';
    this.selfSchedulingService = selfSchedulingService;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.SessionManager = SessionManager;
    this.$stateParams = $stateParams;
    this.$sce = $sce;
    this.$log = $log;
  }

  $onInit() {
    this.questionnaireAnswers = this.$state.params.answers;
    this.showError = false;
    this.errorMessage = '<div class="alert alert-danger">There was an error processing your request, please try again.</div>';

    this.selfSchedulingService.getOrgInfo().then((orgInfo) => {
      this.orgInfo = orgInfo;
      this.getOrgAttributes(orgInfo.orgIds.orgId);
    });
  }

  filterProviders() {
    this.selfSchedulingService.filterProviders(this.questionnaireId, this.questionnaireAnswers, this.orgInfo.orgIds.orgId).then((ans) => {
      let event = {providerIds: ans};
      this.searchCampaigns(event);
    });
  }

  searchCampaigns(event) {
    this.loading = true;
    this.searchResults = null;
    this.questionnaireAnswers = this.questionnaireAnswers == null ? [] : this.questionnaireAnswers;
    //searchCampaigns(event, this.orgInfo, this.selfSchedulingService.getPopulationId(this.$stateParams))
    this.selfSchedulingService.queryProviderScheduleGroups(this.questionnaireAnswers, this.orgInfo, this.questionnaireId, this.selfSchedulingService.getPopulationId(this.$stateParams)).then(data => {
      this.searchResults = data.data;
      this.loading = false;
      angular.forEach(this.searchResults, result => {
        result.schedules = result.schedules.filter(schedule => {
          if (schedule.nextAvailableSlots.length > 0) {
            return schedule
          }
        });
        result.schedules.sort((a,b) => { // to put the previews in order by the next available slot
          return a.nextAvailableSlots[0] - b.nextAvailableSlots[0];
        })
      });
      this.searchResults = this.searchResults.filter(i => {
        if (i.schedules.length > 0) {
          return i;
        }
      });
      if (this.searchResults.length === 0) {
        this.errorMessage = this.noSchedulingResultsText;
        this.showError = true;
      }
    }, () => {
      this.showError = true;
      this.loading = false;
    });
  }

  getOrgAttributes(orgId) {
    this.selfSchedulingService.getOrgAttributes(orgId).then(atts => {
      this.orgAtts = atts;
      this.setAttributeValues();
    });
  }

  setAttributeValues() {
    let providerText = this.orgAtts.attributesAsMap['SELF_SCHEDULING_SEARCH_HEADER_TEXT'] ? this.orgAtts.attributesAsMap['SELF_SCHEDULING_SEARCH_HEADER_TEXT'] : 'Find a Provider';
    this.findAProviderText = this.$sce.trustAsHtml(providerText);
    let noSchedulingResultsText = this.orgAtts.attributesAsMap['SELF_SCHEDULING_NO_RESULTS_TEXT'] ? this.orgAtts.attributesAsMap['SELF_SCHEDULING_NO_RESULTS_TEXT'] : 'No Providers Found';
    this.noSchedulingResultsText = this.$sce.trustAsHtml(noSchedulingResultsText);
    this.schedulePreviewLimit = this.orgAtts.attributesAsMap['SELF_SCHEDULING_SCHEDULE_PREVIEW_LIMIT'] ? this.orgAtts.attributesAsMap['SELF_SCHEDULING_SCHEDULE_PREVIEW_LIMIT'] : 2;
    if (angular.isDefined(this.orgAtts.attributesAsMap['SELF_SCHEDULE_QUESTIONNAIRE_CONFIG'])) {
      let config = angular.fromJson(this.orgAtts.attributesAsMap['SELF_SCHEDULE_QUESTIONNAIRE_CONFIG']);
      this.configEnabled = config.enabled;
      this.questionnaireId = config.questionnaireId;
      if (config.enabled) {
        if (this.questionnaireAnswers == null) {
          this.$state.go('questionnaire', {pid: this.$stateParams.pid});
        }
      }
    }
    this.searchCampaigns();
  }

  getAppointmentTypeId(campaignId) {
    let apptTypeId = null;
    this.searchResults.forEach(result => {
      if(result.campaign.id == campaignId){
        result.schedules.forEach(schedule => {
          if(schedule.appointmentTypeId != null && schedule.appointmentTypeId.length > 0){
            apptTypeId = schedule.appointmentTypeId;
          }
        })
      }
    });
    return apptTypeId;
  }

  campaignSelected($event) {
    if( $event.appointmentTypeId == null ){
      $event.appointmentTypeId = this.getAppointmentTypeId($event.campaignId);
    }
    if (angular.isDefined($event.appointmentTypeId)) {
      this.$state.go('selfSchedulingDetails', {
        campaignId: $event.campaignId,
        locationId: $event.locationId,
        appointmentTypeId: $event.appointmentTypeId,
        answers: this.questionnaireAnswers
      });
    } else {
      this.$state.go('selfSchedulingDetails', {
        campaignId: $event.campaignId,
        locationId: $event.locationId,
        answers: this.questionnaireAnswers
      });
    }
  }

  selectSlot($event) {
    let modalInstance = this.$uibModal.open({
      component: 'progressModal',
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        progressModalTitle: () => 'Just a moment, we\'re securing <br />your appointment time.',
        holdSlot: () => true,
        location: () => $event.location,
        schedule: () => $event.schedule,
        slotStartTime: () => $event.slot
      }
    });
    modalInstance.result.then((result) => {
      this.$state.go('campaignRegistration', {
        campaignId: $event.campaign.id,
        slotId: result.id,
        appointmentTypeId: $event.schedule.appointmentTypeId
      });
    }, (err) => {
      //modal cancelled
      this.$log.error(err);
      this.selfSchedulingService.getOrgAttributes($event.schedule.organizationId).then((data) => {
        this.errorMessage = data.attributesAsMap['SLOT_HOLD_ERROR_MESSAGE']
          ? '<div class="alert alert-danger">' + data.attributesAsMap['SLOT_HOLD_ERROR_MESSAGE'] + '</div>'
          : '<div class="alert alert-danger">The slot you have selected is over capacity please try again</div>';
        this.showError = true;
      });
    });
  }
}

export default SelfSchedulingController;

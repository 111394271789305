import moment from 'moment';

export default class hoursSchedule {
  constructor(hoursScheduleService) {
    'ngInject';
    this.hoursScheduleService = hoursScheduleService;
  }

  $postLink() {
    this.isCollapsed = true;
    if(angular.isDefined(this.schedule)){
      this.schedule = this.hoursScheduleService.parseSchedule(this.schedule);
      this.isConfigured = this.hoursScheduleService.checkConfig(this.schedule);
    }
    if (this.isConfigured) {
      this.hoursScheduleService.findCurrentSlot(this.schedule).then((slot) => {
        //facility is open
        this.scheduleConfigured = true;
        this.openNow = true;
        this.onOpen({$event: this.openNow});
        this.currentSlot = slot;
      }, () => {
        //facility is closed, so find the next open time
        this.openNow = false;
        this.onOpen({$event: this.openNow});
        this.hoursScheduleService.findNextSlot(this.schedule).then((slot) => {
          this.scheduleConfigured = true;
          this.nextSlot = slot;
        });
      });
    }
  }

  isOpen(slot, dayNumber) {
    return this.isToday(dayNumber) && moment().isBetween(slot.dailyStartTime,slot.dailyEndTime);
  }

  isToday(dayNumber) {
    return (dayNumber === moment().isoWeekday());
  }

}

import angular from 'angular';
import angularFilter from 'angular-filter';
import uiRouter from 'angular-ui-router';
import component from './forms.component';
import service from './forms.service';

const dependencies = [
  angularFilter,
  uiRouter,
  'commonLibrary',
  'commonFeatures'
];

const patientIntakeForms = angular
  .module('connect.forms.intake', dependencies)
  .component('formsPatientIntake', component)
  .service('formsPatientIntakeService', service)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('formsPatientIntake', {
        url: '/forms-patient-intake?{token}',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Forms',
        template: '<forms-patient-intake></forms-patient-intake>'
      });
  }])
  .name;

export default patientIntakeForms;

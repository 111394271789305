class LoggedWidgetController {
  constructor($location, SessionManager) {
    'ngInject';
    this.$location = $location;
    this.SessionManager = SessionManager;
  }

  $onChanges() {
    if(angular.isDefined(this.config) && this.config.guest && !this.config.registrationLogin){
      this.isRegistered({value: false});
      this.asRegistered = false;
      this.regChange();
    } else {
      this.isRegistered({value: true});

      if (angular.isUndefined(this.asRegistered)) {
        this.asRegistered = true;
        this.regChange();
      }
    }
  }

  regChange() {
    this.onRegisterSelect({$event: {reg: this.asRegistered}});
  }

  signIn() {
    this.$location.path('/login');
  }

  signOut() {
    this.SessionManager.removeSession();
  }
}

export default LoggedWidgetController;

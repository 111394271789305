export default class MultipleChoiceController{
  constructor(){
    'ngInject';
  }

  deactivateRadio() {
    this.radio = false;
  }

  activateRadio() {
    this.item.awnser = "";
    this.radio = true;
  }
}
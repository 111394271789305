class VideoDoneController {
  constructor($stateParams, $sce, $state, $interval, $location, Access) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.$sce = $sce;
    this.$state = $state;
    this.$interval = $interval;
    this.$location = $location;
    this.Access = Access;
  }

  $onInit() {
    this.$location.replace(); //clear last history route
    this.appointmentId = this.$stateParams.appointmentId;
    this.loading = true;
    this.surveyId = ''

    this.Access.getAttributesByAppointmentId(this.appointmentId).then(res => {
      this.orgId = res.data.organizationId;
      this.orgAtts = res.data.organizationAttributesMap;
      this.departmentAtts = res.data.departmentAttributesMap;

      if (angular.isDefined(this.departmentAtts['VIDEO_VISIT_SURVEY_TEMPLATE'])) {
        // casting string to boolean
        this.hasSurvey = !!this.departmentAtts['VIDEO_VISIT_SURVEY_TEMPLATE'];
      } else {
        this.hasSurvey = false;
      }


      // check if they have a survey attribute
      if (this.hasSurvey) {
        this.setAttributeValuesForSurvey();
      } else {
        this.setAttributeValuesWithoutSurvey();
      }

      this.startCountdown();
      this.loading = false;
    });
  }

  $onDestroy() {
    this.$interval.cancel(this.redirectTimer);
  }

  startCountdown() {
    this.redirectTimer = this.$interval(() => {
      this.countdownSeconds--;
      if (this.countdownSeconds === 0) {
        if (this.hasSurvey) {
          this.goToSurvey();
        } else {
          this.goToMyEvents();
        }
        this.$interval.cancel(this.redirectTimer);
      }
    }, 1000)
  }

  goToSurvey() {
    this.$state.go('survey', {'surveyId': this.surveyId, 'organizationId': this.orgId, 'appointmentId': this.appointmentId }, {reload: true});
  }

  goToMyEvents() {
    this.$state.go('myEvents', {reload: true});
  }

  setAttributeValuesForSurvey() {
    this.countdownSeconds = this.departmentAtts['ORGANIZATION_UNIT_REDIRECT_TIMER'] ? this.departmentAtts['ORGANIZATION_UNIT_REDIRECT_TIMER'] : '10';
    this.countdownSeconds = Number.parseInt(this.countdownSeconds);
    let endVisitSurveyText = this.departmentAtts['VIDEO_VISIT_SURVEY_END_TEXT'] ? this.departmentAtts['VIDEO_VISIT_SURVEY_END_TEXT'] : 'Thank you for visiting.';
    this.endVisitHeader = this.$sce.trustAsHtml(endVisitSurveyText);
    let surveyButtonText = this.departmentAtts['VIDEO_VISIT_SURVEY_BUTTON_TEXT'] ? this.departmentAtts['VIDEO_VISIT_SURVEY_BUTTON_TEXT'] : 'Continue to Survey';
    this.surveyButtonText = this.$sce.trustAsHtml(surveyButtonText);
    this.surveyId = this.departmentAtts['VIDEO_VISIT_SURVEY_TEMPLATE'];
  }

  setAttributeValuesWithoutSurvey() {
    this.countdownSeconds = this.departmentAtts['ORGANIZATION_UNIT_REDIRECT_TIMER'] ? this.departmentAtts['ORGANIZATION_UNIT_REDIRECT_TIMER'] : '10';
    this.countdownSeconds = Number.parseInt(this.countdownSeconds);
    let endVisitText = this.departmentAtts['VIDEO_VISIT_NO_SURVEY_END_TEXT'] ? this.departmentAtts['VIDEO_VISIT_NO_SURVEY_END_TEXT'] : 'Thank you for visiting.';
    this.endVisitHeader = this.$sce.trustAsHtml(endVisitText);
    let noSurveyButtonText = this.departmentAtts['VIDEO_VISIT_NO_SURVEY_BUTTON_TEXT'] ? this.departmentAtts['VIDEO_VISIT_NO_SURVEY_BUTTON_TEXT'] : 'Go to My Events';
    this.noSurveyButtonText = this.$sce.trustAsHtml(noSurveyButtonText);
  }
}

export default VideoDoneController;

import moment from 'moment-timezone';

class MyEventDetailsController {
  constructor($q, $location, $filter, $anchorScroll, $sce, $state, $stateParams, myEventsService, SessionManager, Access, Bloom, User, flash, $uibModal, config, $log, FormsDomain, AppointmentsDomain, Appointment, $window) {
    'ngInject';
    this.$q = $q;
    this.$filter = $filter;
    this.$sce = $sce;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.myEventsService = myEventsService;
    this.SessionManager = SessionManager;
    this.Access = Access;
    this.Bloom = Bloom;
    this.User = User;
    this.flash = flash;
    this.$uibModal = $uibModal;
    this.config = config;
    this.$log = $log;
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;
    this.FormsDomain = FormsDomain;
    this.AppointmentsDomain = AppointmentsDomain;
    this.Appointment = Appointment;
    this.$window = $window;
  }

  static get $inject() {
    return ['$q', '$location', '$filter', '$anchorScroll','$sce', '$state', '$stateParams', 'myEventsService', 'SessionManager', 'Access', 'Bloom', 'User', 'flash', '$uibModal', 'commonLibrary.config', '$log', 'FormsDomain', 'AppointmentsDomain', 'Appointment', '$window'];
  }

  $onInit() {
    this.userAgent = this.$window.navigator.userAgent;
    this.hideCalendarBtn = !!(this.userAgent.includes("Mobile"));
    this.loading = true;
    this.readOnly = true;

    if (this.$stateParams.flashMessage != null) {
      this.selfScheduleSuccess = this.$stateParams.flashMessage;
    }

    if (this.$stateParams.eventId) this.eventId = this.$stateParams.eventId;
    if (this.$stateParams.appointmentId) this.appointmentId = this.$stateParams.appointmentId;

    this.allForms = [];
    this.unitAttributes = [];
    this.currentUser = this.SessionManager.getCurrentUser();
    this.downloadPromise = this.Bloom.downloadICS;
    this.icsPromiseParams = [this.$stateParams.eventId];

    this.AppointmentsDomain.getAppointment(this.$stateParams.appointmentId).then((response)=>{
      this.appointment = response.data.data;
      if(this.appointment.copay) this.appointment.copayDisplay = this.$filter('currency')(this.appointment.copay.amount );
      let splitName = this.appointment.patient.name.split(',')
      this.user = {id: this.appointment.patient.id,  name: `${splitName[1]} ${splitName[0]}`}
      this.Appointment.getAppointment(this.$stateParams.appointmentId).then(res => {
        this.conversations = res.data.conversations;
        if(this.conversations != null){
          this.conversations.forEach(thread => {
            thread.unreadCount = this.getUnreads(thread.replies);
            thread.replyCount = this.getReplyCount(thread.replies);
          });
        }
      })
      this.myEventsService.getDepartmentAttributes([this.appointment.departmentId]).then((res) => {
        this.unitAttributes = res.data;
      });
      this.checkInEnabled = this.enableCheckInButton(this.appointment);

      if(angular.isDefined(this.appointment.forms)) {
        this.documents = this.appointment.forms;

        // Count the number of completed documents
        this.documentsLeft = this.appointment.forms.reduce((accumulator, currentForm) => {
          return accumulator + (currentForm.status === "COMPLETE" ? 0 : 1);
        }, 0);
      }

      this.scrollTo(this.$stateParams.section);
      this.loading = false;
    });
  }

  getUnreads(replies) {
    let count = 0;
    replies.forEach(r => {
      if (r.from.id !== this.user.id) {
        if(!r.read) ++count;
      }
      if(r.replies.length > 0){
        count += this.getUnreads(r.replies);
      }
    });
    return count;
  }

  getReplyCount(replies) {
    let count = 0;
    replies.forEach(r => {
      ++count;
      if(r.replies.length > 0){
        count += this.getReplyCount(r.replies);
      }
    });
    return count;
  }

  updateCounts($event) {
    this.conversations =  $event;
    this.conversations.forEach(thread => {
      thread.unreadCount = this.getUnreads(thread.replies);
      thread.replyCount = this.getReplyCount(thread.replies);
    });
  }


  navigateToClaimPatient() {
    this.loading = false;
    this.$state.transitionTo('claimPatient', {
      appointmentId: !!this.eventId ? this.eventId : this.appointmentId,
      orgId: this.SessionManager.getCurrentOrganization().id
    });
  }

  scrollTo(id){
    this.$location.hash(id);
    this.$anchorScroll();
  }

  payCopay(event){
    if(this.appointment && this.appointmentId)
      this.$state.go('payment', {id:this.appointmentId});
  }

  getFormattedTime(time) {
    if (angular.isDefined(this.appointment.location) && angular.isDefined(this.appointment.location.timezone)) {
      return moment(time).tz(this.appointment.location.timezone).format('h:mm A z');
    }
    return moment.tz(time, moment.tz.guess()).format('h:mm A z');
  }

  getTooltipMessage(event) {
    return this.myEventsService.getTooltipMessage(event);
  }

  checkIn(event) {
    let enabled = this.myEventsService.enableCheckInButton(event, this.unitAttributes);

    if (enabled) {
      this.myEventsService.checkIn(event).then((result) => {
        // DEBT need to make check in call to the domain service and set appointment status off of response here
        this.appointment.status = 'ACTIVE';
        this.checkInEnabled = this.enableCheckInButton(this.appointment);
        this.flash.showToast('You have successfully checked in for your appointment', 'success');
      }, () => {
        this.flash.showToast('There was a problem checking you in for your appointment', 'danger');
      });
    }
  }


  saveDocuments($event) {
    let saveForm = {data: {id: $event.doc.id, version: $event.doc.version}, formModel: $event.doc.data};
    this.FormsDomain.submitFormForAppointment(saveForm, this.appointmentId).then(res => {
      angular.forEach(this.documents, (doc, index, docs) => {
        if (doc.id === res.data.data.id) {
          docs[index].version = res.data.data.version;
          docs[index].data = res.data.formModel;
        }
      });
    });
  }

  allowRemoteCheckIn(event) {
    let allowed = this.myEventsService.allowRemoteCheckIn(event, this.unitAttributes);
    return allowed;
  }

  enableCheckInButton(event) {
    let enabled = this.myEventsService.enableCheckInButton(event, this.unitAttributes);

    this.cancelTooltip = this.appointment.status === 'CANCELLED' ? 'This Appointment is Already Canceled' : 'Cancel this Appointment';
    return enabled;
  }

  allowRemoveCancelButton(appointment){
    let allowed = this.myEventsService.allowRemoveCancelButton(appointment, this.unitAttributes);
    return allowed;
   }


  cancelAppointment() {
    this.$uibModal.open({
      component: 'confirm',
      size: 'sm',
      resolve: {
        title: () => 'Are you sure you want to cancel this appointment?',
        confirmText: () => 'Yes, cancel appointment.',
        cancelText: () => 'No, don\'t cancel.',
        dangerAction: () => true
      }
    })
      .result.then(() => {
        //Not a series, so cancel just that one appointment;
        this.myEventsService.cancelSingleAppointment(this.appointment).then(() => {
          this.$state.transitionTo('myEvents');
        });
    }, () => {
      //Cancelation modal dismissed
    });

  }

}

export default MyEventDetailsController;

import controller from './confirmation.controller';
import template from './confirmation.template.html';

const component = {
  controller,
  template,
  bindings: {
    completed: '<',
    org: '<',
    department: '<',
    config: '<',
    map: '<'
  }
};

export default component;
import angular from 'angular';
import component from './hoursSchedule.component';
import service from './hoursSchedule.service';
import uibCollapse from 'angular-ui-bootstrap/src/collapse';
import hourRange from './hourRange/hourRange';

let dependencies = [
  uibCollapse,
  hourRange
];

const hoursSchedule = angular
.module('connect.hoursSchedule', dependencies)
  .component('hoursSchedule', component)
  .service('hoursScheduleService', service)
  .name;

export default hoursSchedule;

import moment from 'moment';
import _ from 'lodash';

export default class campaignRegistrationService {
  constructor($q, Public, Patients, SessionManager, User, Access, FormsDomain, PatientsDomain){
    'ngInject';
    this.Public = Public;
    this.Patients = Patients;
    this.$q = $q;
    this.SessionManager = SessionManager;
    this.User = User;
    this.Access = Access;
    this.FormsDomain = FormsDomain;
    this.PatientsDomain = PatientsDomain;
  }

  findAndLinkExistingPatient(searchCriteria) {
    //requires orgId, firstname, lastname, last 4 of ssn
    return this.$q((resolve, reject) => {
      this.Public.findAndLinkExistingPatient(searchCriteria).then((patientID) => {
        this.Patients.getPatient(patientID.data[0]).then((patient) => {
          resolve(patient);
        }, error => reject(error));
      }, error => reject(error));
    });
  }

  getPatientSelfRoles(user) {
    let roles = new Array();

    angular.forEach(user.roles, (role) => {
      if(role.value.includes('PAT_') && role.value.includes('_SELF')) {
        let roleId = role.value.split('_');
        roles.push(roleId[1]);
      }
    });

    return roles;
  }

  matchOrgToPatientRecord(user, orgId) {
    return this.$q((resolve, reject) => {
      let roles = this.getPatientSelfRoles(user);
      this.Patients.getBulkPatients(roles).then((patientRecords) => {
        let recordFound = false;
        angular.forEach(patientRecords.data, (record) => {
          if (orgId === record.organizationId) {
            resolve(record);
          }
        });

        //Check for if the user has an account, but not a patient record
        if (!recordFound || patientRecords.data.length == 0) {
          reject('No records found');
        }
      });
    });
  }

  getPatientRecord(user, orgId) {
    return this.$q((resolve, reject) => {
      this.matchOrgToPatientRecord(user, orgId).then((matchedRecord) => {
        this.Patients.getPatientAttributes(matchedRecord.id).then((response) => {
          resolve({patient: matchedRecord, attributes: response.data.attributes});
        }, error => reject(error));
      }, error => reject(error));
    });
  }

  createPatientRecord(user, orgId, asRegistered, relationship) {
    return this.$q((resolve, reject) => {
      this.Patients.createPatient({
        firstName: user.firstName,
        lastName: angular.isDefined(user.lastName) ? user.lastName : 'N/A',
        email: angular.isDefined(user.email) ? user.email.toLowerCase() : angular.isDefined(user.emailAddress) ? user.emailAddress.toLowerCase() : undefined,
        organizationId: orgId,
        dob: (angular.isDefined(user.dob) && user.dob!=null) ? moment(user.dob).format('MM/DD/YYYY') : undefined,
        phoneNumber: user.phoneNumber,
        ssn: user.ssn,
        sex: user.gender,
        tosAgreements: user.tosAgreements
      }, user.wantsSMS, user.wantsEmails, user.wantsVoice).then((response) => {
        let patientData = response.data;
        if (asRegistered && relationship) {
          if(angular.isDefined(user.emailAddress)) patientData.email = user.emailAddress;
          this.linkConnectAccount(patientData, relationship).then(() => {
            resolve({user: user.data, patient: patientData});
          }, error => reject(error));
        } else if (asRegistered) {
          this.linkPatientRecord(patientData).then(() => {
            resolve({user: user.data, patient: patientData});
          })
        } else {
          resolve({user: user.data, patient: patientData});
        }
      }, error => reject(error));
    });
  }

  linkPatientRecord(patientData) {
    return this.Access.linkConnectAccountWithId(patientData.id, patientData.email);
  }

  linkConnectAccount(patientData, reason) {
    return this.Access.linkConnectAccount(patientData, patientData.email, reason);
  }

  setContactPreference(patientId, wantsSMS, wantsEmail, wantsVoice) {
    return this.$q((resolve, reject) => {
      let attributesMap = {};
      attributesMap['USER_WANTS_SMS'] = !!wantsSMS;
      attributesMap['USER_WANTS_VOICE'] = !!wantsVoice;
      attributesMap['USER_WANTS_EMAILS'] = !!wantsEmail;

      this.Patients.setPatientBulkAttributes(patientId, attributesMap).then((response) => {
        resolve(response);
      }, error => reject(error));
    });
  }

  getDocuments(appointmentTypeId, patientId, appointmentDate) {
    let forms = [];

      if (appointmentTypeId && patientId) {
        return this.$q((resolve, reject) => {
          // passing in appointmentDate breaks this call to the backend
          this.PatientsDomain.getPatientDocuments(patientId, appointmentTypeId, "Detail", undefined).then(response => {
            if(angular.isDefined(response.data.data.documents))
            {
              response.data.data.documents.forEach(f => {
                forms.push(f);
              });
            }
            else
            {
              forms = response.data.data.forms;
            }
            resolve(forms);
          }, error => reject(error));
        });
      }
      else
      {
        return this.$q((resolve, reject) => {
          if(appointmentTypeId)
          {
            this.FormsDomain.getFormsByAppointmentType(appointmentTypeId).then(response => {
              forms = response.data.data;
              resolve(forms.map(form => {
                // DEBT: there is something weird here which is requiring me to set fields here.
                //    But leave that rabbit hole for another time
                return {
                  formDefinition: form,
                  fields: form.fields,
                  formDefinitionId: form.id,
                  name: form.name,
                  id: null,
                  data: [],
                  required: !!form.required,
                  status: 'INCOMPLETE'
                }
              }));
            }, error => reject(error));
          }
          else
          {
            reject('No Appointment Type Id');
          }
        });
      }
    }
}

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './videoVisit.component';
import videoPreview from './videoPreview/videoPreview.component';
import videoControls from './patientVideoControls/controls.component';
import patientVideo from './patientVideo/jfhPatientVideo.component';

const dependencies = [uiRouter];

const videoVisit = angular
  .module('connect.VideoVisit', dependencies)
  .component('videoVisit', component)
  .component('videoPreview', videoPreview)
  .component('videoControls', videoControls)
  .component('jfhPatientVideo', patientVideo)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('video', {
        url: '/video/{appointmentId}',
        disableHeader: true,
        disableTimer: true,
        allowUnlogged: true,
        title: 'Video Visit',
        template: '<video-visit></video-visit>'
      });
  }])
  .name;

export default videoVisit;

import angular from 'angular';
import component from './documentsList.component';
import service from './documentsList.service';
import documentModal from './documentModal/documentModal';
import formRender from '@jellyfish-health/features/src/formRender/formRender.module';


const dependencies = [
  documentModal,
  formRender
];

const documentsList = angular
  .module('connect.documentsList', dependencies)
  .component('documentsList', component)
  .service('documentsListService', service)
  .name;

export default documentsList;

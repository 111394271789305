import angular from 'angular';
import component from './getInLineForm.component';
import body from './getInLineFormBody/getInLineFormBody';
import hoursSchedule from '~/shared/hoursSchedule/hoursSchedule';
import unitAddress from '~/shared/unitAddress/unitAddress';

const dependencies = [
  body,
  hoursSchedule,
  unitAddress
];

const getInLineForm = angular
  .module('connect.getInLine.getInLineForm', dependencies)
  .component('getInLineForm', component)
  .name;

export default getInLineForm;

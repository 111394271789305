class dobValidation {
  constructor() {
    'ngInject';
    this.restrict = 'A';
    this.require= 'ngModel'
  }
  link($scope, $element, attrs, ngModel) {
    function isLeapYear(year)
    {
      return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }
    function validateDOB(value) {
      var dayValid = false;
      var monthValid = false;
      var yearValid = false;
      var month = value.substring(0,2);
      var day = value.substring(3,5);
      var year = value.substring(6);
      if(month < 13 && month > 0) monthValid = true;
      if(year >= 1900) yearValid = true;
      switch (month){
        case "02":
          if(!isLeapYear(year) && parseInt(day) <= 28 && parseInt(day) ) dayValid = true;
          if(isLeapYear(year) && parseInt(day) <= 29 && parseInt(day) ) dayValid = true;
          break;
        case "01":
        case "03":
        case "05":
        case "07":
        case "08":
        case "10":
        case "12":
          if(parseInt(day) <= 31 && parseInt(day) > 0) dayValid = true;
          break;
        case "04":
        case "06":
        case "09":
        case "11":
          if(parseInt(day) <= 30 && parseInt(day) ) dayValid = true;
          break;
        default:
          dayValid = false;
          break;
      }
      ngModel.$setValidity('dob', (dayValid && monthValid && yearValid));
      return value;
    }
    ngModel.$parsers.push(validateDOB);
  }
}
export default () => new dobValidation();
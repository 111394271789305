import angular from 'angular';
import angularFilter from 'angular-filter';
import uiRouter from 'angular-ui-router';
import component from './myDocuments.component';
import service from './mydocuments.service';

const dependencies = [
  angularFilter,
  uiRouter,
  'commonLibrary',
  'commonFeatures'
];

const myDocuments = angular
  .module('connect.myDocuments', dependencies)
  .component('myDocumentsList', component)
  .service('myDocumentsService', service)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('myDocuments', {
        url: '/documents',
        showInHeader: true,
        title: 'My Documents',
        template: '<my-documents-list></my-documents-list>'
      });
  }])
  .name;

export default myDocuments;

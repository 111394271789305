class CommunicationController {
  constructor() {
    'ngInject';
  }

  save() {
    this.onCommunicationSave({$event:{sms: this.comm.enableSms,email: this.comm.enableEmail,voice: this.comm.enableVoice}});
  }
}

export default CommunicationController;
class CheckinController {
  constructor($stateParams, Access, Organization) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.Access = Access;
    this.Organization = Organization;
  }

  $onInit() {
    this.loading = false;
    this.successMessage = "";
    this.failureMessage = "";
    this.responseMessage = "";
    this.callSuccess = false;

    this.loading = true;
    this.Organization.getOrgUnitAttributes(this.$stateParams.orgUnitId).then((res) => {
      this.successMessage = res.data.attributesAsMap['ORGANIZATION_UNIT_CHECKIN_SUCCESS_MESSAGE'];
      this.failureMessage = res.data.attributesAsMap['ORGANIZATION_UNIT_CHECKIN_FAILURE_MESSAGE'];
      this.Access.checkInPublic(this.$stateParams.apptId).then((resp) => {
        this.callSuccess = resp.data.checkedin;
        this.responseMessage = resp.data.message;
        this.loading = false;
      }, (error) => {
        this.callSuccess = false;
        this.loading = false;
      })
    }, () => {
      this.callSuccess = false;
      this.loading = false;
    })
  }
}

export default CheckinController;

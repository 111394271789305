import angular from 'angular';

const configs = angular
  .module('commonLibrary.config', [])
  .constant('commonLibrary.config', {
    version: '7.4.9',
    app_token: '353b302c44574f565045687e534e7d6a:286924697e615a672a646a493545646c',
    google_api_key: 'AIzaSyC00gCrAVQrVCLG3weMxd1vQq7wCbDzLTo',
    environment: 'prod',
    profile: 'prodb',
    captcha: {
      public_key: '6LfrsCkTAAAAAMF7lHdPhrHMlovxlpa_oPpU3QLE'
    },
    backend_urls: {
      domain_service: 'https://domain-b.jellyfishhealth.com:443',
      token_service: 'https://user-b.jellyfishhealth.com:443',
      user_service: 'https://user-b.jellyfishhealth.com:443/services',
      patient_service: 'https://patient-b.jellyfishhealth.com:443/services',
      rules_service: 'https://rules-b.jellyfishhealth.com:443/services',
      organization_service: 'https://organization-b.jellyfishhealth.com:443/services',
      appointment_service: 'https://appointment-b.jellyfishhealth.com:443/services',
      flow_service: 'https://flow-b.jellyfishhealth.com:443/services',
      queue_service: 'https://queue-b.jellyfishhealth.com:443/services',
      notification_service: 'https://notification-b.jellyfishhealth.com:443/services',
      image_service: 'https://image-b.jellyfishhealth.com:443/services',
      survey_service: 'https://survey-b.jellyfishhealth.com:443/services',
      campaign_service: 'https://campaign-b.jellyfishhealth.com:443/services',
      population_service: 'https://campaign-b.jellyfishhealth.com:443/services',
      location_service: 'https://schedule-b.jellyfishhealth.com:443/services',
      schedule_service: 'https://schedule-b.jellyfishhealth.com:443/services',
      bloom_service: 'https://bloom-service-b.jellyfishhealth.com:443/services',
      form_service: 'https://edata-b.jellyfishhealth.com:443/services',
      access_service : 'https://access-service-b.jellyfishhealth.com:443/services',
      access_wss_service : 'wss://access-wss-service-b.jellyfishhealth.com:443/services',
      audit_service: 'https://audit-b.jellyfishhealth.com:443/services'
    },
    token_properties: {
      client_id: '353b302c44574f565045687e534e7d6a'
    },
    brand_style_directory : 'https://s3.amazonaws.com/jfhclients/prod',
    base_style_directory : '/',
    dev_orgId: '1d7206a8-e274-4246-8ea4-e383b8f585eb',
    dev_departmentId: '94bf864c-32f9-40c5-8b9e-38c7c9a8f093',
    instamed : {
      secure_token: {
        url : 'https://pay.instamed.com/',
        account : 'jellyfish.test@instamed.net'
      }
    },
    auth_net : {
      client_key: '3mm3a4PXykS5wGFg8AEN69wkX5npSHG333pZ5QSkGWjLneqhGJASbyYzA7mS26Kv',
      api_login_id: '63W9Avuvm'
    }
  })
  .name;

export default configs;

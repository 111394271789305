import controller from './getInLine.controller';
import template from './getInLine.template.html';

const component = {
  controller,
  template,
  bindings: {
    isRegistered: '&'
  }
};

export default component;
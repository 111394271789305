import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './login.component';

const login = angular
  .module('connect.Login', [uiRouter, 'commonLibrary'])
  .component('login', component)
  .config(['$httpProvider', '$stateProvider', ($httpProvider, $stateProvider) => {
    'ngInject';
    $httpProvider.interceptors.push('AuthInterceptor');
    $stateProvider.state('login', {
      url: '/login',
      disableHeader: true,
      allowUnlogged: true,
      onlyUnlogged: true,
      template: '<login></login>',
      params: {
        requested: null
      }
    });
  }])
  .run(($rootScope, $state, $location, $timeout, SessionManager) => {
    'ngInject';

    const savePreviousState = (e, state, params) => {
      if (!state.onlyUnlogged) {
        $rootScope.savedState = {
          state,
          params
        };
      }
    };

    const loginPermissionMiddleware = (e, toState) => {
      let current_user = SessionManager.getCurrentUser();

      // Redirect unlogged user in a logged route.
      if (!current_user && !toState.allowUnlogged) {
        e.preventDefault();
        $state.go('login', {}, {reload: true});
      }

      // Redirect Logged user in a unlogged route.
      else if (current_user && toState.onlyUnlogged) {
        e.preventDefault();
        $timeout(() => $location.path('/'));
      }
    };

    // Redirect Logged and Unlogged Users.
    let stateChangeStart = $rootScope.$on('$stateChangeStart',
      (e, toState, toParams, fromState, fromParams) => {

        if (fromState.name == 'claimPatient' && toState.name == 'login' && !toParams.requested) { // override login redirect on failure
          e.preventDefault();
        }
        savePreviousState(e, toState, toParams, fromState, fromParams);
        loginPermissionMiddleware(e, toState);
      });

    $rootScope.$on('$destroy', () => stateChangeStart());
  })
  .name;

export default login;

import angular from 'angular';
import component from './registerForm.component';
import service from './registerForm.service';
import uiMask from 'angular-ui-mask';
import uibDatePicker from 'angular-ui-bootstrap/src/datepicker';
import uibDatePickerPopup from 'angular-ui-bootstrap/src/datepickerPopup';
import documentsList from '../documentsList/documentsList';
// Temporary
import uiSelect from 'ui-select';
import ngSanizite from 'angular-sanitize';

const registerForm = angular
.module('connect.registerForm', [uiSelect, ngSanizite, uiMask, uibDatePicker, uibDatePickerPopup, documentsList,'commonFeatures'])
  .component('registerForm', component)
  .service('registerFormService', service)
  .name;

export default registerForm;

import angular from 'angular';
import component from './communication.component';


const communication = angular
  .module('connect.Account.Communication', [])
  .component('communication', component)
  .name;

export default communication;

import controller from './confirmModal.controller';
import template from './confirmModal.template.html';

const component = {
  controller,
  template,
  bindings: {
    modalInstance: '<',
    resolve: '<'
  }
};

export default component;

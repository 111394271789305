import controller from './patientIntakeProgress.controller';
import template from './patientIntakeProgress.template.html';

const component = {
  controller,
  template,
  bindings: {
    step: '<',
    total: '<',
    title: '<',
    radius: '<',
    progress: '<',
    stroke: '<'
  }
};

export default component;

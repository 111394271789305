export default () => (input) => {
  if (!input) {
    return 'No pricing information available';
  }
  else {
    let prices = '';
    input.forEach((priceObject, i) => {
      if(priceObject.noCharge) {
        prices += 'FREE';
      }

      else {
        prices += '$' + priceObject.price;
      }

      if(i < input.length - 1 && input.length > 1) {
        prices += ' / ';
      }
    });

    if (prices.length > 0) {
      return prices;
    }
    else return 'No pricing information available';
  }
};

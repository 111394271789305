export default class RootController {
  constructor(Auth, SSO, $window, $location, $q, Organization) {
    'ngInject';
    this.Auth = Auth;
    this.SSO = SSO;
    this.$window = $window;
    this.$location = $location;
    this.$q = $q;
    this.Organization = Organization;
  }

  /**
   * This is to support pass-through authentication to the backend. If it succeeds the user will be automatically logged in
   */
  $postLink() {
    let url = this.$window.location.search.substring(1);
    let deferred = this.$q.defer();

    let ssoToken;
    if (ssoToken = this.SSO.getSsoTokenFromUrl())
    {
      this.SSO.signInWithSsoToken(ssoToken)
    }
    else if (url && url.indexOf('&') !== -1) {
      this.Organization.getOrgIds().then((response) => {
        let queryData = {};
        url.split('&').forEach((query) => {
          if (query && query.indexOf('=') !== -1 && query.split('=').length == 2) {
            queryData[query.split('=')[0]] = query.split('=')[1];
          }
        });

        let orgId = response.orgId;
        let username = queryData['user'];
        let password = queryData['password'];
        url = url + '&orgId=' + orgId;
        this.Auth.oauthService(username, password, false, url).then((response) => {
          this.Auth.finishLogin(response.data, orgId, undefined, deferred);
        });
      });

    }
  }
}

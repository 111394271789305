import angular from 'angular';
import component from './simpleDocumentList.component';
import simpleDocumentModal from './simpleDocumentModal/simpleDocumentModal';
import formRender from '@jellyfish-health/features/src/formRender/formRender.module';


const dependencies = [
  simpleDocumentModal,
  formRender
];

// This is a version of the documentsList that does not have to fetch form data by id
// It just gets passed down

const simpleDocumentList = angular
  .module('connect.simpleDocumentList', dependencies)
  .component('simpleDocumentList', component)
  .name;

export default simpleDocumentList;

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './account.component';
import personalInformation from './personalInformation/personalInformation';
import communication from './communication/communication';
import changePassword from './changePassword/changePassword';
import deleteAccount from './deleteAccount/deleteAccount';
import disconnect from './disconnect/disconnect';

const dependencies = [
  uiRouter,
  'commonLibrary',
  'commonFeatures',
  personalInformation,
  communication,
  changePassword,
  deleteAccount,
  disconnect
];

const account = angular
  .module('connect.Account', dependencies)
  .component('account', component)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('account', {
        url: '/account',
        showInHeader: false,
        title: 'Account Settings',
        template: '<account></account>'
      });
  }])
  .name;

export default account;

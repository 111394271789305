class SignUpSuccessController {
  constructor($state, $stateParams, User, Auth, flash, $location) {
    'ngInject';
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.User = User;
    this.Auth = Auth;
    this.flash = flash;
    this.$location = $location;
  }

  $onInit() {
    const patientDirectoryId = '0000000001';
    this.email = this.$stateParams.email;
    this.password = this.$stateParams.password;
    this.User.getPasswordPolicy(patientDirectoryId).then(resp => {
      this.directory = resp.data;
    });
  }

  doLogin() {
    this.processing = true;
    this.Auth.login(this.email, this.password)
      .then(() => {
        this.flash.instantMessage(`Welcome back, ${this.email}!`, 'info');
        this.processing = false;
        this.$location.path('/');
      }, (err) => {
        this.processing = false;
        this.flash.instantMessage(err.data, 'danger');
      });
  }

}

export default SignUpSuccessController;

import angular from 'angular';
import uiRouter from 'angular-ui-router';
import component from './questionnaire.component';
import service from './questionnaire.service';

const dependencies = [
  uiRouter
];

const questionnaire = angular
  .module('connect.Questionnaire', dependencies)
  .component('questionnaire', component)
  .service('questionnaireService',service)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('questionnaire', {
        url: '/questionnaire?pid&gid&d',
        title: 'Questionnaire',
        template: '<questionnaire></questionnaire>',
        allowUnlogged: true,
        disableHeader: true
      });
  })
  .name;

export default questionnaire;

class QuestionnaireController {
  constructor(questionnaireService, selfSchedulingService, $stateParams, $state){
    'ngInject';
    this.questionnaireService = questionnaireService;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.selfSchedulingService = selfSchedulingService;
  }

  $onInit(){
    this.model = {};
    this.message = {};
    this.nodeStack = [];
    this.setFormConfig();
    this.selfSchedulingService.getPopulationId(this.$stateParams);
  }

  setFormConfig() {
    if(this.$stateParams.gid) { // gid = get in line dept id
      this.questionnaireService.getGetInLineFormConfig(this.$stateParams.gid).then((config) => {
        if(config.enabled){
          this.setupForm(config.questionnaireId);
        } else {
          this.$state.go('getInLine', {answers: this.model, d: this.$state.params.d});
        }
      });
    } else {
      this.questionnaireService.getFormConfigForOrg().then((config) => {
        if(config.enabled){
          this.setupForm(config.questionnaireId);
        } else {
          this.$state.go('login');
        }
      });
    }
  }

  setupForm(formId){
    this.questionnaireService.getFormInformation(formId).then((formData) => {
      if(angular.isDefined(formData.form.type) && formData.form.type == 'DYNAMIC'){
        this.setupDynamicForm(formData);
      } else {
        this.setupStandardForm(formData);
      }
    });
  }

  setupDynamicForm(formData){
    this.dynamicForm = formData.form;
    this.nodeRequests = formData.nodes;
    this.fieldRequests = formData.fields;
    this.currentNode = this.questionnaireService.findNode(this.dynamicForm.initialNode, this.nodeRequests);
    this.renderNode(this.currentNode);
  }

  setupStandardForm(formData){
    let tempFields = [];
    formData.fields.forEach(f=>{
      let t = f.field;
      delete t.createdOn;
      tempFields.push(t);
    });
    this.formType = 'STANDARD';
    this.formFields = tempFields;
  }

  deleteCurrentAnswers(node){
    node.paths.forEach(path=>{
      path.conditions.forEach(cond=>{
        let fieldId = cond.formFieldId;
        delete this.model[fieldId];
      });
    });
  }

  onBack(){
    this.deleteCurrentAnswers(this.currentNode);
    if(this.nodeStack.length>0){
      this.currentNode = this.nodeStack.pop();
    }
    this.renderNode(this.currentNode);
  }

  onNext(invalid){
    if(angular.isUndefined(invalid) || !invalid) {
      if (this.formType == 'STANDARD') {
        this.nextDestination();
      } else {
        switch (this.currentNode.type) {
        case 'QUESTION':
          this.choosePath();
          break;
        }
      }
    }
  }

  transformAnswer(answer){
    let newAnswer;
    if(angular.isString(answer)){
      newAnswer = answer.toLowerCase();
    } else if(angular.isArray(answer)) {
      newAnswer = [];
      answer.forEach(ans => {
        newAnswer.push(ans.toLowerCase());
      });
    } else {
      newAnswer = answer;
    }
    return newAnswer;
  }

  choosePath(){
    let conditionMatch = false;
    this.currentNode.paths.forEach(path => {
      /* logically
         conditions are &&
         paths are ||
      */
      let conditionMatchCount = 0;
      if(!conditionMatch){
        path.conditions.forEach(condition => {
          let patientAnswer = this.transformAnswer(this.model[condition.formFieldId]);
          let conditionAnswer = this.transformAnswer(condition.value);
          switch(condition.predicate){
          case 'EQUAL': {
            if(angular.isArray(patientAnswer)){
              if(this.contains(conditionAnswer, patientAnswer)){
                ++conditionMatchCount;
              }
            } else {
              if(patientAnswer == conditionAnswer){
                ++conditionMatchCount;
              }
            }
            break;
          }
          case 'GREATER_THAN': {
            if(patientAnswer > conditionAnswer){
              ++conditionMatchCount;
            }
            break;
          }
          case 'LESS_THAN': {
            if(patientAnswer < conditionAnswer){
              ++conditionMatchCount;
            }
            break;
          }
          case 'GREATER_THAN_EQUAL': {
            if(patientAnswer >= conditionAnswer){
              ++conditionMatchCount;
            }
            break;
          }
          case 'LESS_THAN_EQUAL': {
            if(patientAnswer <= conditionAnswer){
              ++conditionMatchCount;
            }
            break;
          }
          case 'CONTAINS': {
            if(this.contains(conditionAnswer, patientAnswer)){
              ++conditionMatchCount;
            }
            break;
          }
          }
        });
        // check if all conditions were met
        if(path.conditions.length == conditionMatchCount){
          this.nodeStack.push(this.currentNode);
          this.currentNode = this.questionnaireService.findNode(path.sendTo, this.nodeRequests);
          this.renderNode(this.currentNode);
          conditionMatch = true;
        }
      }
    });
    if(!conditionMatch){
      this.currentNode = this.questionnaireService.findNode(this.dynamicForm.defaultNode, this.nodeRequests);
      this.renderNode(this.currentNode);
    }
  }

  contains(conditionAnswer, patientAnswers){
    let contains = false;
    patientAnswers.forEach((ans) => {
      if(ans == conditionAnswer){
        contains = true;
      }
    });
    return contains;
  }

  renderNode(node) {
    switch (node.type) {
    case 'QUESTION':
      this.formFields = this.questionnaireService.getFieldsFromPaths(node, this.fieldRequests);
      break;
    case 'RESPONSE_MESSAGE':
      this.formFields = [];
      this.message = node.message;
      break;
    case 'RESPONSE_SEARCH':
      this.nextDestination();
      break;
    }
  }

  nextDestination(){
    if(this.$stateParams.gid) {
      this.$state.go('getInLine', {answers: this.model, d: this.$state.params.d});
    } else {
      this.$state.go('selfScheduling', {answers: this.model});
    }
  }

}

export default QuestionnaireController;

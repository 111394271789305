import template from './disconnect.template.html';
import controller from './disconnect.controller';

let component = {
  controller,
  template,
  bindings: {
    parent: '<',
    onParentRemove: '&'
  }
};

export default component;

import controller from './cardCollectionModal.controller';
import template from './cardCollectionModal.template.html';

const component = {
  controller,
  template,
  bindings: {
    modalInstance: '<',
    resolve: '<',
    close: '&'
  }
};

export default component;

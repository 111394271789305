class campaignLocationsModalController {
  constructor($stateParams) {
    'ngInject';
    this.$stateParams = $stateParams;
  }

  $onInit() {
    this.name = this.resolve.name;
    this.imageUrl = this.resolve.imageUrl;
    this.locations = this.resolve.locations;
    this.hours = this.resolve.hours;
    this.selectedLocation = this.$stateParams.locationId;
  }

  done () {
    this.modalInstance.close(this.selectedLocation);
  }

  cancel () {
    this.modalInstance.dismiss();
  }

}



export default campaignLocationsModalController;

export default class RegisterFormService {
  constructor($q, AccessRegistration, ConfigManager, $timeout) {
    'ngInject';
    this.$q = $q;
    this.AccessRegistration = AccessRegistration;
    this.ConfigManager = ConfigManager;
    this.$timeout = $timeout;


    this.resetErrors();
  }

  resetErrors() {
    this.errs = {
      registerForm: false,
      reqDocuments: false,
      tos: false,
      email: false
    };
  }

  hideError(err) {
    this.errs[err] = false;
  }

  showError(err) {
    this.errs[err] = true;
  }

  getCaptchaKey() {
    return this.ConfigManager.getByKey('captcha').public_key;
  }

  getConfig(currentOrg, currentOrgUnit) {
    return this.$q((resolve, reject) => {
      if (angular.isDefined(currentOrg) && angular.isDefined(currentOrgUnit)) {
        this.AccessRegistration.getRegistrationConfig(currentOrg, currentOrgUnit)
          .then(response => {
            resolve({
              registration: response.data,
              captcha_key: this.getCaptchaKey()
            });
          }, reject);
      } else {
        //This should get replaced with an organization default
        resolve({
          registration: this.defaultFields(),
          captcha_key: this.getCaptchaKey()
        }, reject);
      }
    });
  }

  defaultFields() {
    //This should get replaced with an organization default
    return {
      guest: [
        {
          required: true,
          typeId: '0',
          size: '12',
          title: 'First Name',
          placeholder: null,
          instructions: ''
        }, {
          required: true,
          typeId: '1',
          size: '12',
          title: 'Last Name',
          placeholder: null,
          instructions: ''
        }],
      newAccount: [
        {
          required: true,
          typeId: '0',
          size: '12',
          title: 'First Name',
          placeholder: null,
          instructions: ''
        }, {
          required: true,
          typeId: '1',
          size: '12',
          title: 'Last Name',
          placeholder: null,
          instructions: ''
        }, {
          required: false,
          typeId: '4',
          size: '12',
          title: 'Phone Number',
          placeholder: null,
          instructions: ''
        }]
    };
  }
}

class ArrivedController {
  constructor($stateParams, Access, Organization) {
    'ngInject';
    this.$stateParams = $stateParams;
    this.Access = Access;
    this.Organization = Organization;
  }

  $onInit() {
    this.loading = false;
    this.successMessage = "";
    this.failureMessage = "";
    this.callSuccess;

    this.loading = true;
    this.Organization.getOrgUnitAttributes(this.$stateParams.orgUnitId).then((res) => {
      this.successMessage = res.data.attributesAsMap['ORGANIZATION_UNIT_ARRIVED_SUCCESS_MESSAGE'];
      this.failureMessage = res.data.attributesAsMap['ORGANIZATION_UNIT_ARRIVED_FAILURE_MESSAGE'];
      this.Access.patientArrived(this.$stateParams.apptId).then(() => {
        this.callSuccess = true;
        this.loading = false;
      }, () => {
        this.callSuccess = false;
        this.loading = false;
      })
    }, () => {
      this.callSuccess = false;
      this.loading = false;
    })
  }
}

export default ArrivedController;

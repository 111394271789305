import moment from 'moment';

export default class campaignRegistrationController {
  constructor($q, $log, $timeout, Auth, selfSchedulingService, campaignRegistrationService, $state, $stateParams, SessionManager, User, Patients, $uibModal, flash, Schedule, Access, $location, $anchorScroll, registerFormService, FormsDomain) {
    'ngInject';
    this.$q = $q;
    this.$log = $log;
    this.$timeout = $timeout;
    this.Auth = Auth;
    this.selfSchedulingService = selfSchedulingService;
    this.campaignRegistrationService = campaignRegistrationService;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.SessionManager = SessionManager;
    this.User = User;
    this.Patients = Patients;
    this.$uibModal = $uibModal;
    this.flash = flash;
    this.Schedule = Schedule;
    this.Access = Access;
    this.$location = $location;
    this.$anchorScroll = $anchorScroll;
    this.registerFormService = registerFormService;
    this.formsDomain = FormsDomain;
  }

  $onInit() {
    //Get slot id first then build the page from the response
    this.displayedErrors = {
      length: 0,
      errors: {}
    };
    this.areDocumentsValid = true;
    this.loading = true;
    this.user = this.SessionManager.getCurrentUser();
    this.setAppToFailed = false;
    this.found = false;
    this.verifyFields = false;
    this.propogatedDocs = [];
    this.cohorts = [];
    this.promiseArray = [];
    this.hasCohorts = false;
    this.isOther = false;
    this.cohortName = 'Myself';

    this.noSlotMessage = {
      show: false,
      text: 'The appointment time selection you made is no longer available.'
    };

    this.selfSchedulingService
      .getCampaignDetails(this.$stateParams.campaignId, null, this.$stateParams.appointmentTypeId)
      .then(response => (this.campaignData = response.data))
      .then(() => this.selfSchedulingService.getSlotHold(this.$stateParams.slotId))
      .then(response => {
        return this.slotResponse = response;
      })
      .then(() => {
        return this.selfSchedulingService.getExtraSchedulingConfigs(
          this.slotResponse.data.slot.slotTimeDuration.location.organizationId,
          this.slotResponse.data.slot.slotTimeDuration.location.organizationalUnitId,
          this.campaignData.userAttributes.attributes
        );
      })
      .then(extraConfigs => {
        this.orgId = extraConfigs.orgUnit.organizationId;
        this.deptId = extraConfigs.orgUnit.id;
        this.registrationConfigs = extraConfigs;

        if (this.slotResponse.status === 204) {
          //if a slot was not returned then show an error
          this.loading = false;
          this.noSlotMessage.show = true;
          this.slotData = null;
        } else {
          this.slotData = angular.copy(this.slotResponse.data);
          this.selectedSchedule = this.getSelectedSchedule(
            this.slotData.slot.scheduleId
          );
        }

        this.campaignData.campaign.appointmentTypeId = this.$stateParams.appointmentTypeId;

        //Find the image url in the attributes and attach it to the outer campaignData.
        let imageAttribute = this.campaignData.userAttributes.attributes.find(
          attribute => attribute["key"] === "LOGO_URL"
        );
        if (angular.isDefined(imageAttribute)) {
          this.campaignData.imageUrl = imageAttribute["value"];
        }

        this.loading = false;
        //if the user is logged in, get their patient record
        if (this.user) {
          //get matching patient for this org
          //then set communication preference from patient
          this.loading = true;
          // brand new connect account does not have patient record
          this.campaignRegistrationService
            .getPatientRecord(this.user, this.orgId).then(result => {
              //matching record found
              this.userPatientData = this.getUserPatient(this.user, result.patient);
              if(angular.isDefined(this.userPatientData.id)) {
                this.found = true;
                this.verifyFields = true;
                this.userHasPatient = true;
              } else {
                this.userHasPatient = false;
              }
              this.patientData = angular.copy(this.userPatientData);
              this.setupCohorts();
              if (
                this.registrationConfigs.enable_forms &&
                angular.isDefined(this.selectedSchedule) &&
                angular.isDefined(this.selectedSchedule.appointmentTypeId) &&
                this.selectedSchedule.appointmentTypeId !== null
              ) {
                this.loading = true;
                this.getDocsById(this.selectedSchedule.appointmentTypeId, this.patientData.id, moment(this.slotData.slot.slotTimeDuration.day).format().valueOf());
              }
              this.formData = {};

              this.selfSchedulingService
                .getAttributeByKey("USER_WANTS_SMS", result.attributes)
                .then(
                  result =>
                    (this.formData.wantsSMS = angular.fromJson(result))
                );
              this.selfSchedulingService
                .getAttributeByKey("USER_WANTS_EMAILS", result.attributes)
                .then(
                  result =>
                    (this.formData.wantsEmails = angular.fromJson(result))
                );
              this.selfSchedulingService
                .getAttributeByKey("USER_WANTS_VOICE", result.attributes)
                .then(
                  result =>
                    (this.formData.wantsVoice = angular.fromJson(result))
                );

              this.loading = false;
            },
            () => {
              //No Record Found, so get by appt id only
              if(angular.isDefined(this.$stateParams.appointmentTypeId) && this.$stateParams.appointmentTypeId!=null)
                this.setDocumentsByAppointmentTypeId();
              this.loading = false;
              // because there is not a matching record the patientData needs to be set to the current user
              this.patientData = angular.copy(this.user);
              this.patientData.email = angular.copy(this.user.emailAddress);

              this.setupCohorts();

              // the fields need to be locked because the account has already been created
              // this.verifyFields = true;
            });
        }
        else
        {
          if(angular.isDefined(this.$stateParams.appointmentTypeId) && this.$stateParams.appointmentTypeId!=null)
            this.setDocumentsByAppointmentTypeId();
          this.formModel = {relationship: {key: 'SELF', value: 'I am the patient'}};
        }
      })
      .catch(err => {
        this.$log.error(err);
        this.loading = false;
      });
  }

  setupCohorts() {
    if (this.hasCohortRoles(this.user.roles)) {
      this.hasCohorts = true;
      this.patientIds = this.getCohortIds(this.user.roles);
      if (angular.isDefined(this.patientIds)) {
        // takes the patient ids from the roles on the user and gets all the patient info
        angular.forEach(this.patientIds, id => {
          this.promiseArray.push(this.Patients.getPatient(id));
        });
        this.$q.all(this.promiseArray).then(res => {
          angular.forEach(res, i => {
            this.cohorts.push(i.data);
          });
          this.cohorts = this.cohorts.filter(cohort => {
            if (cohort.id !== this.patientData.id) {
              return cohort;
            } else {
              this.cohortName = 'Myself';
              this.formModel = cohort;
            }
          });
        })
      }
    }
  }

  getUserPatient(user, patient) {
    let returnPatient = {};
    user.roles.forEach(role => {
      if(role.value.includes('_SELF') &&role.value.split('_')[1] == patient.id) returnPatient = angular.copy(patient);
    });
    if(angular.isUndefined(returnPatient.id)) {
      returnPatient = this.createPatientFromUser(user);
    }
    return returnPatient;
  }

  hasCohortRoles(roles){
    let foundCohort = false;
    roles.forEach(role => {
      if(role.value.includes('PAT_') && !role.value.includes('_SELF')) foundCohort = true;
    });
    return foundCohort;
  }

  getDocsById(apptTypeId, patientId, appointmentDate) {
    this.campaignRegistrationService
      .getDocuments(apptTypeId, patientId, appointmentDate)
      .then(
        response => {
          this.documents = response;
          this.documents.forEach(doc => {
            doc.fields.forEach(field => { delete field.key})
          })
          this.loading = false;
        },
        err => {
          this.$log.error(err);
          this.loading = false;
        }
      );
  }

  getCohortIds(roles) {
    let ids = [];
    angular.forEach(roles, (role) => {
      if(role.value.includes('PAT_') && !role.value.includes('_SELF')) {
        let roleId = role.value.split('_');
        ids.push(roleId[1]);
      }
    });
    return ids;
  }

  selectCohort(cohort) {
    switch (cohort) {
      case 'OTHER':

        if(angular.isUndefined(this.patientData)) this.patientData = angular.copy(this.formModel);

        this.patientData.id = undefined;
        this.patientData.firstName = undefined;
        this.patientData.lastName = undefined;
        this.patientData.dob = undefined;
        this.patientData.gender = undefined;
        this.patientData.location = undefined;
        this.patientData.phoneNumber = undefined;
        this.patientData.ssn = undefined;

        if (this.user) {
          this.patientData.email = this.user.emailAddress;
        }
        this.cohortName = 'Add New Relationship';
        this.isOther = true;
        this.verifyFields = false;
        this.asRegistered = true;
        this.found = false;
        break;
      case 'SELF':
        this.cohortName = 'Myself';
        this.isOther = false;
        if (this.asRegistered && angular.isUndefined(this.formModel) && angular.isDefined(this.user) && this.user!=null) {
          // new account without patientInfo tied
          this.verifyFields = true;
          this.patientData = this.createPatientFromUser(this.user);
        } else if (this.asRegistered && angular.isDefined(this.formModel)) {
          // new account with patientInfo tied
          if(!this.userHasPatient) {
            this.found = false;
            this.verifyFields = false;
            this.patientData = this.createPatientFromUser(this.user);
          } else {
            this.found = true;
            this.verifyFields = true;
            this.patientData = angular.copy(this.userPatientData);
          }
        }
        if( angular.isUndefined(this.patientData) || this.patientData == null )
        {
          this.patientData = angular.copy(this.formModel);
          // in the case that you have scheduled for cohort before yourself
          this.patientData.dob = undefined;
        }
        this.patientData.relationship = {key: 'SELF', value: 'I am the patient'};
        break;
      default:
        this.found = true;
        this.verifyFields = true;
        this.isOther = false;
        this.patientData = angular.copy(cohort);
        this.cohortName = `${this.patientData.firstName} ${this.patientData.lastName}`;
        break;
    }

    if (angular.isDefined(this.patientData)) {
      this.getDocsById(this.selectedSchedule.appointmentTypeId, this.patientData.id);
    } else {
      this.getDocsById(this.selectedSchedule.appointmentTypeId);
    }
    // patientId is optional
  }

  createPatientFromUser(user){
    let patient = {};
    patient.firstName = user.firstName;
    patient.lastName = user.lastName;
    if(user.dateOfBirth) patient.dob = new Date(user.dateOfBirth).toLocaleDateString("en-US");
    patient.email = user.emailAddress;
    patient.phoneNumber = user.phoneNumber;
    patient.sex = user.gender;
    return patient;
  }


  setDocumentsByAppointmentTypeId() {
    //if there is an appointment type id then check for documents
    if (this.registrationConfigs.enable_forms &&
      angular.isDefined(this.selectedSchedule) &&
      angular.isDefined(this.selectedSchedule.appointmentTypeId) &&
      this.selectedSchedule.appointmentTypeId !== null) {
      this.loading = true;
      this.campaignRegistrationService
        .getDocuments(this.selectedSchedule.appointmentTypeId)
        .then(
          response => {
            this.documents = response;
            this.documents.forEach(doc => {
              doc.fields.forEach(field => { delete field.key})
            })
            this.loading = false;
          },
          err => {
            this.$log.error(err);
            this.loading = false;
          }
        );
    }
  }

  getSelectedSchedule(scheduleId) {
    let returnValue = undefined;

    angular.forEach(this.campaignData.schedules, (schedule) => {
      if (schedule.id === scheduleId) {
        returnValue = schedule;
      }
    });
    return returnValue;
  }

  signOut() {
    this.SessionManager.removeSession();
  }

  formValid($event) {
    //The digest doesn't run like expected because of the timer on this page, so this needs the $timeout function to run it when the form is valid.
    this.$timeout(() => {
      this.formModel = $event.formData;
      this.formModel.$valid = $event.isValid;
      this.registrationValidity = $event.regValidity;
    }, 0);
  }

  documentsValid($event) {
    if ($event != null) {
      //The digest doesn't run like expected because of the timer on this page, so this needs the $timeout function to run it when the form is valid.
      this.$timeout(() => {
        this.documents = $event.documents;
        this.areDocumentsValid = $event.isValid;
      }, 0);
    }
  }

  getRegisterFormConfig($event) {
    this.formConfigs = $event;
  }

  showSchedulingError() {
    this.loading = false;
    this.processing = false;

    // Registration form
    if (!this.formModel.$valid && angular.isDefined(this.registrationValidity)) {
      if (this.asRegistered && !this.registrationValidity.newAccountValid) {
        this.displayedErrors.errors['registerForm'] = 'Complete the required registration information.';
      } else if (!this.asRegistered && !this.registrationValidity.guestValid) {
        this.displayedErrors.errors['registerForm'] = 'Complete the required registration information.';
      }
    }

    // Required documents
    if (this.asRegistered && !this.areDocumentsValid) {
      this.displayedErrors.errors['requiredDocuments'] = 'Complete the required documents.';
    }
    // TOS
    if (!(angular.isDefined(this.formModel.tos) && this.formModel.tos) || angular.isUndefined(this.formModel.tos) || this.formModel.tos == null) {
      this.displayedErrors.errors['tos'] = 'Accept the Terms of Service.';
    }
    // Captcha
    if (!this.registerFormService.hideCaptcha && !this.user && this.formConfigs.captcha_key) {
      if (!(grecaptcha.getResponse().length > 0)) {
        this.displayedErrors.errors['captcha'] = 'Complete the reCAPTCHA.';
      }
    }

    this.displayedErrors.length = Object.keys(this.displayedErrors.errors).length;
  }

  register() {
    //REGISTERS USER IF NOT DONE SO ALREADY
    this.processing = true;
    this.displayedErrors = {
      length: 0,
      errors: {}
    };

    let showError = (this.asRegistered && !this.areDocumentsValid)
      || !(angular.isDefined(this.formModel.tos) && this.formModel.tos)
      || angular.isUndefined(this.formModel.tos)
      || this.formModel.tos == null
      || !this.formModel.$valid;

    if (!this.registerFormService.hideCaptcha && !this.user) {
      showError = showError || !(grecaptcha.getResponse().length > 0);
    }


    //checks if the user is choosing to create an account or guest register
    if (showError) {
      this.showSchedulingError();
    } else {
      if (this.asRegistered) {
        if (!this.user) {
          if(angular.isUndefined(this.formModel.firstName)) this.formModel.firstName = this.formModel.userFirstName;
          if(angular.isUndefined(this.formModel.lastName)) this.formModel.lastName = this.formModel.userLastName;
          this.User.signUp({
            user: {
              emailAddress: this.formModel.email,
              phoneNumber: this.formModel.phoneNumber,
              firstName: this.formModel.userFirstName,
              lastName: this.formModel.userLastName,
              dateOfBirth: this.formModel.relationship.key === 'SELF' ? this.formModel.dob : undefined,
              tosAgreements: this.formModel.tosAgreements
            },
            password: {
              password: this.formModel.password
            }
          }).then(() => {
            this.Auth.login(this.formModel.email, this.formModel.password).then(() => {
              this.user = this.SessionManager.getCurrentUser();
              this.findAndLink();
            }, error => {
              this.processing = false;
              this.error = error.data;
            });
          }, error => {
            this.processing = false;
            if (error.status == 409) {
              this.displayedErrors.errors['email'] = 'The email address you entered is already in use.';
              this.showSchedulingError();
            } else {
              this.slotConflict = false;
              this.error = error.data;
            }
          });
        }
        else
        {
          this.findAndLink();
        }
      } else {
        this.campaignRegistrationService.createPatientRecord(this.formModel, this.orgId, this.asRegistered, this.formModel.relationship.key).then((response) => {
          this.patientData = response.patient;
          this.processing = false;
          this.openRegisterModal();
        }, error => {
          this.$log.error(error);
          this.processing = false;
          this.error = this.registrationConfigs.errorResponseText;
        });
      }
    }
  }

  findAndLink() {
    //SEARCHES FOR EXISTING PATIENT DATA AND LINKS IF IT EXISTS
    if (this.found) {
      this.campaignRegistrationService.setContactPreference(this.patientData.id, this.formModel.wantsSMS, this.formModel.wantsEmails, this.formModel.wantsVoice).then(() => {
        this.User.getCurrentUser().then((user) => {
          this.SessionManager.updateCurrentUser(user.data);
          this.processing = false;
          this.openRegisterModal();
        }, () => {
          this.processing = false;
          this.error = this.registrationConfigs.errorResponseText;
        });
      }, error => {
        this.$log.error(error);
        this.processing = false;
        this.error = this.registrationConfigs.errorResponseText;
      });
    } else {
      let searchCriteria = {
        organizationId: this.orgId,
        firstName: this.formModel.firstName,
        lastName: this.formModel.lastName,
        dob: (angular.isDefined(this.formModel.dob) && this.formModel.dob != null) ? moment(this.formModel.dob).format('MM/DD/YYYY') : undefined,
        ssn: this.formModel.ssn
      };
      this.campaignRegistrationService.findAndLinkExistingPatient(searchCriteria).then((patient) => {
        this.patientData = patient.data;

        this.campaignRegistrationService.setContactPreference(this.patientData.id, this.formModel.wantsSMS, this.formModel.wantsEmails, this.formModel.wantsVoice).then(() => {
          this.User.getCurrentUser().then((user) => {
            this.SessionManager.updateCurrentUser(user.data);
            this.processing = false;
            this.openRegisterModal();
          }, () => {
            this.processing = false;
            this.error = this.registrationConfigs.errorResponseText;
          });
        }, error => {
          this.$log.error(error);
          this.processing = false;
          this.error = this.registrationConfigs.errorResponseText;
        });
      }, error => {
        this.processing = false;
        //show error if multiple patient records found - status 500
        if (error.status === 500 && this.registrationConfigs.only_self_schedule_existing_patients) {
          if (this.registrationConfigs.self_schedule_on_failed_search) {
            this.processing = true;
            this.setAppToFailed = true;
            //create patient record
            this.campaignRegistrationService.createPatientRecord(this.formModel, this.orgId, this.asRegistered, this.formModel.relationship.key)
              .then(response => {
                this.patientData = response.patient;
                return this.User.getCurrentUser();
              }).then(user => {
              this.SessionManager.updateCurrentUser(user.data);
              this.processing = false;
              this.openRegisterModal();
            }).catch(error => {
              this.$log.error(error);
              this.processing = false;
              this.error = this.registrationConfigs.errorResponseText;
            });
          } else {
            this.error = this.registrationConfigs.errorResponseText;
          }
        } else if (!this.registrationConfigs.only_self_schedule_existing_patients) {
          this.processing = true;
          //create patient record
          this.campaignRegistrationService.createPatientRecord(this.formModel, this.orgId, this.asRegistered, this.formModel.relationship.key)
            .then(response => {
              this.patientData = response.patient;
              return this.User.getCurrentUser();
            }).then(user => {
            this.SessionManager.updateCurrentUser(user.data);
            this.processing = false;
            this.openRegisterModal();
          }).catch(error => {
            this.$log.error(error);
            this.processing = false;
            this.error = this.registrationConfigs.errorResponseText;
          });
          //schedule
        }
        //show error if only allowing existing patients - status 404
        if (error.status === 404) {
          this.error = this.registrationConfigs.errorResponseText;
        }

      });
    }
  }

  saveDocuments(event) {
    if( angular.isDefined(event.doc.id) && event.doc.id != null  )  //exists
    {
      //save the doc
      let saveForm = {data: event.doc, formModel: event.doc.data};
      return this.formsDomain.submitForm(saveForm, false).then(res2 => {
        angular.forEach(this.documents, (doc, idx, docs) => {
          if (doc.id === res2.data.data.id) {
            docs[idx].version = res2.data.data.version;
            docs[idx].data = res2.data.formModel;
          }
        });
      });
    } else {
      this.propogatedDocs.push(event.doc);
    }
  }

  openRegisterModal() {
    let modalInstance = this.$uibModal.open({
      component: 'progressModal',
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
      resolve: {
        progressModalTitle: () => 'Just a moment, we\'re scheduling <br />your appointment.',
        scheduleAppt: () => true,
        campaignData: () => this.campaignData,
        slotData: () => this.slotData,
        userData: () => this.user,
        patientData: () => this.patientData,
        registrationConfigs: () => this.registrationConfigs,
        setApptToFailed: () => this.setAppToFailed,
        asRegistered: () => this.asRegistered,
        unsavedDocuments: () => this.propogatedDocs,
        allDocuments: () => this.documents,
        appointmentTypeId: () => this.selectedSchedule.appointmentTypeId
      }
    });
    modalInstance.result.then((result) => {
      this.processing = false;
      if (result.status === 200) {
        //go to my events if registered otherwise go to guest confirmation
        if (this.asRegistered) {
          this.$state.go('myEventDetails', {
            eventId: this.campaignData.campaign.id,
            appointmentId: result.data.id,
            flashMessage: 'Success! Your appointment has been scheduled.'
          }, {location: 'replace'});
        } else {
          this.$state.go('guestConfirmation', {
            campaign: this.campaignData,
            slot: this.slotData,
            appointment: result.data
          }, {location: 'replace'});
        }
      } else if (result.status === 409) {
        this.slotConflict = true;
        this.error = result.data;
      } else {
        this.slotConflict = false;
        this.error = result.data;
      }
      this.selfSchedulingService.releaseSlotHold(this.$stateParams.slotId);
    }, (error) => {
      this.error = error;
    });
  }

  onTimeout() {
    this.slotData = null;
    this.noSlotMessage.show = true;
  }

  onCloseAlerts() {
    this.displayedErrors = {
      length: 0,
      errors: {}
    };
  }

  cancelRegistration() {
    this.selfSchedulingService.releaseSlotHold(this.$stateParams.slotId).then(() => {
      if(this.slotData) {
        this.$state.go('selfSchedulingDetails', {
          campaignId: this.$stateParams.campaignId,
          locationId: this.slotData.slot.slotTimeDuration.location.id,
          appointmentTypeId: this.$stateParams.appointmentTypeId
        });
      }
      else {
        this.$state.go('selfScheduling', {
        });
      }
    }, error => {
      if(this.slotData) {
        this.$state.go('selfSchedulingDetails', {
          campaignId: this.$stateParams.campaignId,
          locationId: this.slotData.slot.slotTimeDuration.location.id,
          appointmentTypeId: this.$stateParams.appointmentTypeId
        });
      }
      else {
        this.$state.go('selfScheduling', {
        });
      }
      this.$log.error(error);
    });
  }

  wantRegister($event) {
    this.asRegistered = $event.reg;
    if (!this.asRegistered) {
      this.formFields = 'guest';
    } else {
      this.formFields = 'newAccount';
    }
  }
}

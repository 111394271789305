class DisconnectController { 
  constructor() {
    'ngInject';
  }

  remove() {
    this.onParentRemove({$event:{parentId: this.parent.patient.id}});
  }
}

export default DisconnectController;
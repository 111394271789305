import template from './hourRange.template.html';
import controller from './hourRange.controller';

const component = {
  controller: controller,
  template: template,
  bindings: {
    daySlots: '<'
  }
};

export default component;

export default class MyEventsService {
  constructor($q, User, Organization, Access, Bloom, Appointment, Form) {
    'ngInject';
    this.$q = $q;
    this.User = User;
    this.Organization = Organization;
    this.Access = Access;
    this.Bloom = Bloom;
    this.Appointment = Appointment;
    this.Form = Form;
  }

  getDepartmentAttributes(ids) {
    return this.Organization.getBulkOrganizationUnitAttributes(ids);
  }

  checkIn(event) {
    return this.Access.connectCheckIn(event.id, "SELF_SCHEDULE");
  }

  allowSelfScheduling() {
    return this.$q((resolve, reject) => {
      this.Organization.getOrgIds().then((orgIds) => {
        this.Organization.getOrgUnitAttributes(orgIds.orgId).then((response) => {
          resolve(response.data.attributesAsMap);
        }, error => reject(error));
      }, error => reject(error));
    });
  }

  allowRemoteCheckIn(event, attributes) {
    let allow = false;
    if (attributes && event) {
      attributes.forEach((attribute) => {
        if (attribute.id === event.departmentId) {
          if (attribute.attributesAsMap['ORGANIZATION_UNIT_HAS_CONNECT_SELF_CHECKIN']) {
            allow = angular.fromJson(attribute.attributesAsMap['ORGANIZATION_UNIT_HAS_CONNECT_SELF_CHECKIN']);
          }
        }
      });
    }
    return allow;
  }

  allowRemoveCancelButton(event, attributes) {
      let allow = false;
      if (attributes && event) {
        attributes.forEach((attribute) => {
          if (attribute.id === event.departmentId) {
            if (attribute.attributesAsMap['ORGANIZATION_UNIT_REMOVE_CANCEL_BUTTON']) {
              allow = angular.fromJson(attribute.attributesAsMap['ORGANIZATION_UNIT_REMOVE_CANCEL_BUTTON']);
            }
          }
        });
      }
      return allow;
    }


  enableCheckInButton(appointment) {
    let disable = true;
    let checkInText = 'Check In';
    let startOfToday = new Date().setHours(0, 0, 0, 0);


    //in the past already dont let them check in
    let startTimeIsDefined = angular.isDefined(appointment.start);

    if (appointment && (appointment.status === 'UNKNOWN') &&
      !(startTimeIsDefined && new Date(appointment.start).getTime() < startOfToday)) {
      disable = false;
    }

    if (appointment.status === 'ACTIVE') {
      checkInText = '\<i class="material-icons text-success">check</i> You\'re Checked In';
    }

    return {
      disabled: disable,
      checkInText: checkInText
    };
  }

  getTooltipMessage(event) {
    let message = 'This department does not support self check in';
    if (event.status === 'ACTIVE') {
      message = 'This appointment has already been checked in';
    } else if (event.status === 'CANCELLED') {
      message = 'This appointment has been canceled';
    }
    return message;
  }

  getParticipant(patientId) {
    return this.Bloom.getBloomParticipant(patientId);
  }

  cancelSeriesAppointment(patientId, schedules) {
    let promises = [];
    schedules.forEach((schedule) => promises.push(this.Bloom.cancelAppointment(schedule, patientId)));
    return this.$q.all(promises);
  }

  cancelSingleAppointment(appointment) {
    return this.Appointment.cancelAppointment(appointment.id);
  }

}

import template from './unitAddress.template.html';

const component = {
  template: template,
  bindings: {
    address: '<'
  }
};

export default component;

export default class SurveyPageService{
    constructor($q, Appointment, SurveyService){
        'ngInject';
        this.$q = $q;
        this.Appointment = Appointment;
        this.SurveyService = SurveyService;
    }

    getData(surveyId, organizationId, appointmentId) {
        return this.$q((resolve, reject) => {
            let data = {};
            this.SurveyService.getSurveyTemplate(surveyId).then((res) => {
                data.template = res.data;
                this.Appointment.getAppointmentTemplateData(appointmentId).then((dRes) => {
                    if (dRes.data[0]) {
                        data.exists = true;
                        data.survey = dRes.data[0];
                        if (data.template && data.template.questions) {
                            if (!data.survey) {
                                data.survey = {
                                    questions: []
                                };
                            }

                            data.template.questions.forEach((question, index) => {
                                if (data.survey.answers[index]) {
                                    data.survey.answers[index].question = question;
                                }
                                else {
                                    data.survey.answers[index] = {
                                        question: question
                                    };
                                }
                            });
                        }
                    }
                    else {
                        data.survey = {
                            appointmentId: appointmentId,
                            organizationId: organizationId,
                            templateId: surveyId,
                            answers: (data.template && data.template.questions) ? data.template.questions.map((question) => {
                                return {
                                    question: question
                                };
                            }) : []
                        };
                    }
                    resolve(data);
                }, reject);
            }, reject);
        });
    }

    saveData(data, exists) {
        return this.Appointment.saveAppointmentTemplateData(data, exists);
    }
}
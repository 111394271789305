import angular from 'angular';
import uiRouter from 'angular-ui-router';
import signUpSuccessComponent from './signUpSuccess.component';

const signUpSuccess = angular
  .module('connect.SignUpSuccess', [uiRouter, 'commonFeatures'])
  .component('signUpSuccess', signUpSuccessComponent)
  .config(['$stateProvider', ($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('signUpSuccess', {
        url: '/success',
        allowUnlogged: true,
        onlyUnlogged: true,
        template: '<sign-up-success></sign-up-success>',
        params: {email: null, password: null}
      });
  }])
  .name;

export default signUpSuccess;

import moment from 'moment-timezone';

class ConfrimationController {
  constructor($state, $stateParams) {
    'ngInject';
    this.$state = $state;
    this.$stateParams = $stateParams;
  }

  $onInit() {
    //go back to find a provider if the page is loaded without any data
    if (angular.isUndefined(this.$stateParams.appointment)) {
      this.$state.go('selfScheduling');
    }
  }

  getFormatedTime(time){
    return moment.tz(time,moment.tz.guess()).format('h:mm A z');
  }


}

export default ConfrimationController;

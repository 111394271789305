import angular from 'angular';
import component from './progressModal.component';
import service from '../selfScheduling.service';

const dependencies = [];

const progressModal = angular
  .module('connect.progressModal', dependencies)
  .component('progressModal', component)
  .service('selfSchedulingService', service)
  .name;

export default progressModal;

import angular from 'angular';
import component from './confirmation.component';

const confirmation = angular
  .module('connect.selfScheduling.guestConfirmation', [])
  .component('guestConfirmation', component)
  .config(($stateProvider) => {
    'ngInject';
    $stateProvider
      .state('guestConfirmation', {
        url: '/self-scheduling/guest/success',
        title: 'Appointment Confirmed',
        template: '<guest-confirmation></guest-confirmation>',
        allowUnlogged: true,
        params: {
          campaign: undefined,
          slot: undefined,
          appointment: undefined
        }
      });
  })
  .name;

export default confirmation;
